
.textTop {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #343B40;
}

.navContainer {

    @media (min-width: 2048px) and (max-width: 3840px) {
        width: 100%;
    }
    
    @media (min-width: 1920px) and (max-width: 1920px) {
        width: 100%;
    }

    @media (min-width: 1366px) and (max-width: 1366px) {
        width: 100%;
    }

    background: #ffffff;
    box-shadow: 0px 0px 2px rgba(37, 42, 49, 0.16),
        0px 1px 4px rgba(37, 42, 49, 0.12);
}

.navItems {
    display: flex;
    div > img {
        margin-right: 8px;
    }

    div:last-child {
        margin-left: 15.5px;
        cursor: pointer;
    }
}