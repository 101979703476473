// **************************************************************************

.formDropdown {
    background-image: url("../../assets/images/icons/arrow-down.png") !important;
    background-repeat: no-repeat !important;
    background-position: right 0.75rem center !important;
    background-size: 14px 7px !important;
}

.containerInfo {
    display: flex;
    flex-direction: column;

    select {
        width: 400px;
        height: 56px;
        border-radius: 8px;
        color: #343b40;
        font-family: Inter, sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;

        @media (min-width: 1024px) and (max-width: 1280px) {
            width: 300px !important;
        }
    }

    select:focus {
        border: 1px solid #c7c9d9;
        outline: none;
        box-shadow: none;
    }

    align-items: center;
    span {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        font-family: Inter, sans-serif;
        color: #343b40;
        line-height: 16.94px;
    }
}

.containerInput {
    span {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        font-family: Inter, sans-serif;
        color: #343b40;
        line-height: 16.94px;
    }

    span:nth-last-child(1) {
        font-style: normal;
        font-weight: 600;
        font-size: 12px !important;
        font-family: Inter, sans-serif;
        color: #e60e19;
        line-height: 16.94px;
    }
}

.containerInfoButton {
    display: flex;
    margin-top: 64px;
    width: 100%;
    justify-content: flex-end;
}

.containerButton {
    display: flex;
}

.buttonSearch {
    min-width: 169px;
    height: 56px;
    background: linear-gradient(268.05deg, #0064b4 4.03%, #1486e1 101.81%);
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    border-radius: 50px;
    border: 0;

    span {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
        padding: 20.5px 64px;
    }
}

.buttonSearchBlock {
    min-width: 169px;
    height: 56px;
    background: #b3b5b6;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    border-radius: 50px;
    border: 0;

    span {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #656a6e;
        padding: 20.5px 64px;
    }
}

.containerButtonEdit {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
    gap: 32px;
    div {
        font-family: Inter, sans-serif;
        font-weight: 600;
        font-size: 14px;
        line-height: 16.94px;
        color: #0064b4;
        text-decoration: underline;
        cursor: pointer;
    }
}

.containerGeralInput {
    display: flex;
    flex-direction: row;
    gap: 16px;
}

.containerEdit {
    padding: 27px 32px 64px 32px;
    width: 879px;
    @media (min-width: 1024px) and (max-width: 1280px) {
        width: 679px !important;
    }
    border-radius: 8px;
    background-color: #f7f7f7;
}
