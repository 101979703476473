// **************************************************************************

.containerGeral {
    background-color: #f7f7f7;
    width: 879px !important;
    margin-bottom: 50px;
}

.containerInput {
    padding: 63px 25px 5px;
}

.containerRow {
    margin-bottom: 24px;
    justify-content: center;
}

.inputLine {
    justify-content: center;
    display: flex;
    div:nth-child(1) {
        width: 400px;
        flex-direction: column;
    }

    input {
        width: 400px;
        height: 56px;
        background-color: #ecedee;
        box-sizing: border-box;
        border: 1px solid #c7c9d9;
        border-radius: 8px;
    }

    select {
        width: 400px;
        height: 56px;
        background-color: #ecedee !important;
        box-sizing: border-box;
        border: 1px solid #c7c9d9;
        border-radius: 8px;
    }

    label {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #343b40;
    }
}

.textareaLine {
    justify-content: center;
    display: flex;
    textarea {
        resize: none;
        width: 816px;
        height: 135px;
        border: 1px solid #c7c9d9;
        border-radius: 8px;
        background-color: #ecedee;
    }

    label {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #343b40;
    }
}

.containerOperator {
    width: 816px !important;
    display: flex;
    align-items: center;
}

.textOperator {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #343b40;
}

.characterNumber {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    color: #8d8f90;
}

.characterLetter {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #8d8f90;
}

.divider {
    border: 1px solid #c7c9d9;
    width: 816px !important;
    display: flex;
}

.buttonSearchRed {
    min-width: 169px;
    height: 56px;
    background: #e60e19;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    border-radius: 50px;
    border: 0;

    span {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
        padding: 20.5px 32px;
    }
}

.buttonSearch {
    min-width: 169px;
    height: 56px;
    background: linear-gradient(268.05deg, #0064b4 4.03%, #1486e1 101.81%);
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    border-radius: 50px;
    border: 0;

    span {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
        padding: 20.5px 32px;
    }
}

.containerButton {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    div:nth-child(1) {
        margin-right: 35.5px;
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        text-decoration-line: underline;
        color: #0064b4;
        cursor: pointer;
    }

    div:nth-child(2) {
        margin-right: 25px;
    }
}

.containerButtonFlexEnd {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    div:nth-child(1) {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        text-decoration-line: underline;
        color: #0064b4;
        cursor: pointer;
    }
}

.containerEndOccurrence {
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    label {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #343b40;
        display: flex;
        text-align: left;
    }
    input {
        width: 400px;
        height: 56px;
        background-color: #ecedee;
        box-sizing: border-box;
        border: 1px solid #c7c9d9;
        border-radius: 8px;
    }
    select {
        width: 400px;
        height: 56px;
        background-color: #ffffff !important;
        border: 1px solid #c7c9d9;
        border-radius: 8px;
        &:focus {
            box-shadow: none !important;
            border-color: #ccc !important;
        }
    }
    textarea {
        resize: none;
        width: 400px;
        height: 331px;
        background-color: #ffffff;
        border: 1px solid #c7c9d9;
        border-radius: 8px;
        &:focus {
            outline: 0;
            border-color: #c7c9d9;
            box-shadow: none;
        }
    }
    div:nth-child(1),
    div:nth-child(2) {
        margin-bottom: 16px;
    }
}

.containerButtonCancel {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    div:nth-child(1) {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        text-decoration-line: underline;
        color: #0064b4;
        cursor: pointer;
    }
}

.containerCancelOccurrence {
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    label {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #343b40;
        display: flex;
        text-align: left;
    }
    input {
        width: 400px;
        height: 56px;
        background-color: #ecedee;
        box-sizing: border-box;
        border: 1px solid #c7c9d9;
        border-radius: 8px;
    }
    select {
        width: 400px;
        height: 56px;
        background-color: #ffffff !important;
        border: 1px solid #c7c9d9;
        border-radius: 8px;
        &:focus {
            box-shadow: none !important;
            border-color: #ccc !important;
        }
    }
    textarea {
        resize: none;
        width: 400px;
        height: 331px;
        background-color: #ffffff;
        border: 1px solid #c7c9d9;
        border-radius: 8px;
        &:focus {
            outline: 0;
            border-color: #c7c9d9;
            box-shadow: none;
        }
    }
    div:nth-child(1) {
        margin-bottom: 16px;
    }

    div:nth-child(2) {
        margin-bottom: 5px;
    }
}

.espacamentoButton {
    margin-bottom: 46px;
}

.textLabel {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #343b40;
    display: flex;
    text-align: left;
}

.textLabelRed {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #e60e19 !important;
    display: flex;
    text-align: left;

    textarea {
        box-sizing: border-box;
        border: 2px solid red !important;
        border-radius: 4px;
    }
}

.formGroupError {
    textarea {
        box-sizing: border-box;
        border: 2px solid red !important;
        border-radius: 4px;
    }
}

.containerModal {
    height: 450px;
    width: 420px;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: #0064b4 #ecedee !important;

    @media (min-width: 1152px) and (max-width: 1370px) {
        height: 250px;
    }
}

.containerModal::-webkit-scrollbar {
    height: 500px;
    width: 5px;
}

.containerModal::-webkit-scrollbar-thumb {
    background: #0064b4;
}

.containerOperatorOccurrence {
    display: flex;
    align-items: center;
    flex-direction: row;
}

.requiredField {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    font-family: Inter, sans-serif;
    display: flex;
    justify-content: flex-start;
    color: #e60e19;
}

.section {
    overflow-y: scroll !important;
    width: 400px;
    height: 331px;
    background-color: #ecedee;
    border: 1px solid #c7c9d9;
    border-radius: 8px;
    div {
        background-color: #ecedee;
        font-weight: 400;
        font-size: 16px;
        font-family: Inter, sans-serif;
        justify-content: flex-start;
        text-align: start !important;
        color: #b3b5b6 !important;
        border: none !important;
    }
}
