.myCardAddres {
    width: 252px;
    height: 223px;
    border: 0.5px solid #c7c9d9 !important;
    border-radius: 8px;
    background-color: #ffffff !important;
}

.myCardAddresPrinc {
    border: 0.5px solid #0064b4 !important;
}

.infoContent {
    padding: 24px;
    display: flex;
    flex-direction: column;

    .titleAddress {
        font-family: Inter, sans-serif !important;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16.94px;
        color: #252a31;
        display: flex;
        text-transform: capitalize;
    }

    .logradAddress {
        font-family: Inter, sans-serif !important;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14.52px;
        color: #252a31;
        display: flex;
        margin-top: 16px;
    }

    .subLogradAddress {
        font-family: Inter, sans-serif !important;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14.52px;
        color: #252a31;
        display: flex;
        margin-top: 4px;
    }

    .referText {
        display: flex;
        flex-direction: row;
        margin-top: 16px;
        div {
            font-family: Inter, sans-serif !important;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            color: #252a31;
            span {
                font-family: Inter, sans-serif !important;
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 14.52px;
                color: #252a31;
            }
        }
    }
}

.containerAcoes {
    display: flex;
    justify-content: flex-end;
    gap: 24px;
    margin-top: 16px;
    span {
        font-family: Inter, sans-serif !important;
        font-weight: 700;
        font-size: 12px;
        line-height: 14.52px;
        color: #0064b4;
        text-decoration: underline;
        cursor: pointer;
    }
}

.cardInfo {
    flex-direction: column;
    display: flex;
    border: 0.5px solid #d8d9da;
    width: 264px;
    border-radius: 18px;
    background-color: #ffffff;
    padding: 16px 20px;
}

.topoCard {
    display: flex;
    justify-content: space-between;
    span:nth-child(1) {
        font-family: Inter, sans-serif;
        font-weight: 600;
        font-size: 14px;
        line-height: 16.94px;
        color: #0064b4;
    }

    span:nth-child(2) {
        font-family: Inter, sans-serif;
        font-weight: 600;
        font-size: 14px;
        line-height: 14.52px;
        color: #ffffff;
        padding: 4px 16px;
        border-radius: 10px;
    }

    .confirmado {
        background-color: #95c11f;
    }

    .aconfirmar {
        background-color: #f9b000;
    }

    .avaliado {
        background: linear-gradient(268.05deg, #0064b4, #1486e1);
    }

    .realizado {
        background-color: #0064b4;
    }

    .nao_realizado {
        background-color: #656a6e;
    }

    .cancelado {
        background-color: #e60e19;
    }

    .expirado {
        background-color: #e60e19;
    }
}

.bodyCard {
    margin-top: 12px;
    div {
        margin-top: 6px;
        display: flex;
        flex-direction: row;
        font-family: Inter, sans-serif;
        font-weight: 600;
        font-size: 14px;
        line-height: 16.94px;
        span:nth-child(1) {
            width: 56px;
            color: #656a6e;
        }
        span:nth-child(2) {
            margin-left: 8px;
            color: #343b40;
        }
    }
}

.rodapeCard {
    margin-top: 13px;
    display: flex;
    justify-content: flex-end;
    font-family: Inter, sans-serif;
    font-weight: 700;
    font-size: 12px;
    line-height: 14.52px;
    color: #0064b4;
    text-decoration: underline;
    cursor: pointer;
}
