.textRegister {
  color: #0064B4 !important;
  font-family: Inter, sans-serif !important;
  font-weight: 600 !important;
  font-size: 32px !important;
  line-height: 38.73px !important;
  margin: 72px 0 56px;

  @media (min-width: 1600px) and (max-width: 1680px) {
    padding-left: 10px;
  }
}

.buttonBackDisab {
  background: #fff !important;
  border-radius: 50px !important;
  border: 1px solid #B3B5B6 !important;
  height: 60px !important;

  @media (min-width: 2048px) and (max-width: 3840px) {
    width: 320px !important;
  }

  @media (min-width: 1920px) and (max-width: 1920px) {
    width: 320px !important;
  }

  @media (min-width: 1600px) and (max-width: 1680px) {
    width: 256px !important;
  }

  @media (min-width: 1366px) and (max-width: 1440px) {
    width: 212px !important;
  }

  @media (min-width: 1360px) and (max-width: 1360px) {
    width: 256px !important;
  }

  @media (min-width: 1280px) and (max-width: 1280px) {
    width: 212px !important;
  }

  @media (min-width: 1024px) and (max-width: 1152px) {
    width: 140px !important;
  }

  span {
    font-family: Inter, sans-serif !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 19.36px !important;
    color: #B3B5B6 !important;
  }
}

.buttonBack {  
  background: #fff !important;
  border-radius: 50px !important;
  border: 1px solid #0064B4 !important;
  height: 60px !important;
  @media (min-width: 2048px) and (max-width: 3840px) {
    width: 320px !important;
  }

  @media (min-width: 1920px) and (max-width: 1920px) {
    width: 320px !important;
  }

  @media (min-width: 1600px) and (max-width: 1680px) {
    width: 256px !important;
  }

  @media (min-width: 1366px) and (max-width: 1440px) {
    width: 212px !important;
  }

  @media (min-width: 1360px) and (max-width: 1360px) {
    width: 256px !important;
  }

  @media (min-width: 1280px) and (max-width: 1280px) {
    width: 212px !important;
  }

  @media (min-width: 1024px) and (max-width: 1152px) {
    width: 140px !important;
  }

  span {
    font-family: Inter, sans-serif !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 19.36px !important;
    color: #0064B4 !important;
  }
}

.buttonNext {
  background: linear-gradient(268.05deg, #0064b4 4.03%, #1486e1 101.81%);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  border-radius: 50px !important;
  border: 0 !important;
  height: 60px !important;
  @media (min-width: 2048px) and (max-width: 3840px) {
    width: 320px !important;
  }

  @media (min-width: 1920px) and (max-width: 1920px) {
    width: 320px !important;
  }

  @media (min-width: 1600px) and (max-width: 1680px) {
    width: 256px !important;
  }

  @media (min-width: 1366px) and (max-width: 1440px) {
    width: 212px !important;
  }

  @media (min-width: 1360px) and (max-width: 1360px) {
    width: 256px !important;
  }

  @media (min-width: 1280px) and (max-width: 1280px) {
    width: 212px !important;
  }

  @media (min-width: 1024px) and (max-width: 1152px) {
    width: 140px !important;
  }

  span {
    font-family: Inter, sans-serif !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 19.36px !important;
    color: #ffffff !important;
  }
}

.buttonNextDisab {
  background: #B3B5B6 !important;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  border-radius: 50px !important;
  border: 0 !important;
  height: 60px !important;

  @media (min-width: 2048px) and (max-width: 3840px) {
    width: 320px !important;
  }

  @media (min-width: 1920px) and (max-width: 1920px) {
    width: 320px !important;
  }

  @media (min-width: 1600px) and (max-width: 1680px) {
    width: 256px !important;
  }

  @media (min-width: 1366px) and (max-width: 1440px) {
    width: 212px !important;
  }

  @media (min-width: 1360px) and (max-width: 1360px) {
    width: 256px !important;
  }

  @media (min-width: 1280px) and (max-width: 1280px) {
    width: 212px !important;
  }

  @media (min-width: 1024px) and (max-width: 1152px) {
    width: 140px !important;
  }

  span {
    font-family: Inter, sans-serif !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 19.36px !important;
    color: #656A6E !important;
  }
}

.textPossuiConta {
  font-family: Inter, sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 19.36px;
    color: #0064B4;
    cursor: pointer;
}