.menuSidebarOn {
    background: linear-gradient(268.05deg, #0064b4 4.03%, #1486e1 101.81%);
    border-radius: 5px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff !important;
    margin: 0.1rem !important;
}

.menuSidebarOff {
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 19px !important;
    color: #0064b4 !important;
}

.iconImage {
    width: 18px;
    height: 18px;
}

.textExit {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #0064b4 !important;
}

.menuItem {
    position: relative !important;
    display: flex !important;
    width: 238px;
    height: 54px;
    left: 5px !important;
    top: 6px !important;
    border-radius: 5px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px !important;
}

.containerImageText {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10%;
}

.menuSidebar {
    left: 0;
    margin: 0;
    min-width: 260px;
    min-height: 943px;
    padding: 0 6px 70px;
    border: 1px;
    background: #ffffff;
    box-shadow: 0px 0px 2px rgba(37, 42, 49, 0.16),
        0px 1px 4px rgba(37, 42, 49, 0.12);
}
