// **************************************************************************

.containerInfo {
    display: flex;
    flex-direction: column;

    select {
        width: 400px;
        height: 56px;
        border-radius: 8px;
        color: #343b40;
        font-family: Inter, sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: 19.36px;

        @media (min-width: 1024px) and (max-width: 1280px) {
            width: 300px !important;
        }
    }

    select:focus {
        border: 1px solid #c7c9d9;
        outline: none;
        box-shadow: none;
    }

    align-items: center;
    span {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        font-family: Inter, sans-serif;
        color: #343b40;
        line-height: 16.94px;
    }
}

.containerInput {
    display: flex;
    flex-direction: column;
    align-self: center !important;
    width: 400px;
    span {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        font-family: Inter, sans-serif;
        color: #343b40;
        line-height: 16.94px;
    }
    @media (min-width: 1024px) and (max-width: 1280px) {
        width: 300px !important;
    }
}

.formDropdown {
    background-image: url("../../../assets/images/icons/arrow-down.png") !important;
    background-repeat: no-repeat !important;
    background-position: right 0.75rem center !important;
    background-size: 14px 7px !important;
}

.formDropdownStatus {
    background-image: url("") !important;
}

.marginTop {
    margin-top: 29px;
}

.containerInfoButton {
    display: flex;
    margin-top: 131px;
    width: 100%;
    justify-content: flex-end;
}

.containerInfoButtonService {
    display: flex;
    margin-top: 131px;
    width: 100%;
    justify-content: flex-end;
    margin-bottom: 30px;
}

.containerButton {
    display: flex;
}

.buttonSearch {
    min-width: 169px;
    height: 56px;
    background: linear-gradient(268.05deg, #0064b4 4.03%, #1486e1 101.81%);
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    border-radius: 50px;
    border: 0;

    span {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
        padding: 20.5px 64px;
    }
}

.buttonSearchBlock {
    min-width: 169px;
    height: 56px;
    background: #b3b5b6;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    border-radius: 50px;
    border: 0;

    span {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #656a6e;
        padding: 20.5px 64px;
    }
}

.requiredField {
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    font-family: Inter, sans-serif !important;
    display: flex !important;
    justify-content: flex-start !important;
    color: #e60e19 !important;
}

.textTop {
    span {
        font-family: Inter, sans-serif !important;
        font-size: 14px;
        font-weight: 600;
        line-height: 16.94px;
        margin-bottom: 32px;
    }
    span:nth-child(1) {
        color: #343b40;
    }
    span:nth-child(2) {
        color: #0064b4;
        text-decoration: underline;
        cursor: pointer;
    }

    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

// **************************************************************************

/* CONTATO E ACESSO */

.containerGeralContat {
    padding: 32px 32px 0 32px;
    div {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
}

.containerTextField {
    width: 100%;
    div {
        display: flex;
        flex-direction: column;
        span {
            font-family: Inter, sans-serif !important;
            font-size: 14px;
            font-weight: 600;
            line-height: 16.94px;
            color: #343b40;
        }
        fieldset {
            background-color: #ffffff;
        }
    }
}

.containerInfoButtonEdit {
    display: flex;
    width: 100%;
    padding: 0 16px;
    // padding: 0 53px 0 53px;
    // @media (min-width: 1344px) and (max-width: 1400px) {
    //     padding: 0 43px 0 43px;
    // }
    // @media (min-width: 1280px) and (max-width: 1344px) {
    //     padding: 0 33px 0 33px;
    // }
    // @media (min-width: 1152px) and (max-width: 1280px) {
    //     padding: 0 75px 0 75px;
    // }
    // @media (min-width: 1024px) and (max-width: 1152px) {
    //     padding: 0 53px 0 53px;
    // }
    // @media (min-width: 960px) and (max-width: 1024px) {
    //     padding: 0 31px 0 31px;
    // }
    div {
        margin: 32px 0;
    }
}

.containerButtonEdit {
    display: flex;
    width: 100%;
    justify-content: flex-end;
}

// **************************************************************************
/* DADOS PESSOAIS */
.containerDatePicker {
    width: 400px;
    span {
        display: flex;
        font-family: Inter, sans-serif !important;
        font-size: 14px;
        font-weight: 600;
        line-height: 16.94px;
        color: #343b40;
    }
    fieldset {
        background-color: #ffffff;
    }
}

.containerFieldSelect {
    display: flex;
    flex-direction: column;
    select {
        width: 400px;
        height: 56px;
        border-radius: 8px;
        color: #343b40;
        font-family: Inter, sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: 19.36px;

        @media (min-width: 1024px) and (max-width: 1280px) {
            width: 300px !important;
        }
    }

    select:focus {
        border: 1px solid #c7c9d9;
        outline: none;
        box-shadow: none;
    }
    span {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        font-family: Inter, sans-serif;
        color: #343b40;
        line-height: 16.94px;
    }
}

.containerGeralDadosP {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    gap: 16px;
    padding-top: 32px;
}

.containerInputsBtn {
    padding: 0 16px;
}

.subContainerGeralDados01 {
    display: flex;
    flex-direction: column;
}

.dividerSpace {
    margin-top: 24px;
}

.colorFieldBlock {
    div {
        fieldset {
            background-color: #ecedee !important;
        }
    }
    select {
        background-color: #ecedee !important;
    }
}

.containerInfoButtonEditDadosP {
    display: flex;
    width: 100%;
    padding: 0 16px;
    // padding: 0 16px 0 67px;
    // @media (min-width: 1400px) and (max-width: 1440px) {
    //     padding: 0 63px 0 63px;
    // }
    // @media (min-width: 1344px) and (max-width: 1400px) {
    //     padding: 0 48px 0 48px;
    // }
    // @media (min-width: 1280px) and (max-width: 1344px) {
    //     padding: 0 30px 0 30px;
    // }
    // @media (min-width: 1152px) and (max-width: 1280px) {
    //     padding: 0 60px 0 60px;
    // }
    // @media (min-width: 1024px) and (max-width: 1152px) {
    //     padding: 0 17px 0 17px;
    // }
    // @media (min-width: 960px) and (max-width: 1024px) {
    //     padding: 0 31px 0 31px;
    // }
    div {
        margin: 32px 0;
    }
}

.containerButtonEditDadosP {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    gap: 43px;
    div {
        font-weight: 600;
        font-size: 14px;
        font-family: Inter, sans-serif;
        color: #0064b4;
        line-height: 16.94px;
        text-decoration: underline;
        cursor: pointer;
    }
}

.containerFoto {
    flex-direction: row;
    display: flex;
    align-items: center;
    gap: 24px;
    padding: 32px 0 0 32px;
}

.fotoProf {
    background-color: #d8d9da;
    width: 132px;
    height: 132px;
    border-radius: 70px;
    border: 0.5px solid #b3b5b6;
    img {
        width: 132px;
        height: 132px;
        border-radius: 70px;
        object-fit: cover;
        cursor: pointer;
    }
}

.textoPerfil {
    font-weight: 600;
    font-size: 16px;
    font-family: Inter, sans-serif;
    color: #656a6e;
    line-height: 19.36px;
    text-decoration: underline;
    text-decoration-color: #656a6e;
    cursor: pointer;
}

.imageCamera {
    img {
        width: 32px !important;
        height: 32px !important;
        position: absolute;
        margin-left: 100px;
        margin-top: -40px;
        cursor: pointer;
    }
}

.estrelasPerfil {
    margin-top: 10px;
}

.containerEstrelas {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.containerEstrelasModal {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.starDivider {
    margin-left: 8px;
}

.profileImageModal {
    width: 116px;
    height: 116px;
    border-radius: 66px;
    justify-content: center;
    align-items: center;
    object-fit: cover;
}

.fotoProfModal {
    width: 116px;
    height: 116px;
    border-radius: 66px !important;
    background-color: #d8d9da;
    border: 0.5px solid #b3b5b6;
}

.containerFotoModal {
    margin-bottom: 16px;
}

.tituloBoxTitle {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21.78px;
    text-align: left;
    color: #343b40;
}

.listaServicosItem {
    text-align: left;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16.94px;
    color: #343b40;
}

.listaServicoTop {
    margin-top: 8px;
}

.formaPagamento {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-top: 13px;
}

.containerAvaliacoes {
    background-color: #ecedee;
    padding: 16px;
    flex-direction: column;
    border-radius: 8px;
    margin-bottom: 8px;
    word-break: break-all;
}

.avaliacoesBox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.marginTopAvalicao {
    margin-bottom: 11.3px;
}

.avaliacoesBoxRatingData {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 12.1px;
    text-align: right;
    color: #8d8f90;
}

.comentarios {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16.94px;
    color: #343b40;
    text-align: left;
    margin-top: 12px;
}

.modalPerfilProfissional {
    height: 500px;
    overflow-y: scroll;
    scroll-behavior: smooth;
}

.containerModal {
    height: 450px;
    // width: 420px;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: #0064b4 #ecedee !important;

    @media (min-width: 1152px) and (max-width: 1370px) {
        height: 370px;
    }
}

.containerModal::-webkit-scrollbar {
    height: 500px;
    width: 5px;
}

.containerModal::-webkit-scrollbar-thumb {
    background: #0064b4;
}
