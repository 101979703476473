.containerFilds {
    margin-top: 16px;
    display: block;
    cursor: auto;
    margin-bottom: 24px;
    width: 800px;
}

.subContainerFilds {
    display: flex;
    span {
        font-family: Inter, sans-serif;
        color: #0064b4;
        display: flex;
        width: 280px;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19.36px;
    }
}

.containerSelectTela {
    display: flex;
    align-self: flex-start;
}

.containerSelect {
    display: flex;
    flex-direction: row;
    gap: 12px;
}

.fieldSelect {
    width: 319px;
    height: 40px;
    display: flex;

    select {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #b3b5b6;
        background: #ffffff;
        border: 1px solid #c7c9d9;
        border-radius: 8px;

        &:focus {
            box-shadow: none !important;
            border-color: #ccc !important;
        }
        background-image: url("../../../assets/images/icons/arrow-down.png") !important;
        background-repeat: no-repeat !important;
        background-position: right 0.75rem center !important;
        background-size: 14px 7px !important;
    }
}

.fieldSelect2 {
    width: 305px;
    display: flex;
    flex-direction: row;
}

.inputData {
    display: flex;
    flex-direction: column;
    input {
        height: 40px !important;
        background: #ffffff;
        box-sizing: border-box;
        border-radius: 8px !important;
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #8d8f90 !important;

        ::placeholder {
            font-weight: normal;
            display: flex;
            align-items: center;
            color: #b6b3b3 !important;
        }

        &:focus {
            background-color: #ffffff !important;
            box-shadow: none !important;
        }
    }
    :hover {
        background-color: #ffffff !important;
        border-color: #b6b3b3 !important;
    }

    div {
        border-radius: 8px !important;
        background-color: #ffffff !important;
    }
    div:nth-child(1) {
        width: 145px !important;
    }
    label {
        margin-top: -4px;
        color: #b3b5b6 !important;
        font-style: normal !important;
        font-weight: 400 !important;
        font-size: 14px;
        line-height: 17px;
    }
}

// ************************************************************************

.containerGeral {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
}

.containerInputData {
    width: 651px;
    display: flex;
    flex-direction: row;
    gap: 12px;
}

.containerDatePicker {
    div {
        background-color: #fff;
        div {
            div {
                button {
                    z-index: 2;
                    display: block;
                    width: 30px;
                    height: 30px;
                    margin-right: 0px;

                    background: url("../../../assets/images/calendar2.png") !important;
                    background-repeat: no-repeat !important;
                    background-position: right 0.35rem center !important;
                }
            }
        }
    }
}

.containerList {
    width: 1057px;
    margin-left: -60px;

    @media (min-width: 1366px) and (max-width: 1366px) {
        margin-left: -90px;
        width: 1057px;
    }

    @media (min-width: 1344px) and (max-width: 1344px) {
        width: 1000px;
    }

    @media (min-width: 1152px) and (max-width: 1280px) {
        width: 950px;
    }

    @media (min-width: 1024px) and (max-width: 1152px) {
        width: 850px;
        margin-left: -40px;
    }
}

.listagem {
    background: #f7f7f7;
    border-radius: 8px;
    padding: 5px;
    padding-left: 25px !important;
    padding-right: 25px !important;
    padding: 15px;
    margin-bottom: 30px;
}
