
.characterNumber {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    display: flex;
    color: #8D8F90;
}

.characterLetter {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #8D8F90;
}

.requiredField {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    font-family: Inter, sans-serif;
    display: flex;
    justify-content: flex-start;
    color: #e60e19;
}

.buttonSearch {
    min-width: 169px;
    height: 56px;
    background: linear-gradient(268.05deg, #0064b4 4.03%, #1486e1 101.81%);
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    border-radius: 50px;
    border: 0;

    span {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #FFFFFF;
        padding: 20.5px 64px;
    }
}

.buttonSearchBlock {
    min-width: 169px;
    height: 56px;
    background: #B3B5B6;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    border-radius: 50px;
    border: 0;

    span {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #656A6E;
        padding: 20.5px 64px;
    }
}

.containerButton {
    display: flex;
    justify-content: flex-end;
    margin-top: 12px;
}