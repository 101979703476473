// *********************************pagination*********************************

.reactPaginate {
    display: flex;
    justify-content: center;
}

.reactPaginateModal {
    display: flex;
    justify-content: center;
}

.pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
}

.pageItem {
    font-size: 0.9rem;
    color: #0064b4 !important;
    background-color: transparent;
    border: 0;
    outline: 0;
    transition: all 0.3s linear;
    font-weight: 400;
}

.pageLink {
    padding: 0.375rem 0.75rem;
    position: relative;
    display: block;
    color: #0064b4 !important;
}

.disabled {
    display: none !important;
}
// **************************************************************************

.colorTrOne {
    background-color: #eeeeee;
}

.colorTrTwo {
    background-color: #fbfbfb;
}

.colorTrRed {
    background-color: #e6d0d1;
}

.table > thead {
    vertical-align: bottom;
    background: #cfd9e1;
    border: 1px solid #cfd9e1 !important;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #252a31;
}

.boxColor {
    min-width: 118px;
    height: 23px;
    padding: 0px 2px;
    border-radius: 99px;
}

.pillBadgesText {
    line-height: 1.5;
    letter-spacing: 0.00938em;
    color: #ffffff;
    margin: 0px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
}

.textNewOccurrence {
    display: flex;
    justify-content: flex-end;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-decoration-line: underline;
    color: #0064b4;
    margin: 10px 0 14px;
    span {
        cursor: pointer;
    }
}

// **************************************************************************

.containerGeneral {
    width: 882px !important;
    display: flex;
    flex-direction: column;
}

.containerFilds {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    cursor: auto;
}

.containerSelect {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    div:nth-child(3) {
        margin: 25px 8px 0 0;
    }

    div:nth-child(4) {
        margin-top: 25px;
    }
}

.fieldSelect {
    width: 400px;
    height: 56px;
    display: flex;

    div {
        flex-direction: column !important;
        select {
            width: 400px !important;
            height: 56px !important;
            font-family: Inter, sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #b3b5b6;
            background: #ffffff;
            border: 1px solid #c7c9d9;
            border-radius: 8px;

            &:focus {
                box-shadow: none !important;
                border-color: #ccc !important;
            }
            background-image: url("../../../assets/images/icons/arrow-down.png") !important;
            background-repeat: no-repeat !important;
            background-position: right 0.75rem center !important;
            background-size: 14px 7px !important;
        }
    }
}

.inputData {
    display: flex;
    width: 400px !important;
    // background: #ffffff !important;
    flex-direction: column;
    input {
        width: 400px !important;
        height: 56px !important;
        // background: #ffffff;
        box-sizing: border-box;
        border-radius: 8px !important;
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #8d8f90 !important;

        ::placeholder {
            font-weight: normal;
            display: flex;
            align-items: center;
            color: #b6b3b3 !important;
        }

        &:focus {
            background-color: #ffffff !important;
        }
    }

    div {
        border-radius: 8px !important;
        background: #ffffff;
    }
}

.buttonSearch {
    width: 169px;
    max-width: 169px;
    height: 56px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    align-items: center;
    text-align: center;
    background: linear-gradient(268.05deg, #0064b4 4.03%, #1486e1 101.81%);
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    border-radius: 50px;
    color: #ffffff;
    border: 0;
}

.buttonSearchBlock {
    width: 169px;
    max-width: 169px;
    height: 56px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    align-items: center;
    text-align: center;
    background: #b3b5b6;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    border-radius: 50px;
    color: #656a6e;
    border: 0;
}

.containerButton {
    display: flex;
    justify-content: flex-end;
    margin: 161px 0 44px 0;
}

.textLabel {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #343b40;
    margin-bottom: 8px;
}

.textLabelRed {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #e60e19;
    margin-bottom: 8px;
    background: #fbfbfb;
}

.formGroupError {
    input[type="text"],
    select {
        box-sizing: border-box;
        border: 2px solid red !important;
        border-radius: 4px;
    }
    div > div > div > div {
        border: 2px solid red !important;
    }
}

.requiredField {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    font-family: Inter, sans-serif;
    display: flex;
    justify-content: flex-start;
    color: #e60e19;
}
