.buttonSearch {
    width: 169px;
    max-width: 169px;
    height: 56px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    align-items: center;
    text-align: center;
    background: linear-gradient(268.05deg, #0064b4 4.03%, #1486e1 101.81%);
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    border-radius: 50px;
    color: #ffffff;
    border: 0;
}

.buttonSearchBlock {
    width: 169px;
    max-width: 169px;
    height: 56px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    align-items: center;
    text-align: center;
    background: #b3b5b6;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    border-radius: 50px;
    color: #656a6e;
    border: 0;
}

.inputPesquisar {
    width: 800px;
    max-width: 800px;
    color: #8d8f90;
    background: #ffffff;
    border: 1px solid #e2e8f0;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 0px 1.2%;
}
.inputPesquisar::placeholder {
    position: absolute;
    left: 1.2%;
    right: 9.3%;
    top: 32.14%;
    bottom: 32.14%;
    font-family: Inter, sans-serif !important;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #8d8f90 !important;
}
.inputPesquisar:focus {
    color: #212529;
    background-color: #ffffff;
    outline: 0;
    box-shadow: none !important;
}
