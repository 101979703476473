#checkbox {
    position: relative;
    appearance: none;
    outline: none;
    width: 50px;
    height: 18px;
    border-radius: 20px;
    background: #d8d9da;
    box-shadow: none;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    top: 3px;
}

#checkbox:checked {
    background: #d8d9da;
}

#checkbox::before {
    content: "";
    position: absolute;
    border-radius: inherit;
    width: 26px;
    height: 26px;
    transition: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
}

#checkbox::after {
    content: "X";
    position: absolute;
    color: #fff;
    top: -4px;
    border-radius: inherit;
    width: 26px;
    height: 26px;
    background: #e60e19;
    transition: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    right: calc(100% - 25px);
}

#checkbox:checked::after {
    content: url("../../../assets/images/icons/check2.png");
    right: 0;
    background: #0064b4;
}

.textAreaComment {
    width: 816px !important;
    height: 133px !important;
    background: #ffffff;
    border: 1px solid #c7c9d9;
    border-radius: 8px;
    resize: none;
    outline: 0;
    &:focus {
        box-shadow: none !important;
        background-color: #ffffff !important;
        border: 1px solid #c7c9d9 !important;
        outline: none !important;
    }
}

.labelText {
    font-family: Inter, sans-serif;
    font-size: 14px;
    line-height: 16.94px;
    font-weight: 600;
    color: #343b40;
}

.containerInputFull {
    background-color: #ffffff !important;
    width: 816px !important;
    height: 56px !important;
    border-radius: 8px !important;
    outline: 0;

    &:focus {
        box-shadow: none !important;
        background-color: #ffffff !important;
        border: 1px solid #c7c9d9 !important;
        outline: none !important;
    }

    color: #343b40 !important;
    font-family: Inter, sans-serif !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 19.36px !important;
    border: 1px solid #c7c9d9 !important;
}

.formularioItem {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.containerCaractere {
    margin-top: 8px;
    display: flex;
    justify-content: flex-end;

    span:nth-child(1) {
        font-family: Inter, sans-serif !important;
        font-weight: 700 !important;
        font-size: 12px !important;
        line-height: 14.52px !important;
        color: #8d8f90 !important;
    }

    span:nth-child(2) {
        font-family: Inter, sans-serif !important;
        font-weight: 400 !important;
        font-size: 12px !important;
        line-height: 14.52px !important;
        color: #8d8f90 !important;
    }
}

.containerGeral {
    width: 879px;
    background-color: #f7f7f7;
    border-radius: 8px;
}

.containerBtn {
    display: flex;
    flex-direction: row;
    align-items: center !important;
    justify-content: space-between;
    margin-top: 46px;
}

.marginLabelText {
    margin-top: 24px;
}

.buttonSave {
    min-width: 169px;
    height: 56px;
    background: linear-gradient(268.05deg, #0064b4 4.03%, #1486e1 101.81%);
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    border-radius: 50px;
    border: 0;

    span {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
        padding: 20.5px 64px;
    }
}

.buttonSearchBlock {
    min-width: 169px;
    height: 56px;
    background: #b3b5b6;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    border-radius: 50px;
    border: 0;

    span {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #656a6e;
        padding: 20.5px 64px;
    }
}

.subContainerGeral {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 32px 32px 45px;
}

.containerBtnUpload {
    display: flex;
    justify-content: flex-start;
    margin-top: 26.5px;
}

.imageTrash {
    img {
        width: 40px !important;
        height: 40px !important;
        position: absolute;
        margin-left: -50px;
        margin-top: 10px;
        cursor: pointer;
    }
}

.imageSelected {
    img {
        width: 240px;
        height: 180px;
        border: 2px dotted #0064b4;
        border-radius: 4px;
        object-fit: cover;
    }
}

.textBtnUpload {
    font-family: Inter, sans-serif;
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 19.36px !important;
    color: #0064b4 !important;
    cursor: pointer;
    text-decoration: underline;
}
