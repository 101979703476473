// .containerForm {
//     margin: auto;
//     max-height: max-content;
//     min-width: 996px;
//     padding: 0px 90px 0px 0px !important;   

//     @media (min-width: 1366px) and (max-width: 1440px) {
//         margin-left: -35px;
//     }
// }

// .breadcrumb {
//     @media (min-width: 1366px) and (max-width: 1440px) {
//         margin-left: -35px;
//     }
// }

.containerList {
    width: 1057px;
    margin-left: -60px;

    @media (min-width: 1366px) and (max-width: 1366px) {
        margin-left: -90px;
        width: 1057px;
    }

    @media (min-width: 1344px) and (max-width: 1344px) {
        width: 1000px;
    }

    @media (min-width: 1152px) and (max-width: 1280px) {
        width: 950px;
    }

    @media (min-width: 1024px) and (max-width: 1152px) {
        width: 850px;
        margin-left: -40px;
    }
}