.table {
    margin-top: 24px;
    width: 853px !important;
    thead {
        vertical-align: bottom;
        background: #cfd9e1;
        border: 1px solid #cfd9e1 !important;
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        color: #252a31;

        tr {
            th {
                div:first-child {
                    width: 22.18px;
                    height: 22.18px;
                    background: #0064B4;
                    border-radius: 3.69677px;
                    display: flex;
                    span:first-child {
                        width: 13px;
                        height: 1.84839px;
                        margin-top: 10px;
                        background-color: #FFFFFF;
                        display: flex;
                        margin-left: 3.6px;
                    }
                }
            }
        }
    }
}

.inputSearchClient {
    width: 658px !important;
    height: 56px !important;
    color: #8d8f90;
    background: #ffffff;
    border: 1px solid #E2E8F0;
    box-sizing: border-box;
    border-radius: 8px;
    font-family: Inter, sans-serif;
    color: #8D8F90 !important;
    border-color: #E2E8F0 !important;

    ::placeholder {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        display: flex;
        align-items: center;
        color: #b3b5b6 !important;
    }

    &:focus {
        color: #212529 !important;
        background-color: #ffffff !important;
        box-shadow: none !important;
        outline: 0;
        color: #8D8F90 !important;
    }


}

.containerInput {
    display: flex;
    justify-content: space-between;
    width: 853px;
}

.buttonSearch {
    width: 169px;
    max-width: 169px;
    height: 56px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    align-items: center;
    text-align: center;
    background: linear-gradient(268.05deg, #0064b4 4.03%, #1486e1 101.81%);
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    border-radius: 50px;
    color: #ffffff;
    border: 0;
}

.buttonSearchBlock {
    width: 169px;
    max-width: 169px;
    height: 56px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    align-items: center;
    text-align: center;
    background: #B3B5B6;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    border-radius: 50px;
    color: #656A6E;
    border: 0;
}

.radioCircle {
    width: 23px;
    height: 23px;
    border-radius: 12px;
    border: 2px solid #0064b4;
    display: flex;
    justify-content: center;
    align-items: center;
}

.radCirNoSelected {
    width: 23px;
    height: 23px;
    border-radius: 12px;
    border: 2px solid #B3B5B6;
    background-color: #D8D9DA;
}

.tbodyTable {
    background-color: #ECEDEE;
    font-family: Inter, sans-serif;
    td {
        border: 0.5px solid #C7C9D9;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
    }

    tr {
        cursor: pointer;
        td:first-child {
            width: 10px;
        }
    }
}

.circleBall {
    background-color: #0064B4;
    width: 11px;
    height: 11px;
    border-radius: 6px;
    display: flex;
}

.textSelected {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12.9387px;
    line-height: 16px;
    color: #0064B4;
}

// *********************************pagination*********************************

.reactPaginate {
    display: flex;
    justify-content: center;
}

.reactPaginateModal {
    display: flex;
    justify-content: center;
}

.pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
}

.pageItem  {
    font-size: 0.9rem;
    color: #0064B4 !important;
    background-color: transparent;
    border: 0;
    outline: 0;
    transition: all 0.3s linear;
    font-weight: 400;
}

.pageLink {
    padding: .375rem .75rem;
    position: relative;
    display: block;
    color: #0064B4 !important;
}

.disabled {
    display: none !important;
}
// **************************************************************************

.containerClient {
    background-color: #F7F7F7;
    width: 917px !important;
    padding: 38px 0 47px 0;
    border-radius: 8px;
}

.textLabel {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #343B40;
    margin-bottom: 16px;
}

.textLabelRed {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #e60e19;
    margin-bottom: 16px;
}

.requiredField {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    font-family: Inter, sans-serif;
    display: flex;
    justify-content: flex-start;
    color: #e60e19;
}

.formGroupError {
    textarea, input[type=text], select {
        box-sizing: border-box;
        border: 2px solid red !important;
        border-radius: 4px;
    }
    div > div > div > div {
        border: 2px solid red !important;
    }
}