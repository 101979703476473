// **************************************************************************

.containerGeral {
  width: 880px;
}


.subContainer {
  background-color: #FBFBFB !important;
  border-radius: 8px;
  padding: 33px 32px 0;
}


.textLabel {
  font-family: Inter, sans-serif !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 16.94px !important;
  color: #343b40 !important;
}

.marginTopLabel {
  margin-top: 24px !important;
}


.containerInputFull {
  background-color: #ffffff !important;
  width: 816px !important;
  height: 56px !important;
  border-radius: 8px !important;
  outline: 0;

  &:focus {
    box-shadow: none !important;
    background-color: #ffffff !important;
    border: 1px solid #C7C9D9 !important;
    outline: none !important;
  }

  color: #343b40 !important;  
  font-family: Inter, sans-serif !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 19.36px !important;
  border: 1px solid #C7C9D9 !important;
}


.containerGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}


.groupFone {
  display: flex;
  flex-direction: column;
}




.inputItem {
  width: 400px !important;
  height: 56px !important;
  border-radius: 8px !important;
  padding-left: 19.88px !important;
  outline: 0 !important;

  &:focus {
    box-shadow: none !important;
    background-color: #ffffff !important;
    border: 1px solid #C7C9D9 !important;
    outline: none !important;
  }

  color: #343b40 !important;
  font-family: Inter,
  sans-serif !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 19.36px !important;
  border: 1px solid #C7C9D9 !important;
}

.inputFone {
  border-radius: 8px;
  border: 1px solid #C7C9D9 !important;
}

.formDropdown {
  background-image: url("../../../assets/images/icons/arrow-down-02.png") !important;
  background-repeat: no-repeat !important;
  background-position: right 0.75rem center !important;
  background-size: 14px 7px !important;
  height: 56px !important;
  border-radius: 8px !important;
  background-color: #ECEDEE !important;

  font-family: Inter, sans-serif !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 19.36px !important;
  color: #8D8F90 !important;
}

.buttonSave {
  min-width: 169px;
  height: 56px;
  background: linear-gradient(268.05deg, #0064b4 4.03%, #1486e1 101.81%);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  border-radius: 50px;
  border: 0;

  span {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
    padding: 20.5px 64px;
  }
}

.buttonSearchBlock {
  min-width: 169px;
  height: 56px;
  background: #b3b5b6;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  border-radius: 50px;
  border: 0;

  span {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #656a6e;
    padding: 20.5px 64px;
  }
}

.textEmailUpdate {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16.94px;
  color: #0064B4;
  text-decoration: underline;
  cursor: pointer;  
}

.containerTextAndBtn {
  display: flex;
  flex-direction: row;
  align-items: center !important;
  justify-content: space-between !important;
}

// ******************************** MODAL ******************************************

.labelModal {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16.94px;
  color: #343B40;
  display: flex;
}

.labelMarginTopModal {
  margin-top: 24px;
}

.containerInputControl {  
  width: 400px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #C7C9D9;
  padding: 1px;

}

.inputControl {
  // width: 400px !important;
  height: 56px !important;
  border: 0 !important;
  outline: 0 !important;
  &:focus {
    box-shadow: none !important;
    background-color: #ffffff !important;
    border: none !important;
    outline: none !important;
  }
}

.containerModal {
  display: flex;
  flex-direction: column;
  align-items: center !important;
  margin-top: 64px;

  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: #0064b4 #ecedee !important;

  @media (min-width: 1366px) and (max-width: 1366px) {
    height: 478px !important;
  }

    @media (min-width: 1600px) and (max-width: 1600px) {
      // height: 18px !important;
    }

  @media (min-width: 1440px) and (max-width: 1600px) {
    height: 478px !important;
  }

  @media (min-width: 1280px) and (max-width: 1344px) {
    height: 378px !important;
  }

  @media (min-width: 1024px) and (max-width: 1280px) {
    height: 358px !important;
  }
}

.imageEye {
  padding: 16px;
  cursor: pointer;
}

.textInformativo {
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14.52px;
  color: #8D8F90;
  display: flex;
  margin-top: 8px;
}



.buttonSaveModal {
  min-width: 181px;
  height: 56px;
  background: linear-gradient(268.05deg, #0064b4 4.03%, #1486e1 101.81%);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  border-radius: 50px;
  border: 0;

  span {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
    padding: 20.5px 64px;
  }
}

.buttonSearchBlockModal {
  min-width: 181px;
  height: 56px;
  background: #b3b5b6;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  border-radius: 50px;
  border: 0;

  span {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #656a6e;
    padding: 20.5px 64px;
  }
}

.containerBtnModal {
  display: flex;
  margin-top: 73px;
}