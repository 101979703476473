// **************************************************************************

.formGroupError {
    box-sizing: border-box;
    border: 2px solid red !important;
    border-radius: 4px;
}

.textLabel {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #343b40;
    display: flex;
    text-align: left;
}

.textLabelRed {
    font-family: Inter, sans-serif;
    color: #e60e19 !important;
    display: flex;
    text-align: left;
}

.requiredField {
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    font-family: Inter, sans-serif !important;
    display: flex !important;
    justify-content: flex-start !important;
    color: #e60e19 !important;
}

.containerPDF {
    width: 100%;
    max-width: 804px;
    @media (min-width: 1024px) and (max-width: 1280px) {
        // width: 1000px;
    }
}

.containerSelect {
    display: flex;
    flex-direction: column;

    select {
        width: 320px;
        height: 46px;
        border-radius: 8px;
        color: #343b40;
        font-family: Inter, sans-serif;
        font-weight: 400;
        font-size: 14px;
    }

    select:focus {
        border: 1px solid #c7c9d9;
        outline: none;
        box-shadow: none;
    }

    align-items: center;
    span {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        font-family: Inter, sans-serif;
        color: #343b40;
        line-height: 16px;
    }
}

.formDropdown {
    background-image: url("../../../assets/images/icons/arrow-down.png") !important;
    background-repeat: no-repeat !important;
    background-position: right 0.75rem center !important;
    background-size: 14px 7px !important;
}

.containerTextArea {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    span {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        font-family: Inter, sans-serif;
        line-height: 16px;
        color: #343b40;
    }
    textarea::placeholder {
        font-family: Inter, sans-serif;
        color: #b3b5b6;
        font-weight: 400;
        font-size: 16px;
    }

    textarea:focus {
        outline: none !important;
        box-shadow: none;
    }
}

.textAreaComment {
    background: #ffffff;
    border: 1px solid #c7c9d9;
    border-radius: 8px;
    resize: none;
    width: 320px;
    height: 135px;
    display: flex;
    padding-top: 15.5px;
    padding-bottom: 15.5px;
    padding-left: 7.45px;
    padding-right: 7.45px;
}

.containerOperador {
    width: 320px;
    display: flex;
    margin-top: 13px;
    div > span {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        font-family: Inter, sans-serif;
        line-height: 14px;
        color: #343b40;
    }
}

.textOperador {
    margin-left: 4px;
}

.containerInfo {
    margin-top: 77px;
}

.containerCharacter {
    margin-top: 12px;
    display: flex;
    width: 320px;
    justify-content: flex-end;
    flex-direction: row;
}

.characterNumber {
    font-family: Inter, sans-serif !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 12px !important;
    line-height: 14px !important;
    color: #8d8f90 !important;
}

.characterLetter {
    font-family: Inter, sans-serif !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 14px !important;
    color: #8d8f90 !important;
}

.espacoInfo {
    margin-top: 29px;
}

.buttonSearch {
    min-width: 169px;
    height: 56px;
    background: linear-gradient(268.05deg, #0064b4 4.03%, #1486e1 101.81%);
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    border-radius: 50px;
    border: 0;

    span {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
        padding: 20.5px 64px;
    }
}

.buttonSearchBlock {
    min-width: 169px;
    height: 56px;
    background: #b3b5b6;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    border-radius: 50px;
    border: 0;

    span {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #656a6e;
        padding: 20.5px 64px;
    }
}

.containerInfoButton {
    display: flex;
    justify-content: center;
    margin-top: 37px;
}

.containerButton {
    display: flex;
    justify-content: flex-end;
    width: 320px;
}
