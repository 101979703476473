// **************************************************************************

.containerGeral {
    background-color: #f7f7f7;
    width: 879px !important;
    margin-bottom: 50px;
    border-radius: 8px;
}

.containerInput {
    padding: 63px 15px 5px;
}

.containerRow {
    margin-bottom: 24px;
    justify-content: center;
}

.inputLine {
    justify-content: center;
    display: flex;
    div:nth-child(1) {
        width: 400px;
        flex-direction: column;
    }

    select {
        width: 400px;
        height: 56px;
        background-color: #ffffff !important;
        box-sizing: border-box;
        border: 1px solid #c7c9d9;
        border-radius: 8px;

        background-image: url("../../../assets/images/icons/arrow-down-02.png") !important;
        background-repeat: no-repeat !important;
        background-position: right 0.75rem center !important;
        background-size: 20px !important;
    }

    label {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16.94px;
        color: #343b40;
    }
}

.buttonRelatBlock {
    width: 169px;
    max-width: 169px;
    height: 56px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    align-items: center;
    text-align: center;
    background: #b3b5b6;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    border-radius: 50px;
    color: #656a6e;
    border: 0;
}

.buttonRelat {
    min-width: 169px;
    height: 56px;
    background: #0064b4;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    border-radius: 50px;
    border: 0;

    span {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19.36px;
        color: #ffffff;
        padding: 20.5px 32px;
    }
}

.bntGerarRelat {
    display: flex !important;
    justify-content: flex-end !important;
    margin-bottom: 32px;
}

.inputData {
    display: flex;
    width: 400px !important;
    flex-direction: column;
    input {
        width: 400px !important;
        height: 56px !important;
        box-sizing: border-box;
        border-radius: 8px !important;
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #8d8f90 !important;

        ::placeholder {
            font-weight: normal;
            display: flex;
            align-items: center;
            color: #b6b3b3 !important;
        }

        &:focus {
            background-color: #ffffff !important;
        }
    }

    div {
        border-radius: 8px !important;
        background: #ffffff;
    }
}

.inputLineData {
    justify-content: center;
    display: flex;
    label {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #343b40;
    }
}

.inputPesquisar {
    width: 824px;
    height: 56px;
    color: #8d8f90;
    background: #ffffff;
    border: 1px solid #e2e8f0;
    box-sizing: border-box;
    border-radius: 8px !important;
    padding: 10px !important;
}
.inputPesquisar::placeholder {
    position: absolute;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #b3b5b6 !important;
}
.inputPesquisar:focus {
    color: #212529;
    background-color: #ffffff;
    outline: 0;
    box-shadow: none !important;
}

.inputColor {
    background-color: #ecedee;
}

// ************************************************************************

#checkbox {
    position: relative;
    appearance: none;
    outline: none;
    width: 50px;
    height: 18px;
    border-radius: 20px;
    background: #d8d9da;
    box-shadow: none;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    top: 3px;
}

#checkbox:checked {
    background: #d8d9da;
}

#checkbox::before {
    content: "";
    position: absolute;
    border-radius: inherit;
    width: 26px;
    height: 26px;
    transition: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
}

#checkbox::after {
    content: "X";
    position: absolute;
    color: #fff;
    top: -4px;
    border-radius: inherit;
    width: 26px;
    height: 26px;
    background: #e60e19;
    transition: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    right: calc(100% - 25px);
}

#checkbox:checked::after {
    content: url("../../../assets/images/icons/check2.png");
    right: 0;
    background: #0064b4;
}

.containerSwitch {
    flex-direction: row;
    margin-bottom: 37.5px;
    display: flex;
    align-items: center;
    gap: 25px;
}

.textInputNome {
    font-family: Inter, sans-serif;
    color: #343b40;
    font-weight: 600;
    font-size: 14px;
    line-height: 16.94px;
}

// ************************************************************************

.inputNome {
    display: flex;
    align-items: center;
    flex-direction: column;

    input {
        height: 56px !important;
        width: 824px !important;
    }

    label {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16.94px;
        color: #343b40;
        width: 824px !important;
        margin-bottom: 8px !important;
    }
}

.selectCidBair {
    select {
        background-color: #ecedee !important;
        background-image: url("../../../assets/images/icons/arrow-down-02.png") !important;
        background-repeat: no-repeat !important;
        background-position: right 0.75rem center !important;
        background-size: 20px !important;
    }
}

// ************************************************************************

.containerModal {
    max-height: 578px !important;
    width: 670px !important;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: #0064b4 #ecedee !important;
    @media (min-width: 1366px) and (max-width: 1366px) {
        max-height: 350px !important;
    }

    @media (min-width: 1440px) and (max-width: 1600px) {
        max-height: 478px !important;
    }

    @media (min-width: 1280px) and (max-width: 1344px) {
        max-height: 378px !important;
    }

    @media (min-width: 1024px) and (max-width: 1280px) {
        max-height: 358px !important;
    }
}

.containerModalBottom {
    margin-bottom: -30px;
}

.containerModal::-webkit-scrollbar {
    height: 578px;
    width: 5px;
}

.containerModal::-webkit-scrollbar-thumb {
    background: #0064b4;
}

.boxModal {
    width: 100%;
}

.containerInputModal {
    display: flex;
    justify-content: space-between;
    align-items: center !important;
}

.inputSearchModal {
    width: 435px !important;
    height: 50px !important;
    color: #8d8f90 !important;
    background: #ffffff;
    border: 1px solid #e2e8f0 !important;
    box-sizing: border-box;
    border-radius: 8px !important;

    ::placeholder {
        position: absolute;
        left: 1.2%;
        right: 9.3%;
        top: 32.14%;
        bottom: 32.14%;
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        /* identical to box height */
        display: flex;
        align-items: center;
        /* Cinza 2 */
        color: #b3b5b6 !important;
    }

    &:focus {
        color: #212529 !important;
        background-color: #ffffff !important;
        border-color: #e2e8f0 !important;
        outline: 0 !important;
        box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.25) !important;
    }
}

.tableFormModal {
    background-color: #f7f7f7;
    padding: 30px !important;
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    margin: 10px 0 !important;
}

.tableModal {
    margin: 20px 0 30px 0;
    thead {
        vertical-align: bottom;
        background: #cfd9e1;
        border: 1px solid #cfd9e1 !important;
        text-align: center;
        tr {
            th {
                font-family: Inter, sans-serif;
                font-style: normal;
                font-weight: 600;
                font-size: 12.94px;
                line-height: 15.66px;
                color: #252a31;
                div:first-child {
                    width: 22.18px;
                    height: 22.18px;
                    background: #0064b4;
                    border-radius: 3.69677px;
                    display: flex;
                    span:first-child {
                        width: 13px;
                        height: 1.84839px;
                        margin-top: 10px;
                        background-color: #ffffff;
                        display: flex;
                        margin-left: 3.6px;
                    }
                }
            }
        }
    }
}

.tbodyModal {
    background-color: #ecedee !important;
    td {
        border: 0.5px solid #c7c9d9 !important;
        word-break: break-all !important;
    }

    tr {
        cursor: pointer;
        td:first-child {
            width: 10px !important;
        }
    }
}

// ****************************************************************************

// *********************************pagination*********************************

.reactPaginate {
    display: flex;
    justify-content: center;
}

.reactPaginateModal {
    display: flex;
    justify-content: center;
}

.pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
}

.pageItem {
    font-size: 0.9rem;
    color: #0064b4 !important;
    background-color: transparent;
    border: 0;
    outline: 0;
    transition: all 0.3s linear;
    font-weight: 400;
}

.pageLink {
    padding: 0.375rem 0.75rem;
    position: relative;
    display: block;
    color: #0064b4 !important;
}

.disabled {
    display: none !important;
}
// **************************************************************************

.buttonSearch {
    width: 169px;
    max-width: 169px;
    height: 56px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    align-items: center;
    text-align: center;
    background: linear-gradient(268.05deg, #0064b4 4.03%, #1486e1 101.81%);
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    border-radius: 50px;
    color: #ffffff;
    border: 0;
}

.buttonSearchBlock {
    width: 169px;
    max-width: 169px;
    height: 56px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    align-items: center;
    text-align: center;
    background: #b3b5b6;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    border-radius: 50px;
    color: #656a6e;
    border: 0;
}

.textSelected {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12.94px;
    line-height: 16px;
    color: #0064b4;
}

.textNoSelected {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12.94px;
    line-height: 16px;
    color: #8d8f90;
}

.radioCircle {
    width: 23px;
    height: 23px;
    border-radius: 12px;
    border: 2px solid #0064b4;
    display: flex;
    justify-content: center;
    align-items: center;
}

.radCirNoSelected {
    width: 23px;
    height: 23px;
    border-radius: 12px;
    border: 2px solid #b3b5b6;
    background-color: #d8d9da;
}

.circleBall {
    background-color: #0064b4;
    width: 11px;
    height: 11px;
    border-radius: 6px;
    display: flex;
}
