// *********************************pagination*********************************

.reactPaginate {
    display: flex;
    justify-content: center;
}

.reactPaginateModal {
    display: flex;
    justify-content: center;
}

.pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
}

.pageItem  {
    font-size: 0.9rem;
    color: #0064B4 !important;
    background-color: transparent;
    border: 0;
    outline: 0;
    transition: all 0.3s linear;
    font-weight: 400;
}

.pageLink {
    padding: .375rem .75rem;
    position: relative;
    display: block;
    color: #0064B4 !important;
}

.disabled {
    display: none !important;
}
// **************************************************************************

.colorTrOne {
    background-color: #eeeeee;
}

.colorTrTwo {
    background-color: #fbfbfb;
}

.colorTrRed {
    background-color: #E6D0D1;
}

.table > thead {
    vertical-align: bottom;
    background: #cfd9e1;
    border: 1px solid #cfd9e1 !important;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #252a31;
}

.boxColor {
    min-width: 118px;
    height: 23px;
    padding: 0px 2px;
    border-radius: 99px;
}

.pillBadgesText {
    line-height: 1.5;
    letter-spacing: 0.00938em;
    color: #ffffff;
    margin: 0px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
}

.textNewOccurrence {
    display: flex;
    justify-content: flex-end;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-decoration-line: underline;
    color: #0064B4;
    margin: 10px 0 14px;
    span {
        cursor: pointer;
    }
}