.myCardAddres {
    width: 252px;
    height: 223px;
    border: 0.5px solid #C7C9D9;
    border-radius: 8px;
    background-color: #FFFFFF;
}

.containerCreate {
    display: flex;
    height: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center !important;
    align-self: center !important;
    cursor: pointer;
    
    span:nth-child(1) {
        img {
            width: 24px;
            height: 24px;
        }
    }

    span:nth-child(2) {
        font-family: Inter, sans-serif !important;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16.94px;
        color: #0064B4;
    }
}