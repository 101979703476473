.formulario {
    max-width: 879px;
    max-height: 931px;
    background: #f7f7f7;
    border-radius: 8px;
}

.formularioItem {
    width: 100% !important;
    height: 100% !important;
    margin: 10px 2px;
    padding: 32px 32px;
    display: flex;
    flex-direction: column;
}

.formulario input {
    width: 100% !important;
    max-width: 816px;
    height: 56px;
    left: 0px;
    top: 22px;
    background: #ffffff;
    /* Linhas */
    border: 1px solid #c7c9d9;
    box-sizing: border-box;
    border-radius: 8px;
}
.formulario select {
    width: 100% !important;
    max-width: 400px;
    height: 56px;
    left: 0px;
    top: 22px;
    background: #ffffff;
    /* Linhas */
    border: 1px solid #c7c9d9;
    box-sizing: border-box;
    border-radius: 8px;
    color: #b3b5b6 !important;
}
.formulario select:focus {
    color: #343b40 !important;
}
.formulario textarea {
    width: 100% !important;
    max-width: 879px;
    max-height: 931px;
    left: 0px;
    top: 22px;

    background: #ffffff;
    border: 1px solid #c7c9d9;
    box-sizing: border-box;
    border-radius: 8px;
}

.fieldSelect {
    width: 400px;
    height: 56px;
    display: flex;

    div {
        flex-direction: column !important;
        select {
            width: 400px !important;
            height: 56px !important;
            font-family: Inter, sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #b3b5b6;
            background: #ffffff;
            border: 1px solid #c7c9d9;
            border-radius: 8px;
            padding-left: 15px;

            &:focus {
                box-shadow: none !important;
                border-color: #ccc !important;
            }
            background-image: url("../../../assets/images/icons/arrow-down.png") !important;
            background-repeat: no-repeat !important;
            background-position: right 1rem center !important;
            background-size: 14px 7px !important;
        }

        label {
            font-family: Inter, sans-serif;
            font-weight: 600 !important;
            font-size: 14px;
            line-height: 17px;
        }
    }
}

.formGroup {
    display: flex;
    height: 78px !important;
    margin-bottom: 24px;
}

.formGroupTextArea {
    label {
        font-family: Inter, sans-serif;
        font-weight: 600 !important;
        font-size: 14px;
        line-height: 17px;
    }

    textarea {
        resize: none;
        height: 135px;
        border-radius: 8px;
        &:focus {
            box-shadow: none !important;
            border-color: #ccc !important;
        }
    }
}

.formGroupError {
    textarea {
        box-sizing: border-box;
        border: 2px solid red !important;
        border-radius: 8px;
    }

    select {
        box-sizing: border-box;
        border: 2px solid red !important;
        border-radius: 4px;
    }
}

.textLabelRed {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #e60e19 !important;
    display: flex;
    text-align: left;
}

.containerOperatorOccurrence {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-top: 12px;
}

.textOperator {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #343b40;
}

.characterNumber {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    color: #8d8f90;
}

.characterLetter {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #8d8f90;
}

.buttonSaveBlock {
    width: 169px;
    max-width: 169px;
    height: 56px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    align-items: center;
    text-align: center;
    background: #b3b5b6;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    border-radius: 50px;
    color: #656a6e;
    border: 0;
}

.buttonSave {
    width: 169px;
    max-width: 169px;
    height: 56px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    align-items: center;
    text-align: center;
    background: linear-gradient(268.05deg, #0064b4 4.03%, #1486e1 101.81%);
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    border-radius: 50px;
    color: #ffffff;
    border: 0;
}

.labelLastAuter {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */
    display: flex;
    align-items: center;
    flex-wrap: wrap !important;
    word-break: break-all !important;
    /* Texto */
    color: #343b40;
}

.textareaUpdate {
    background-color: #ecedee !important;
}
