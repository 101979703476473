@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap");
* {
    box-sizing: border-box;
    margin: 0;
}

body {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    width: 100%;
    height: 100%;
}
h1,
h2 {
    font-size: 24px;
}
.wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 1440px;
    min-width: 350px;
    min-height: 1024px;
}
.nav-container {
    top: 0px;
    left: 0px;
    width: 1440px;
    height: 80px;
    background: #ffffff;
    box-shadow: 0px 0px 2px rgba(37, 42, 49, 0.16),
        0px 1px 4px rgba(37, 42, 49, 0.12);

    @media (width: 1400px) {
        width: 1385px;
    }

    @media (width: 1366px) {
        width: 1349px !important;
    }

    @media (width: 1344px) {
        width: 1329px;
    }

    @media (width: 1280px) {
        width: 1265px;
    }

    @media (width: 1152px) {
        width: 1137px;
    }

    @media (width: 1024px) {
        width: 1009px;
    }
}
#navbar {
    width: 100%;
    padding: 10px 25px;
}

#print {
    width: 100%;
}
.conteudo {
    display: flex;
}

.menu {
    width: 260px;
    height: 100%;
}
/* Column Row Fix*/
.row > *,
.col > * {
    margin: 0 !important;
    padding: 0 !important;
}
/* Column Row Fix*/
.principal {
    width: 100%;
    max-width: 1056px;
    /* fix responsive */
    margin: 32px 0px 0px -32px;
    padding: 10px 10px;
}
/* FIX Form */
.containerForm {
    margin: auto;
    max-height: max-content;
    min-width: 996px;
    padding: 0px 90px 0px 0px !important; /* Desc */
    /* scroll-behavior: auto;
    overflow-y: scroll;
    scroll-behavior: smooth; */
}
/* Fix Table */
.container-xl,
.container-lg,
.container-md,
.container-sm,
.container {
    max-width: 100% !important;
    max-height: max-content !important;
}
nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 72px;
}

nav ul {
    display: flex;
}

.nav-items li {
    list-style: none;
    margin-right: 100px;
}

.nav-items li:nth-last-of-type(1) {
    margin: 0;
}

.nav-items li a {
    color: #021718;
    text-decoration: none;
}
/* Body */
.cabecalho {
    min-width: 450px;
    height: 39px;
    left: 323px;
    top: 152px;
    border: "none";
    margin: 32px 0px;
    font-family: Inter, sans-serif !important;
    font-style: "normal" !important;
    font-weight: 600 !important;
    font-size: 32px;
    line-height: 39px;
    color: #0064b4;
}
h1 {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #0064b4;
}

.input {
    width: 400px;
    height: 56px;
    left: 0px;
    top: 22px;

    background: #ffffff;
    /* Linhas */

    border: 1px solid #c7c9d9;
    box-sizing: border-box;
    border-radius: 8px;
}

.button {
    background: linear-gradient(268.05deg, #0064b4 4.03%, #1486e1 101.81%);
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    border-radius: 50px;
    width: 400px;
    height: 56px;
}

.button-criar {
    position: relative;
    display: block;
    direction: rtl;
    margin: 44px -30px -50px 0px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-decoration-line: underline;
    color: #0064b4;
}

.texto-criar {
    position: relative;
    display: block;
    direction: rtl;
    margin: 44px -10px -50px 0px;
    font-family: Inter, sans-serif;
    font-style: "normal";
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-decoration-line: underline;
    color: #0064b4;
    word-spacing: 2px;
}

/* FORM */
.wrapperlistagem {
    /* background: #fbfbfb; */
    /* border-radius: 8px; */
    padding: 5px 25px !important;
}
.listagem {
    background: #fbfbfb;
    border-radius: 8px;
    padding: 5px;
    padding-left: 25px !important;
    padding-right: 25px !important;
}
/* FORM */
.formulario {
    position: relative;
    max-width: 879px;
    max-height: 931px;
    background: #ecedee;
    border-radius: 8px;
}

.formulario-bottom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 879px;
    max-height: 931px;
    background: #ecedee;
    border-radius: 8px;
    padding: 30px;
}

.formulario-item {
    position: relative;
    width: 100% !important;
    height: 100% !important;
    margin: 10px 2px;
    padding: 32px 32px;
    align-content: space-between;
    /* background: #ecedee; */
    /* border-radius: 8px; */
}
.form-label {
    margin-bottom: 0.5rem;
    color: rgba(0, 0, 0, 0.6);
    font-weight: bold !important;
}
.formulario input {
    width: 100% !important;
    max-width: 816px;
    height: 56px;
    left: 0px;
    top: 22px;
    background: #ffffff;
    /* Linhas */
    border: 1px solid #c7c9d9;
    box-sizing: border-box;
    border-radius: 8px;
}
.formulario select {
    width: 100% !important;
    max-width: 400px;
    height: 56px;
    left: 0px;
    top: 22px;
    background: #ffffff;
    /* Linhas */
    border: 1px solid #c7c9d9;
    box-sizing: border-box;
    border-radius: 8px;
    color: #b3b5b6 !important;
}
.formulario select:focus {
    color: #343b40 !important;
}
.formulario textarea {
    width: 100% !important;
    max-width: 879px;
    max-height: 931px;
    left: 0px;
    top: 22px;

    background: #ffffff;
    border: 1px solid #c7c9d9;
    box-sizing: border-box;
    border-radius: 8px;
}
.form-control::placeholder {
    color: #b3b5b6 !important;
}

.form-textarea-caracter {
    position: relative;
    max-width: 120px;
    height: 15px;
    float: right;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #8d8f90;
    margin-top: 14px;
}

/* Button */
.button-submeter {
    width: 181px;
    height: 60px;
    margin-left: 15px;
    color: #fff;
    border: 0;
    border-radius: 50px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    background: linear-gradient(268.05deg, #0064b4 4.03%, #1486e1 101.81%);
}

.button-cancelar {
    color: #fff;
    background: linear-gradient(268.05deg, #b40000 4.03%, #f50707 101.81%);
    border: 0;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    border-radius: 50px;
    width: 181px;
    height: 60px;
}
.linha {
    display: flex;
    justify-content: space-between;
}

/* TABLE */
.custom-select {
    margin-bottom: 10px;
}
.table > thead {
    vertical-align: bottom;
    background: #cfd9e1;
    border: 1px solid #cfd9e1 !important;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #252a31;
}
thead,
tbody,
tfoot,
tr,
td,
th {
    border-bottom: 1px solid #cfd9e1 !important;
    border-color: #c7c9d9;
    border-style: solid;
}
tbody {
    border: none !important;
    max-height: 992px;
}
.table th {
    font-weight: bold !important;
}

.table td {
    text-align: center;
    vertical-align: middle;
}
.estado-cat {
    position: relative;
    display: flex;
    width: 57px !important;
    height: 18px !important;
    color: #fff;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #b3b5b6;
    border-radius: 99px;
}
.true {
    background: #95c11f;
}

.false {
    background: #e60e19;
    padding: 10px 35px;
}

.null {
    width: 87px !important;
}
.conteudo-tabela {
    font-family: Inter !important;
    width: 100%;
    max-width: 996px;
    margin-top: 50px;
    cursor: pointer;
}
/* Fix treinamento */
.conteudo-tabela-response {
    width: 100%;
    min-width: 996px;
    max-width: 996px;
    margin-top: 50px;
    cursor: pointer;
}

.caminho-de-pao-meio {
    max-width: 128px;
    height: 16px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-decoration-line: underline;
    color: #343b40;
    margin-right: 15px;
}

.caminho-de-pao-pagina {
    height: 16px;
    left: 212px;
    top: 0px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #343b40;
    margin-right: 15px;
}

.caminho-de-pao-home {
    width: 34px;
    height: 16px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    text-decoration-line: underline;
    color: #0064b4;
}

.caminho-de-pao-container {
    justify-content: left;
    flex-direction: row !important;
    display: flex;
    align-items: center;
}

.caminho-de-pao-seta {
    margin: 0 15px 0 !important;
}

.nav-bar-texto {
    height: 20px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #343b40;
    margin-left: 10px;
}

.texto-tit a {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px !important;
    color: #343b40;
}

.modal-titulo {
    width: 450px;
    height: 29px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: #0064b4;
}

.modal-texto {
    width: 428px;
    height: 102px;
    left: calc(50% - 428px / 2 + 3px);
    top: calc(50% - 102px / 2 - 11.5px);

    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;

    /* Texto */
    color: #343b40;
}

.custom-ui {
    width: 558px;
    height: 450px;
}

.modal-botoes {
    display: flex;
    justify-content: space-between;
}

.texto-registro {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #0064b4;
}
/* Sidebar */
.menuSidebar {
    left: 0;
    margin: 0;
    min-width: 260px;
    min-height: 943px;
    padding: 70px 6px;
    border: 1px;
    background: #ffffff;
    box-shadow: 0px 0px 2px rgba(37, 42, 49, 0.16),
        0px 1px 4px rgba(37, 42, 49, 0.12);
}
.menuButton {
    display: inline-flex;
    width: 238px;
    height: 54px;
    font-family: Inter, sans-serif !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 16px !important;
    line-height: 19px !important;
}
.menuButton-active {
    display: inline-flex;
    width: 238px;
    height: 54px;
    font-family: Inter, sans-serif !important;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 16px !important;
    line-height: 19px !important;
    background-color: #2e7ebe !important;
}
.submenuButton {
    text-align: left;
    margin-left: 70px;
}

.menuItem {
    position: relative;
    display: flex;
    width: 238px;
    height: 54px;
    left: 5px;
    top: 6px;
    padding: 1px 1px !important;
    margin: 10px -5px !important;
    background: #ffffff;
    border-radius: 5px;
    /* border:"ipx solid #000"; */
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #0064b4;
}

.menuText {
    margin-left: 15px;
}

.btn-link {
    /* width: max-content; */
    padding: 2px 2px !important;
    text-decoration: none !important;
    font-family: Inter, sans-serif !important;
    font-style: normal !important;
    font-weight: bold !important;
}

#sair button {
    border-radius: 50px !important;
}

.cancel {
    color: #fff;
    background: #e60e19 !important;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
}

.invalid {
    border-color: #e60e19;
    outline: 0;
    box-shadow: inset 0px 0px 0px 1px #e60e19;
}

.box-tarefas {
    width: 750px;
    height: 76px;
    left: 359px;
    top: 510px;

    background: #ecedee;

    border: 1px solid #c7c9d9;
    border-radius: 8px;
    text-align: center;
    padding: 25px 0;
}

.texto-tarefas {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #828282;
}

.texto-tarefas-titulo {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;

    /* identical to box height */
    text-align: center;

    /* Cinza 2 */
    color: #8d8f90;
}
/* Login */
/* formulário de login */

.login {
    max-width: 402px;
    max-height: 413px;
    margin: 160px 214px;
    margin-right: 100px;
}
.login-title {
    /* H1 */
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 64px;
    /* identical to box height */
    /* Azul */
    color: #0064b4;
}
.login-img {
    width: 100%;
    height: 940px;
    object-fit: cover;
    padding-bottom: 2px;
}
.form {
    align-items: center;
}
.form-control {
    color: #bcbcbd !important;
}
.form-control:focus {
    color: #343b40 !important;
}
.buttons {
    max-width: 400px;
    margin: 0 0;
    padding: 0 0;
}
.button-login {
    width: 181px;
    height: 60px;
    background: linear-gradient(268.05deg, #0064b4 4.03%, #1486e1 101.81%);
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    border-radius: 50px !important;
}
.button-cadastrar {
    width: 181px;
    max-width: 181px;
    height: 60px;
    font-family: Inter, sans-serif;
    color: #0064b4 !important;
    background: #fff !important;
    background-color: #fff !important;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    display: flex;
    align-items: center !important;
    text-align: center !important;
    border-radius: 50px !important;
}
.passwordText {
    margin-bottom: 50px;
    float: right;
    position: relative;
    top: 0%;
    bottom: 0%;

    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-decoration-line: underline;
    color: #343b40;
}
/* Input Cadastrar */
.inputCadastrar {
    width: 400px;
    height: 56px;
    background: #ffffff;
    border: 1px solid #c7c9d9;
}
/* Check */
.form-switch {
    padding-left: 3rem !important;
    margin-top: -20px !important;
}

.profile-image {
    width: 132px;
    height: 132px;
    border-radius: 66px;
    justify-content: center;
    align-items: center;
}

.botaoCapitalizado {
    text-transform: capitalize;
}

.box-servicos {
    box-sizing: border-box;

    width: 446px;

    left: 0px;
    top: 0px;

    background: #ffffff;
    border: 1px solid #d8d9da;
    border-radius: 18px;
    padding: 15px;
}

.titulo-box-servicos {
    height: 35px;
    left: 16px;
    top: 16px;

    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: left;
    color: #343b40;
}

.forma-de-pagamento {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 15px;
}

.formas-de-pagamento-item {
    display: flex;
    flex-direction: column !important;
}

.formas-de-pagamento-item-nome {
    margin-top: 10px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
}

.lista-servicos-item {
    text-align: left;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #343b40;
}

.avaliacoes-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: #ecedee;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 15px;
}

.modal-perfil-profissional {
    height: 500px;
    overflow-y: scroll;
    scroll-behavior: smooth;
}

.avaliacoes-box-rating-data {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    text-align: right;
    color: #8d8f90;
}

.link {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-decoration-line: underline;
    color: #0064b4;
    cursor: pointer;
}

.link-blue {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #0064b4;
    cursor: pointer;
    text-decoration: underline;
}

.ver-mais-texto {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-decoration-line: underline;
    color: #0064b4;
    cursor: pointer;
}

.hug {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #0064b4;
    flex: none;
    order: 0;
    flex-grow: 0;
    border-radius: 8px;
}

.hug-box {
    width: 37px;
    height: 21px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid #0064b4;
    border-radius: 50px;
    padding: 2px 6px;
    gap: 10px;
}

.badge-tarefa-texto {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 140%;
    display: flex;
    align-items: center;
    text-align: center;
    color: #ffffff;
}

.badge-tarefa-a-confirmar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 16px;
    gap: 10px;
    width: 101px;
    height: 18px;
    background: #f9b000;
    border-radius: 99px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.badge-tarefa-confirmado {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 16px;
    gap: 10px;
    width: 101px;
    height: 18px;
    background: #95c11f;
    border-radius: 99px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.badge-tarefa-realizado {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 16px;
    gap: 10px;
    width: 101px;
    height: 18px;
    background: #2e7ebe;
    border-radius: 99px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.badge-tarefa-avaliado {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 16px;
    gap: 10px;
    width: 101px;
    height: 18px;
    background: #0064b4;
    border-radius: 99px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.badge-tarefa-cancelado {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 16px;
    gap: 10px;
    width: 101px;
    height: 18px;
    background: #e60e19;
    border-radius: 99px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.badge-tarefa-expirado {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 16px;
    gap: 10px;
    width: 101px;
    height: 18px;
    background: #e60e19;
    border-radius: 99px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.badge-tarefa-nao-realizado {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 16px;
    gap: 10px;
    width: 101px;
    height: 18px;
    background: #f9b000;
    border-radius: 99px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.select-pesquisa-unidade {
    max-width: 320px;
    outline: 0;
    height: 56px;
    background: #ffffff;
    border: 1px solid #c7c9d9;
    box-sizing: border-box;
    border-radius: 8px;
    margin-left: 23px;
    margin-top: 15px !important;
}

.spacer {
    width: 130px;
}

.title-profissionaos-solicitados {
    width: 165px;
    height: 17px;
    left: 32px;
    top: 40px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: #343b40;
}

.box-professional-table {
    display: flex;
    justify-content: center;
    width: 817px;
}

.profissionaisTablaName {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    text-decoration-line: underline;

    color: #0064b4;
}

.labelLastAuter {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */
    display: flex;
    align-items: center;

    /* Texto */
    color: #343b40;
}

.professionalDataTableAction > a > img {
    /* display: flex; */
    margin-right: 10px;
}

.complimentsBox {
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 20px;
    display: flex;
}

.complimentButton {
    width: 50%;
}

.complimentButtonPressed {
    width: 50%;
}

.complimentButton > button {
    width: 100%;
    background-color: #fff;
    border-radius: 50px;
    padding: 10px;
    margin: 10px 3px 0;
    border: 1px solid #0064b4;
}

.complimentButtonPressed > button {
    width: 100%;
    background-color: #0064b4;
    border-radius: 50px;
    padding: 10px;
    margin: 10px 3px 0;
    border: 1px solid #fff;
}

.complimentBorder {
    background-color: #0064b4;
    border-radius: 50px;
    padding: 10px;
    margin: 10px 3px 0;
    border-width: 2px;
    border-color: #1486e1;
    justify-content: space-between;
}

.complimentBorderWhite {
    background-color: #fff;
    border-radius: 50px;
    padding: 10px;
    margin: 10px 3px 0;
    border-width: 2px;
    border-color: #1486e1;
    justify-content: space-between;
}

.complimentText {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #fff;
    text-transform: capitalize;
}

.complimentTextBlue {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #0064b4;
    text-transform: capitalize;
}

.div_teste {
    display: flex;
    flex-direction: row;
    background-color: #b40000;
    width: 50%;
}

.boxCommentProfessional {
    width: 100%;
}

.boxCommentProfessionalText {
    text-align: left;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: #343b40;
}

.textAreaCommentProfessional {
    background: #ffffff;
    border: 1px solid #c7c9d9;
    border-radius: 8px;
}

.recipe-container {
    display: flex;
    flex-direction: column !important;
    align-items: flex-start !important;
}

.recipe-inner-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 446px;
    left: 56px;
    top: 449px;
    background: #ffffff;
    border: 1px solid #dbdde0;
    border-radius: 18px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 10px;
    margin-top: 20px;
}

.title-recipe {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #0064b4;
}

.sub-title-recipe {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #3a393b;
    padding-top: 10px;
}

.sub-info-recipe {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #3a393b;
}

.recipe-value-text {
    width: 400px;
    height: 56px;
    left: 0px;
    top: 22px;
    background: #ffffff;
    border: 1px solid #c7c9d9;
    border-radius: 8px;
}

.linha-pintar {
    height: inherit;
    border: 1px solid #000;
    width: 100px;
    background-color: pink;
}

table > datatable > td {
    height: 100%;
} /* Will be ignored, don't worry. */
tr {
    height: 100%;
}
td {
    height: 100%;
}
td > div {
    height: 100%;
}

.linhaADireita {
    text-align: right;
}

/* ******************************NEW PAGINATION********************************/
.pagination .active .page-link {
    background-color: #4285f4 !important;
}

.pagination .page-item .page-link {
    font-size: 0.9rem;
    color: #292321;
    background-color: transparent;
    border: 0;
    outline: 0;
    transition: all 0.3s linear;
    font-weight: 400;
}

.row-disabled {
    color: gray;
}

/* ****************************************************************************/

/* Remove o icon default que vem no select do Mui */
.screen-custom > .MuiSvgIcon-root {
    visibility: hidden !important;
}

.dataService > .MuiInputBase-root {
    width: 400px;
    fontfamily: "Inter";
    fontstyle: "normal";
    fontweight: 400;
    fontsize: "16px";
    lineheight: "19px";
    color: "#343B40";
    background-color: "#ECEDEE !important";
    borderradius: "8px !important";

    @media (min-width: 1024px) and (max-width: 1280px) {
        width: 300px;
    }
}

.contato_acesso > .MuiFormControl-root,
.MuiTextField-root {
    @media (min-width: 1024px) and (max-width: 1280px) {
        width: 300px !important;
    }
}

.step1Cadast > .MuiInputBase-root {
    @media (min-width: 1280px) and (max-width: 1280px) {
        width: 440px !important;
    }
}

.step2Cadast > .MuiFormControl-root,
.MuiTextField-root {
    @media (min-width: 1280px) and (max-width: 1280px) {
        width: 440px !important;
    }
}
