
.gridLayout  {
    border-right-color: #ffffff !important;
    box-shadow: 0px 0px 2px rgba(37, 42, 49, 0.16), 0px 1px 4px rgba(37, 42, 49, 0.12) !important;
}

.containerGeral {
    display: flex;
    @media (min-width: 1920px) and (max-width: 1920px) {
        background-color: #FFF;
    }

    @media (min-width: 1366px) and (max-width: 1366px) {
        background-color: #FFF;
    }

}