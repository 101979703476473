.caminhoDePaoContainer {
    justify-content: left;
    flex-direction: row !important;
    display: flex;
    align-items: center;
    // margin-bottom: 16px;    
    word-break: break-all;
}

.caminhoDePaoHome {
    width: 34px;
    height: 16px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    text-decoration-line: underline;
    color: #0064b4;
    text-decoration-color: #0064b4;
    word-break: keep-all;
}

.caminhoDePaoSeta {
    margin: 0 15px 0 !important;
}

.caminhoDePaoMeio {
    max-width: 128px;
    height: 16px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-decoration-line: underline;
    text-decoration-color: #343B40;
    a {
        color: #343B40;
    }
}

.caminhoDePaoPagina {
    height: 16px;
    left: 212px;
    top: 0px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #343b40;
    margin-right: 15px;    
    word-break: break-all;
}