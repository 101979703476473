.table > thead {
    vertical-align: bottom;
    background: #cfd9e1;
    border: 1px solid #cfd9e1 !important;
    font-family: Inter, sans-serif;
    font-style: normal;
    line-height: 17px;
    text-align: center;
    tr > th {
        font-family: Inter, sans-serif;
        font-weight: 600 !important;
        font-size: 14px !important;
        color: #252a31 !important;
    }
}

.table > tbody > tr > td {
    font-family: Inter, sans-serif;
    font-weight: 400 !important;
    font-size: 14px !important;
    color: #252a31 !important;
    border: 1px solid #c7c9d9 !important;
}

.boxColor {
    min-width: 118px;
    height: 23px;
    padding: 0px 2px;
    border-radius: 99px;
}

.pillBadgesText {
    color: #ffffff;
    font-family: Inter, sans-serif;
    font-weight: 600;
    font-size: 10px;
    text-align: center;
}

.serviceInfo {
    display: flex;
    justify-content: center;
    align-items: center !important;
    span {
        font-family: Inter, sans-serif;
        font-weight: 400 !important;
        font-size: 14px !important;
        color: #252a31 !important;
    }
    span:nth-child(2) {
        width: 35px;
        height: 25px;
        border-radius: 50px;
        margin-left: 8px;
        border: 1px solid #0064b4;
        color: #0064b4 !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.titleHist {
    font-family: Inter, sans-serif;
    font-weight: 600 !important;
    font-size: 14px !important;
    color: #343b40 !important;
    margin-bottom: 16px !important;
}

.textJustif {
    font-family: Inter, sans-serif;
    font-weight: 700;
    font-size: 12px;
    line-height: 14.52px;
    text-decoration: underline;
    color: #0064b4;
    cursor: pointer;
}

.textAguardJustif {
    font-family: Inter, sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 16.94px;
    color: #252a31;
}

.label {
    font-family: Inter, sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 16.94px;
    color: #343b40;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 6px !important;
}

.labelOpenDate {
    font-family: Inter, sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 16.94px;
    color: #343b40;
    display: flex;
    justify-content: flex-start;
    margin-top: 42px;
}

.containerChackBox {
    display: flex;
    justify-content: flex-start;
    margin-top: 16px;
    span {
        padding: 0 !important;
    }
}

.containerJust {
    display: flex;
    flex-direction: column;
}

.containerJust {
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: #0064b4 #ecedee !important;

    @media (min-width: 1920px) and (max-width: 1920px) {
        height: 330px;
    }

    @media (min-width: 1280px) and (max-width: 1280px) {
        height: 230px;
    }
}

.containerJust::-webkit-scrollbar {
    height: 500px;
    width: 5px;
}

.containerJust::-webkit-scrollbar-thumb {
    background: #0064b4;
}

.textAreaComment {
    width: 400px !important;
    height: 135px !important;
    background: #ffffff;
    border: 1px solid #c7c9d9;
    border-radius: 8px !important;
    resize: none;
    outline: 0;

    &::placeholder {
        color: #b3b5b6 !important;
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19.36px;
    }

    padding: 19px 17px !important;

    &:focus {
        box-shadow: none !important;
        background-color: #ffffff !important;
        border: 1px solid #c7c9d9 !important;
        outline: none !important;
    }
}

.textCheckBox {
    font-family: Inter, sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 22.4px;
    margin-left: 8px;
}

// ************************************************************************

#checkbox {
    position: relative;
    appearance: none;
    outline: none;
    width: 88px;
    height: 35px;
    border-radius: 50px;
    background: #d8d9da;
    box-shadow: none;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}

#checkbox:checked {
    background: #d8d9da;
}

#checkbox::before {
    content: url("../../../../assets/images/icons/check2.png");
    position: absolute;
    border-radius: inherit;
    transition: inherit;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    color: #fff;
    top: 2px;
    width: 40px;
    height: 31px;
    left: calc(11px);
}

#checkbox:checked::after {
    content: "X";
    position: absolute;
    color: #fff;
    top: 2px;
    border-radius: inherit;
    width: 40px;
    height: 31px;
    background: #e60e19;
    transition: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    left: calc(100% - 42px);
    margin-right: 15px !important;
    z-index: 2;
}

#checkbox::after {
    content: url("../../../../assets/images/icons/check2.png");
    left: 0;
    position: absolute;
    color: #fff;
    top: 2px;
    border-radius: inherit;
    width: 40px;
    height: 31px;
    background: #0a53be;
    transition: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 1px;
    z-index: 2;
}

// ************************************************************************

.buttonSave {
    min-width: 169px;
    height: 56px;
    background: linear-gradient(268.05deg, #0064b4 4.03%, #1486e1 101.81%);
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    border-radius: 50px;
    border: 0;

    span {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
        padding: 20.5px 64px;
    }
}

.buttonSaveBlock {
    min-width: 169px;
    height: 56px;
    background: #b3b5b6;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    border-radius: 50px;
    border: 0;

    span {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
        padding: 20.5px 64px;
    }
}

.containerButton {
    display: flex;
    justify-content: flex-end;
    margin-top: 12px;
}

// ************************************************************************

.typeCheckbox {
    display: flex;
    flex-direction: row;
    display: flex;
    border-radius: 50px;
    align-items: center;
    div {
        color: #ffffff;
        font-size: 16px;
        margin-left: -22px;
        z-index: 1;
        font-family: Inter, sans-serif;
        cursor: pointer;
    }
}

#checkboxDisabled {
    position: relative;
    appearance: none;
    outline: none;
    width: 88px;
    height: 35px;
    border-radius: 50px;
    background: #d8d9da;
    box-shadow: none;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}

#checkboxDisabled:checked {
    background: #d8d9da;
}

#checkboxDisabled::before {
    content: url("../../../../assets/images/icons/check2.png");
    position: absolute;
    border-radius: inherit;
    transition: inherit;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    color: #fff;
    top: 2px;
    width: 40px;
    height: 31px;
    left: calc(11px);
}

#checkboxDisabled:checked::after {
    content: "X";
    position: absolute;
    color: #fff;
    top: 2px;
    border-radius: inherit;
    width: 40px;
    height: 31px;
    background: #656a6e;
    transition: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    left: calc(100% - 42px);
    margin-right: 15px !important;
    z-index: 2;
}

#checkboxDisabled::after {
    content: url("../../../../assets/images/icons/check2.png");
    left: 0;
    position: absolute;
    color: #fff;
    top: 2px;
    border-radius: inherit;
    width: 40px;
    height: 31px;
    background: #656a6e;
    transition: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 1px;
    z-index: 2;
}

.checkToggle {
    background-color: #d8d9da;
    width: 88px;
    height: 35px;
    border-radius: 50px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
    a span:nth-child(1) {
        display: flex;
        width: 44px;
        height: 35px;
        justify-content: center;
        align-items: center;
        img {
            width: 14px;
            height: 10px;
        }
    }
    div {
        font-family: Inter, sans-serif;
        font-weight: 400;
        font-size: 14px;
        color: #fff;
        width: 44px;
        height: 35px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-left: -22px;
    }
}

.flagApr {
    display: flex;
    flex-direction: row;
    align-items: center !important;
    justify-content: center;
    img {
        margin-left: 4px;
        width: 24px;
        height: 24px;
        cursor: pointer;
        margin-left: 22px;
    }
}

.containerGeralJust {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;

    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: #0064b4 #ecedee !important;

    @media (min-width: 1280px) and (max-width: 1280px) {
        height: 230px;
    }

    @media (min-width: 1366px) and (max-width: 1366px) {
        height: 210px;
    }

    @media (min-width: 1360px) and (max-width: 1360px) {
        height: 210px;
    }
}

.containerGeralJust::-webkit-scrollbar {
    height: 500px;
    width: 5px;
}

.containerGeralJust::-webkit-scrollbar-thumb {
    background: #0064b4;
}

.textLabel {
    font-family: Inter, sans-serif !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 16.94px !important;
    color: #343b40 !important;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 8px;
}

.containerInputFull {
    width: 400px !important;
    height: 56px !important;
    border-radius: 8px !important;
    padding-left: 19.88px !important;
    outline: 0 !important;

    &:focus {
        box-shadow: none !important;
        background-color: #ffffff !important;
        border: 1px solid #c7c9d9 !important;
        outline: none !important;
    }

    color: #343b40 !important;
    font-family: Inter, sans-serif !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 19.36px !important;
    border: 1px solid #c7c9d9 !important;
}

.dividerSpace {
    margin-top: 16px;
}

.fieldSelectDisabled {
    width: 400px;
    height: 56px;
    display: flex;

    select {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19.36px;
        color: #8d8f90;
        background: #ecedee;
        border: 1px solid #c7c9d9;
        border-radius: 8px;

        &:focus {
            box-shadow: none !important;
            border-color: #ccc !important;
        }

        background-image: url("../../../../assets/images/icons/arrow-down-02.png") !important;
        background-repeat: no-repeat !important;
        background-position: right 0.75rem center !important;
        background-size: 14px 7px !important;
    }
}

.fieldSelect {
    width: 400px;
    height: 56px;
    display: flex;

    select {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19.36px;
        color: #b3b5b6;
        background: #ffffff;
        border: 1px solid #c7c9d9;
        border-radius: 8px;

        &:focus {
            box-shadow: none !important;
            border-color: #ccc !important;
        }

        background-image: url("../../../../assets/images/icons/arrow-down.png") !important;
        background-repeat: no-repeat !important;
        background-position: right 0.75rem center !important;
        background-size: 14px 7px !important;
    }
}

.containerOpOutro {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 410px;
    margin-top: 8px;

    div:nth-child(1) {
        // display: flex;
        justify-content: flex-start;
        flex-direction: row;
        text-align: left;
        img {
            width: 16px;
            height: 16px;
        }
        span {
            margin-left: 4px;
            text-align: left;
            color: #8d8f90;
            font-family: Inter, sans-serif;
            font-weight: 400;
            font-size: 12px;
            line-height: 14.52px;
        }
        // width: 72%;
        word-wrap: break-word !important;
    }

    div:nth-child(2) {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        width: 28%;

        span:nth-child(1) {
            color: #8d8f90;
            font-family: Inter, sans-serif;
            font-weight: 700;
            font-size: 12px;
            line-height: 14.52px;
        }

        span:nth-child(2) {
            color: #8d8f90;
            font-family: Inter, sans-serif;
            font-weight: 400;
            font-size: 12px;
            line-height: 14.52px;
        }
    }
}
