.buttonDisabled {
    width: 181px;
    height: 60px;
    margin-left: 15px;
    color: #fff;
    border: 0;
    border-radius: 50px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    background: #b3b5b6;
}

.requiredField {
    color: #e60e19 !important;
}

.formGroup {
    width: 49%;
    flex-direction: column;
    display: flex;
}

.formGroupError {
    input[type="date"],
    input[type="number"],
    input[type="tel"],
    input[type="text"],
    select {
        box-sizing: border-box;
        border: 2px solid red !important;
        border-radius: 4px;
    }
    div > div > div > div {
        border: 2px solid red !important;
    }
}

.messageBackend {
    font-family: "Inter", sans-serif;
    color: #e60e19;
    font-size: 20px;
    width: 100%;
    display: flex;
    margin-bottom: 10px;
}

// ***************************************************************************

.dadosTrein {
    padding: 32px 0 24px 43px;
    font-family: Inter, sans-serif !important;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.94px;
    color: #343b40;
}

.marginTopData {
    padding-top: 32px;
}

.containerGeralDadosP {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    gap: 16px;
    padding-top: 32px;
}

.subContainerGeralDados01 {
    display: flex;
    flex-direction: column;
}

.containerDatePicker2 {
    width: 100%;
    span {
        display: flex;
        font-family: Inter, sans-serif !important;
        font-size: 14px;
        font-weight: 600;
        line-height: 16.94px;
        color: #343b40;
        margin-bottom: 2px;
    }
    fieldset {
        background-color: #ffffff;
    }
    div {
        background-color: #fff;
        div {
            div {
                button {
                    z-index: 2;
                    display: block;
                    width: 30px;
                    height: 30px;
                    margin-right: 0px;

                    background: url("../../../assets/images/calendar.png") !important;
                    background-repeat: no-repeat !important;
                    background-position: right 0.1rem center !important;
                }
            }
        }
    }
}

.containerDatePickerDisabled {
    width: 100%;
    span {
        display: flex;
        font-family: Inter, sans-serif !important;
        font-size: 14px;
        font-weight: 600;
        line-height: 16.94px;
        color: #343b40;
        margin-bottom: 2px;
    }
    fieldset {
        background-color: #ffffff;
    }
}

.containerDatePicker {
    width: 100%;
    span {
        display: flex;
        font-family: Inter, sans-serif !important;
        font-size: 14px;
        font-weight: 600;
        line-height: 16.94px;
        color: #343b40;
        margin-bottom: 2px;
    }
    fieldset {
        background-color: #ffffff;
    }
    div {
        background-color: #fff;
    }
}

.dividerSpace {
    margin-top: 24px;
}

.containerFieldSelect {
    display: flex;
    flex-direction: column;
    select {
        width: 400px;
        height: 56px;
        border-radius: 8px;
        color: #343b40;
        font-family: Inter, sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: 19.36px;

        @media (min-width: 1024px) and (max-width: 1280px) {
            width: 300px !important;
        }
    }

    select:focus {
        border: 1px solid #c7c9d9;
        outline: none;
        box-shadow: none;
    }
    span {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        font-family: Inter, sans-serif;
        color: #343b40;
        line-height: 16.94px;
        margin-bottom: 2px;
    }
}

.colorDisableSelect {
    select {
        background-color: #ecedee !important;
        color: #8d8f90;
    }
}

.colorActiveSelect {
    select {
        background-color: #fff !important;
    }
}

.formDropdown {
    background-image: url("../../../assets/images/icons/arrow-down.png") !important;
    background-repeat: no-repeat !important;
    background-position: right 0.75rem center !important;
    background-size: 14px 7px !important;
}

.formDropdownDisabled {
    background-image: url("../../../assets/images/icons/arrow-down-02.png") !important;
}

.containerTextField {
    width: 100%;
    height: 56px !important;
    display: flex;
    flex-direction: column;
    span {
        font-family: Inter, sans-serif !important;
        font-size: 14px;
        font-weight: 600;
        line-height: 16.94px;
        color: #343b40;
        margin-bottom: 2px;
    }
    fieldset {
        background-color: #ffffff;
    }
    div {
        background-color: #fff;
    }
}

.containerTextField2 {
    display: flex;
    flex-direction: column;
    input {
        border-radius: 8px;
        width: 400px;
        height: 56px !important;
        box-sizing: border-box;
        padding-left: 12px;
        color: #343b40 !important;

        font-family: Inter, sans-serif !important;
        font-size: 16px;
        font-weight: 400;
        line-height: 19.36px;

        &:focus {
            background-color: #ffffff !important;
            box-shadow: none !important;
        }
        outline: 0;
        border-width: 0px;
        border: 1px solid #c7c9d9 !important;
        box-shadow: none !important;
    }

    input::placeholder {
        font-family: Inter, sans-serif !important;
        font-size: 16px;
        font-weight: 400;
        line-height: 19.36px;
        color: #b3b5b6 !important;
    }

    span {
        font-family: Inter, sans-serif !important;
        font-size: 14px;
        font-weight: 600;
        line-height: 16.94px;
        color: #343b40;
        margin-bottom: 2px;
    }
    fieldset {
        background-color: #ffffff;
    }
    div {
        background-color: #fff;
    }
}

.inputDisabled {
    input {
        background-color: #ecedee;
        color: #8d8f90 !important;
    }
}

.containerTextFieldDisabled {
    width: 100%;
    div {
        display: flex;
        flex-direction: column;
        span {
            font-family: Inter, sans-serif !important;
            font-size: 14px;
            font-weight: 600;
            line-height: 16.94px;
            color: #343b40;
            margin-bottom: 2px;
        }
        fieldset {
            background-color: #ffffff;
        }
        div {
            background-color: #ecedee;
        }
    }
}

.marginTopRequired {
    margin-top: 0px;
}

.colorFieldBlock {
    div {
        fieldset {
            background-color: #ecedee !important;
        }
    }
    select {
        background-color: #ecedee !important;
    }
}

.containerInfoButtonEditDadosP {
    display: flex;
    width: 100%;
    padding: 0 67px 0 67px;
    @media (min-width: 1920px) and (max-width: 1920px) {
        padding: 0 45px 0 45px;
    }
    @media (min-width: 1600px) and (max-width: 1600px) {
        padding: 0 45px 0 45px;
    }
    @media (min-width: 1400px) and (max-width: 1440px) {
        padding: 0 63px 0 63px;
    }
    @media (min-width: 1344px) and (max-width: 1400px) {
        padding: 0 48px 0 48px;
    }
    @media (min-width: 1280px) and (max-width: 1344px) {
        padding: 0 30px 0 30px;
    }
    @media (min-width: 1152px) and (max-width: 1280px) {
        padding: 0 60px 0 60px;
    }
    @media (min-width: 1024px) and (max-width: 1152px) {
        padding: 0 17px 0 17px;
    }
    @media (min-width: 960px) and (max-width: 1024px) {
        padding: 0 31px 0 31px;
    }
    div {
        margin: 32px 0;
    }
}

.containerBtn {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    div {
        margin: 32px 0;
    }
}

.containerBtnMarginAvaliar {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    div {
        margin: 32px 0;
    }
    margin-bottom: 90px;
}

.containerButtonEditDadosP {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    padding: 0 45px;
    div {
        font-weight: 600;
        font-size: 14px;
        font-family: Inter, sans-serif;
        color: #0064b4;
        line-height: 16.94px;
        text-decoration: underline;
        cursor: pointer;
    }
}

.buttonSave {
    min-width: 169px;
    height: 56px;
    background: linear-gradient(268.05deg, #0064b4 4.03%, #1486e1 101.81%);
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    border-radius: 50px;
    border: 0;
    margin-left: 45px;

    span {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
        padding: 20.5px 64px;
    }
}

.buttonDisabledAvaliar {
    min-width: 169px;
    height: 56px;
    margin-left: 15px;
    color: #fff;
    border: 0;
    border-radius: 50px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    background: #b3b5b6;

    span {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19.36px;
        color: #ffffff;
        padding: 18px;
    }
}

.buttonSaveAvaliar {
    min-width: 169px;
    height: 56px;
    background: linear-gradient(268.05deg, #0064b4 4.03%, #1486e1 101.81%);
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    border-radius: 50px;
    border: 0;

    span {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19.36px;
        color: #ffffff;
        padding: 18px;
    }
}

.containerBtnAvaliar {
    padding: 0 67px 0 67px;
    justify-content: flex-end;
    display: flex;
    margin-bottom: 32px !important;
    // height: 90px;
    padding: 0 45px;
    @media (min-width: 1920px) and (max-width: 1920px) {
        padding: 0 45px 0 45px;
    }
    @media (min-width: 1600px) and (max-width: 1600px) {
        padding: 0 45px 0 45px;
    }
    @media (min-width: 1400px) and (max-width: 1440px) {
        padding: 0 63px 0 63px;
    }
    @media (min-width: 1344px) and (max-width: 1400px) {
        padding: 0 48px 0 48px;
    }
    @media (min-width: 1280px) and (max-width: 1344px) {
        padding: 0 30px 0 30px;
    }
    @media (min-width: 1152px) and (max-width: 1280px) {
        padding: 0 60px 0 60px;
    }
    @media (min-width: 1024px) and (max-width: 1152px) {
        padding: 0 17px 0 17px;
    }
    @media (min-width: 960px) and (max-width: 1024px) {
        padding: 0 31px 0 31px;
    }
}

.containerRel {
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0 10px;
    font-family: "Inter", sans-serif;
    font-style: "normal";
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #0064b4;
    a {
        text-decoration-line: underline;
    }
    div:nth-child(1) {
        font-family: "Inter", sans-serif;
        font-weight: 600;
        font-size: 14px;
        line-height: 16.94px;
        color: #343b40;
    }
    div:nth-child(2) {
        cursor: pointer;
        font-family: "Inter", sans-serif;
        font-style: "normal";
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        text-decoration-line: underline;
        color: #0064b4;
        word-spacing: 2px;
        flex-direction: row;
        display: flex;
        gap: 8px;
        span {
            background-color: #ecedee;
            width: 40px;
            height: 40px;
            border-radius: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                width: 24px;
                height: 24px;
            }
        }
    }
}

.containerInputs {
    display: flex;
    flex-direction: column;
    align-items: center !important;
    // margin-top: 16px;
}

.linha1,
.linha2,
.linha3,
.linha4 {
    display: flex;
    flex-direction: row;
    gap: 20px;
}
