.reactPaginate {
    justify-content: space-between;
    display: flex;
    span {
        margin-bottom: 18px;
        font-weight: 400;
    }
}

.colorTrOne {
    background-color: #eeeeee;
}

.colorTrTwo {
    background-color: #fbfbfb;
}

.colorTrRed {
    background-color: #e6d0d1;
}

.table > thead {
    vertical-align: bottom;
    background: #cfd9e1;
    border: 1px solid #cfd9e1 !important;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #252a31;
}

.boxColor {
    min-width: 118px;
    height: 23px;
    padding: 0px 2px;
    border-radius: 99px;
}

.pillBadgesText {
    line-height: 1.5;
    letter-spacing: 0.00938em;
    color: #ffffff;
    margin: 0px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
}

.actionIcon {
    flex-direction: row;
    display: flex;
    align-items: center;
    div {
        margin-left: 5px;
    }
}

.margiContainer {
    margin-bottom: 20px;
    background-color: red;
}

.formSelect {
    display: block;
    width: 10%;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    -moz-padding-start: calc(0.75rem - 3px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.6;
    color: #4f4f4f;
    background-color: #fff;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat !important;
    background-position: right 0.75rem center !important;
    background-size: 16px 12px !important;
    border: 1px solid #bdbdbd;
    border-radius: 0.25rem;
    transition: all 0.2s linear;
    appearance: none;
}

.cursorPointer {
    cursor: pointer;
}
