// **************************************************************************

.containerGeral {
    background-color: #f7f7f7;
    width: 879px !important;
    margin-bottom: 50px;
    border-radius: 8px;
}

.containerInput {
    padding: 32px 15px 5px;
}

.containerRow {
    margin-bottom: 24px;
    justify-content: center;
}

.inputLine {
    justify-content: center;
    display: flex;
    div:nth-child(1) {
        width: 400px;
        flex-direction: column;
    }

    label {
        font-family: Inter, sans-serif;
        font-weight: 600 !important;
        font-size: 14px;
        line-height: 16.94px;
        color: #343b40;
    }
}

.buttonRelatBlock {
    width: 169px;
    max-width: 169px;
    height: 56px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    align-items: center;
    text-align: center;
    background: #b3b5b6;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    border-radius: 50px;
    color: #656a6e;
    border: 0;
}

.buttonRelat {
    min-width: 169px;
    height: 56px;
    background: #0064b4;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    border-radius: 50px;
    border: 0;

    span {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19.36px;
        color: #ffffff;
        padding: 20.5px 32px;
    }
}

.bntGerarRelat {
    display: flex !important;
    justify-content: flex-end !important;
    margin-bottom: 32px;
}

.inputText {
    width: 400px;
    height: 56px;
    color: #8d8f90;
    background: #ffffff;
    border: 1px solid #e2e8f0;
    box-sizing: border-box;
    border-radius: 8px !important;
    padding: 10px !important;
}
.inputText::placeholder {
    position: absolute;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #b3b5b6 !important;
}
.inputText:focus {
    color: #212529;
    background-color: #ffffff;
    outline: 0;
    box-shadow: none !important;
}

// ************************************************************************

#checkbox {
    position: relative;
    appearance: none;
    outline: none;
    width: 50px;
    height: 18px;
    border-radius: 20px;
    background: #d8d9da;
    box-shadow: none;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    top: 3px;
}

#checkbox:checked {
    background: #d8d9da;
}

#checkbox::before {
    content: "";
    position: absolute;
    border-radius: inherit;
    width: 26px;
    height: 26px;
    transition: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
}

#checkbox::after {
    content: "X";
    position: absolute;
    color: #fff;
    top: -4px;
    border-radius: inherit;
    width: 26px;
    height: 26px;
    background: #e60e19;
    transition: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    right: calc(100% - 25px);
}

#checkbox:checked::after {
    content: url("../../../assets/images/icons/check2.png");
    right: 0;
    background: #0064b4;
}

.textSpanSwitch {
    font-family: Inter, sans-serif;
    color: #343b40;
    font-weight: 600;
    font-size: 14px;
    line-height: 16.94px;
}

.containerRowSwitch {
    margin-bottom: 24px;
    display: flex;
    flex-direction: row !important;
    gap: 66px;
}

.containerSwitch {
    flex-direction: row;
    margin-bottom: 37.5px;
    display: flex;
    align-items: center;
    gap: 24px;
}
