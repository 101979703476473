.table {
    margin: 20px 0 30px 0;
    thead {
        vertical-align: bottom;
        background: #cfd9e1;
        border: 1px solid #cfd9e1 !important;
        text-align: center;
        tr {
            th {
                font-family: Inter, sans-serif;
                font-style: normal;
                font-weight: 600;
                font-size: 12.94px;
                line-height: 15.66px;
                color: #252a31;
                div:first-child {
                    width: 22.18px;
                    height: 22.18px;
                    background: #0064b4;
                    border-radius: 3.69677px;
                    display: flex;
                    span:first-child {
                        width: 13px;
                        height: 1.84839px;
                        margin-top: 10px;
                        background-color: #ffffff;
                        display: flex;
                        margin-left: 3.6px;
                    }
                }
            }
        }
    }
}

.tableForm {
    background-color: #ecedee;
    padding: 30px !important;
    width: 820px;
    border-radius: 16px;
}

.containerTable {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    flex-direction: column;
    display: flex;
    span:nth-child(1) {
        text-decoration-line: underline;
        color: #0064b4;
        justify-content: flex-end;
        margin: 40px 0 23px 0;
        align-items: flex-end;
        align-self: flex-end;
        cursor: pointer;
    }
    span:nth-child(2) {
        color: #343b40;
        justify-content: flex-start;
        margin-bottom: 14px;
    }
}

.tbody {
    background-color: #ffffff;
    td {
        border: 0.5px solid #c7c9d9;
    }
}

.imgEYE {
    // display: block;
    filter: grayscale(100%) brightness(125%);
    // opacity: 0.5;
    transition: filter 0.5s ease-out, opacity 0.5s ease-out;
}

.containerModal {
    height: 578px;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: #0064b4 #ecedee !important;
    @media (min-width: 1366px) and (max-width: 1366px) {
        height: 350px !important;
    }

    @media (min-width: 1440px) and (max-width: 1600px) {
        height: 478px !important;
    }

    @media (min-width: 1280px) and (max-width: 1344px) {
        height: 378px !important;
    }

    @media (min-width: 1024px) and (max-width: 1280px) {
        height: 358px !important;
    }
}

.containerModal::-webkit-scrollbar {
    height: 578px;
    width: 5px;
}

.containerModal::-webkit-scrollbar-thumb {
    background: #0064b4;
}

.inputSearchRestriction {
    width: 612px !important;
    height: 56px;
    color: #8d8f90;
    background: #ffffff;
    border: 1px solid #e2e8f0;
    box-sizing: border-box;
    border-radius: 8px;

    ::placeholder {
        position: absolute;
        left: 1.2%;
        right: 9.3%;
        top: 32.14%;
        bottom: 32.14%;
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        /* identical to box height */
        display: flex;
        align-items: center;
        /* Cinza 2 */
        color: #b3b5b6 !important;
    }

    :focus {
        color: #212529;
        background-color: #ffffff;
        border-color: #ffffff;
        outline: 0;
        box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.25);
    }
}

.boxModal {
    width: 100%;
}

.containerInput {
    display: flex;
    justify-content: space-between;
}

.buttonSearch {
    width: 169px;
    max-width: 169px;
    height: 56px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    align-items: center;
    text-align: center;
    background: linear-gradient(268.05deg, #0064b4 4.03%, #1486e1 101.81%);
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    border-radius: 50px;
    color: #ffffff;
    border: 0;
}

.buttonSearchBlock {
    width: 169px;
    max-width: 169px;
    height: 56px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    align-items: center;
    text-align: center;
    background: #b3b5b6;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    border-radius: 50px;
    color: #656a6e;
    border: 0;
}

.containerSelect {
    display: flex;
    justify-content: space-between;

    div > select {
        width: 395px;
        height: 56px;
    }
}

.textOperator {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #343b40;
}

.characterNumber {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    color: #8d8f90;
}

.characterLetter {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #8d8f90;
}

.formDropdown {
    background-image: url("../../../assets/images/icons/arrow-down.png") !important;
    background-repeat: no-repeat !important;
    background-position: right 0.75rem center !important;
    background-size: 14px 7px !important;
}

.formDropdownView {
    background-image: url("data:image/svg+xml;charset=utf-8,<svg width='12' height='8' fill='none' xmlns='http:%2F%2Fwww.w3.org/2000/svg'><path d='M1 1l4 4 4-4' stroke='gray' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/></svg>") !important;
}

.radioCircle {
    width: 23px;
    height: 23px;
    border-radius: 12px;
    border: 2px solid #0064b4;
    display: flex;
    justify-content: center;
    align-items: center;
}

.radCirNoSelected {
    width: 23px;
    height: 23px;
    border-radius: 12px;
    border: 2px solid #b3b5b6;
    background-color: #d8d9da;
}

.tbodyModal {
    background-color: #ecedee;
    td {
        border: 0.5px solid #c7c9d9;
        word-break: break-all;
    }

    tr {
        cursor: pointer;
        td:first-child {
            width: 10px;
        }
    }
}

.circleBall {
    background-color: #0064b4;
    width: 11px;
    height: 11px;
    border-radius: 6px;
    display: flex;
}

.textAreaCommentView {
    background: #ecedee;
    border: 1px solid #c7c9d9;
    border-radius: 8px;
    resize: none;
    height: 133px;
}

.requiredField {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    font-family: Inter, sans-serif;
    display: flex;
    justify-content: flex-start;
    color: #e60e19;
}

.formGroupError {
    textarea,
    input[type="text"],
    select {
        box-sizing: border-box;
        border: 2px solid red !important;
        border-radius: 4px;
    }
    div > div > div > div {
        border: 2px solid red !important;
    }
}

.textSelected {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12.94px;
    line-height: 16px;
    color: #0064b4;
}

.textNoSelected {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12.94px;
    line-height: 16px;
    color: #8d8f90;
}

.infoText {
    font-family: Inter, sans-serif;
    font-weight: 400 !important;
    font-size: 12.94px !important;
}

.boxCommentText {
    text-align: left;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: #343b40;
}

.textAreaComment {
    background: #ffffff;
    border: 1px solid #c7c9d9;
    border-radius: 8px;
    resize: none;
    height: 133px;
}

// *********************************pagination*********************************

.reactPaginate {
    display: flex;
    justify-content: center;
}

.reactPaginateModal {
    display: flex;
    justify-content: center;
}

.pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
}

.pageItem {
    font-size: 0.9rem;
    color: #0064b4 !important;
    background-color: transparent;
    border: 0;
    outline: 0;
    transition: all 0.3s linear;
    font-weight: 400;
}

.pageLink {
    padding: 0.375rem 0.75rem;
    position: relative;
    display: block;
    color: #0064b4 !important;
}

.disabled {
    display: none !important;
}
// **************************************************************************
