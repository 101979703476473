.table {
    margin: 20px 0 30px 0;
    thead {
        vertical-align: bottom;
        background: #cfd9e1;
        border: 1px solid #cfd9e1 !important;
        text-align: center;
        tr {
            th {
                font-family: Inter, sans-serif;
                font-style: normal;
                font-weight: 600 !important;
                font-size: 12.94px;
                line-height: 15.66px;
                color: #252a31;
                div:first-child {
                    width: 22.18px;
                    height: 22.18px;
                    background: #0064b4;
                    border-radius: 3.69677px;
                    display: flex;
                    span:first-child {
                        width: 13px;
                        height: 1.84839px;
                        margin-top: 10px;
                        background-color: #ffffff;
                        display: flex;
                        margin-left: 3.6px;
                    }
                }
            }
        }
    }
}

// Resposavel por colocar linhas
.table > tbody > tr > td {
    font-family: Inter, sans-serif;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 16.94px;
    color: #252a31 !important;
    border: 1px solid #c7c9d9 !important;
}

.textoHeader {
    font-family: Inter, sans-serif;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 16.94px;
    color: #252a31 !important;
}

.tableHeader {
    background-color: #0064b4 !important;
}

.tableHeader > tr > td {
    font-family: Inter, sans-serif;
    font-weight: 600 !important;
    font-size: 14px !important;
    color: #ffffff !important;
    line-height: 16.94px;
    border-right: 3px solid #f7f7f7 !important;
    border-bottom: 3px solid #f7f7f7 !important;
}

.containerTable {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    flex-direction: column;
    display: flex;
    span:nth-child(1) {
        text-decoration-line: underline;
        color: #0064b4;
        justify-content: flex-end;
        margin: 40px 0 23px 0;
        align-items: flex-end;
        align-self: flex-end;
        cursor: pointer;
    }
    span:nth-child(2) {
        color: #343b40;
        justify-content: flex-start;
        margin-bottom: 14px;
    }
}

.tbody {
    background-color: #ffffff;
    td {
        border: 0.5px solid #c7c9d9;
    }
}

.imgEYE {
    filter: grayscale(100%) brightness(125%);
    transition: filter 0.5s ease-out, opacity 0.5s ease-out;
}

// *********************************pagination*********************************

.reactPaginate {
    display: flex;
    justify-content: center;
}

.reactPaginateModal {
    display: flex;
    justify-content: center;
}

.pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
}

.pageItem {
    font-size: 0.9rem;
    color: #0064b4 !important;
    background-color: transparent;
    border: 0;
    outline: 0;
    transition: all 0.3s linear;
    font-weight: 400;
}

.pageLink {
    padding: 0.375rem 0.75rem;
    position: relative;
    display: block;
    color: #0064b4 !important;
}

.disabled {
    display: none !important;
}
// **************************************************************************

.resultNotFound {
    display: flex;
    align-items: center;
    justify-content: center;
    span {
        text-decoration: none !important;
        cursor: text !important;
    }
}

.containerModal {
    height: 450px;
    width: 420px;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: #0064b4 #ecedee !important;

    @media (min-width: 1152px) and (max-width: 1370px) {
        height: 250px;
    }
}

.containerEndOccurrence {
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    label {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #343b40;
        display: flex;
        text-align: left;
    }
    input {
        width: 400px;
        height: 56px;
        background-color: #ecedee;
        box-sizing: border-box;
        border: 1px solid #c7c9d9;
        border-radius: 8px;
    }
    select {
        width: 400px;
        height: 56px;
        background-color: #ffffff !important;
        border: 1px solid #c7c9d9;
        border-radius: 8px;
        &:focus {
            box-shadow: none !important;
            border-color: #ccc !important;
        }
    }
    textarea {
        resize: none;
        width: 400px;
        height: 331px;
        background-color: #ffffff;
        border: 1px solid #c7c9d9;
        border-radius: 8px;
        &:focus {
            outline: 0;
            border-color: #c7c9d9;
            box-shadow: none;
        }
    }
    div:nth-child(1),
    div:nth-child(2) {
        margin-bottom: 16px;
    }
}

.textLabel {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #343b40;
    display: flex;
    text-align: left;
}

.inputSelect {
    select {
        background-color: #ecedee !important;
        background-image: url("../../../assets/images/icons/arrow-down-03.png") !important;
        background-repeat: no-repeat !important;
        background-position: right 0.75rem center !important;
        background-size: 24px 24px !important;
    }
}

.colorBackground {
    background-color: #ecedee !important;
}

.section {
    overflow-y: scroll !important;
    width: 400px;
    height: 331px;
    background-color: #ecedee;
    border: 1px solid #c7c9d9;
    border-radius: 8px;
    div {
        background-color: #ecedee;
        font-weight: 400;
        font-size: 16px;
        font-family: Inter, sans-serif;
        justify-content: flex-start;
        text-align: start !important;
        color: #b3b5b6 !important;
        border: none !important;
    }
}
