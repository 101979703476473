.novaCategoria {
    display: flex;
    justify-content: flex-end;
    margin-top: 35px;
    margin-bottom: 14px;
    font-family: Inter, sans-serif;
    font-size: 14px;
    line-height: 16.94px;
    font-weight: 600;
    a {
        color: #0064B4 !important;
    }

    :hover {
        color: #0064B4 !important;
    }
}

.listagem {
    background: #fbfbfb;
    border-radius: 8px;
    padding: 5px;
    padding-left: 25px !important;
    padding-right: 25px !important;
    padding: 15px;
    margin-bottom: 30px;
}