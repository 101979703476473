.containerStepper {
  display: flex;
  flex-direction: column;

  @media (min-width: 2048px) and (max-width: 3840px) {
    margin-left: 200px;
  }

  @media (min-width: 1920px) and (max-width: 1920px) {
    margin-left: 200px;
  }

  @media (min-width: 1600px) and (max-width: 1680px) {
    width: 560px !important;
    margin-left: 124px;
  }

  @media (min-width: 1366px) and (max-width: 1440px) {
    width: 480px !important;
    margin-left: 120px;
  }

  @media (min-width: 1360px) and (max-width: 1360px) {
    width: 540px !important;
    margin-left: 80px;
  }

  @media (min-width: 1280px) and (max-width: 1280px) {
    width: 450px !important;
    margin-left: 80px;
  }

  @media (min-width: 1024px) and (max-width: 1152px) {
    width: 300px !important;
    margin-left: 50px;
  }
}

.containerCadastro1 {
  display: flex !important;
  flex-direction: column !important;
  display: flex;
  align-self: center;
  
  @media (min-width: 1600px) and (max-width: 1680px) {
    // width: 540px !important;
  }

  @media (min-width: 1366px) and (max-width: 1440px) {
    width: 480px !important;
  }

  @media (min-width: 1360px) and (max-width: 1360px) {
    width: 540px !important;
  }

  @media (min-width: 1280px) and (max-width: 1280px) {
    width: 450px !important;    
  }
}

.containerCadastro {
  display: flex !important;
  flex-direction: column !important;
  display: flex;
  align-self: center;

  @media (min-width: 1600px) and (max-width: 1680px) {
    width: 540px !important;
  }

  @media (min-width: 1280px) and (max-width: 1280px) {
    width: 450px !important;
  }
}

.containerGeral {
  display: flex;
  flex-direction: row;
  // background-color: red;

  @media (min-width: 1600px) and (max-width: 1680px) {}
}

.loginImg {
  
  object-fit: cover;
  padding-bottom: 2px;

  @media (min-width: 2048px) and (max-width: 3840px) {
    width: 776px !important;
    height: 1000px !important;
  }

  @media (min-width: 1920px) and (max-width: 1920px) {
    width: 776px !important;
    height: 1000px !important;
  }

  @media (min-width: 1600px) and (max-width: 1680px) {
    width: 756px !important;
    min-height: 820px;
    max-height: 1000px;
  }

  @media (min-width: 1366px) and (max-width: 1440px) {
    width: 700px !important;
    min-height: 820px;
    max-height: 1000px;
  }

  @media (min-width: 1360px) and (max-width: 1360px) {
    width: 700px !important;
    min-height: 820px;
    max-height: 1000px;
  }

  @media (min-width: 1280px) and (max-width: 1280px) {
    width: 700px !important;
    min-height: 820px;
    max-height: 1000px;
  }

  @media (min-width: 1024px) and (max-width: 1152px) {
    width: 620px !important;
    min-height: 720px;
    max-height: 900px;
  }
}

.containerTextField {
  width: 100% !important;
  display: flex !important;
  flex-direction: column;

  span {
    font-family: Inter, sans-serif !important;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.94px;
    color: #343b40;
    margin-bottom: 8px;
  }

  div {
    display: flex;
    flex-direction: column;

    fieldset {
      background-color: #ffffff;
      @media (min-width: 1280px) and (max-width: 1280px) {
        width: 440px !important;
      }
    }
  }
}

.inputTextField {
  height: 56px !important;
  @media (min-width: 2048px) and (max-width: 3840px) {
    width: 714px !important;
  }

  @media (min-width: 1920px) and (max-width: 1920px) {
    width: 714px !important;
  }

  @media (min-width: 1600px) and (max-width: 1680px) {
    width: 540px !important;
  }
}

.dividerSpace {
  margin-top: 24px;
}

.containerFieldSelect {
  display: flex;
  flex-direction: column;

  div {
    span {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      font-family: Inter, sans-serif;
      color: #343b40;
      line-height: 16.94px;
    }
  }
  
  div > div {
    display: flex;
    height: 56px;
    border-radius: 8px;
    color: #343b40;
    font-family: Inter, sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 19.36px;

    @media (min-width: 2048px) and (max-width: 3840px) {
      width: 714px !important;
    }

    @media (min-width: 1920px) and (max-width: 1920px) {
      width: 714px !important;
    }
  
    @media (min-width: 1600px) and (max-width: 1680px) {
      width: 540px !important;
    }

    @media (min-width: 1366px) and (max-width: 1440px) {
      width: 480px !important;
    }

    @media (min-width: 1360px) and (max-width: 1360px) {
      width: 540px !important;
    }

    @media (min-width: 1280px) and (max-width: 1280px) {
      width: 450px !important;
    }

  }
}

.formDropdown {
  background-image: url("../../../assets/images/icons/arrow-down.png") !important;
  background-repeat: no-repeat !important;
  background-position: right 0.75rem center !important;
  background-size: 14px 7px !important;
}



.containerTextFieldSelect {
  display: flex;
  flex-direction: column;
  span:nth-child(1) {
    font-family: Inter, sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 16.94px;
    color: #343B40;
    margin-bottom: 8px;
  }
}

.imageEye {
  padding: 16px;
  cursor: pointer;
}

.containerPassWord {
  height: 56px;
  display: flex;
  flex-direction: row !important;
  border-radius: 8px;
  border: 1px solid #C7C9D9;

  @media (min-width: 2048px) and (max-width: 3840px) {
    width: 714px !important;
  }

  @media (min-width: 1920px) and (max-width: 1920px) {
    width: 714px !important;
  }

  @media (min-width: 1600px) and (max-width: 1680px) {
    width: 540px !important;
  }

  @media (min-width: 1280px) and (max-width: 1280px) {
    width: 450px !important;
  }

  @media (min-width: 1024px) and (max-width: 1152px) {
    width: 300px !important;
  }
}


.containerInput {
  border-radius: 8px;
  border: 1px solid #C7C9D9;
  padding: 1px;
}

.containerPassWordErro {
  border: 1px solid #E60E19;
  padding: 1px;
}

.textTermResp {
  font-family: Inter, sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 16.94px;
  color: #343B40;

  span {
    color: #0064B4;
    text-decoration: underline;
  }
}

.minimoCaract {
  font-family: Inter, sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 14.52px;
  color: #8D8F90;
  margin-top: 8px;
}

.fontFamily {
  p {
    font-family: Inter, sans-serif !important;
  }
}