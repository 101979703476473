.colorIndicator {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 24px;
    color: #343b40;
    font-family: Inter, sans-serif;
    font-weight: 600;
    font-size: 14px;

    div:nth-child(1) {
        gap: 8px;
        div:nth-child(1) {
            width: 24px;
            height: 24px;
            background-color: #fff1cf;
            border-radius: 4px;
            border: 1px solid #b3b5b6;
        }
        display: flex;
        flex-direction: row;
    }

    div:nth-child(2) {
        gap: 8px;
        div:nth-child(1) {
            width: 24px;
            height: 24px;
            background-color: #ecedee;
            border-radius: 4px;
            border: 1px solid #b3b5b6;
        }
        display: flex;
        flex-direction: row;
    }

    div:nth-child(3) {
        gap: 8px;
        div:nth-child(1) {
            width: 24px;
            height: 24px;
            background-color: #ffffff;
            border-radius: 4px;
            border: 1px solid #b3b5b6;
        }
        display: flex;
        flex-direction: row;
    }

    div:nth-child(4) {
        gap: 8px;
        div:nth-child(1) {
            width: 24px;
            height: 24px;
            background-color: #aacbe4;
            border-radius: 4px;
            border: 1px solid #b3b5b6;
        }
        display: flex;
        flex-direction: row;
    }

    div:nth-child(5) {
        gap: 8px;
        div:nth-child(1) {
            width: 24px;
            height: 24px;
            background-color: #ffdd98;
            border-radius: 4px;
            border: 1px solid #b3b5b6;
        }
        display: flex;
        flex-direction: row;
    }
}

.colorIndicator2 {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 24px;
    margin-top: 16px;
    color: #343b40;
    font-family: Inter, sans-serif;
    font-weight: 600;
    font-size: 14px;

    div:nth-child(1) {
        gap: 8px;
        div:nth-child(1) {
            width: 24px;
            height: 24px;
            background-color: #e6d0d1;
            border-radius: 4px;
            border: 1px solid #b3b5b6;
        }
        display: flex;
        flex-direction: row;
    }

    div:nth-child(2) {
        gap: 8px;
        align-items: center;
        display: flex;

        div:nth-child(1) {
            width: 24px;
            height: 24px;
            background-color: #cbffbe;
            border-radius: 4px;
            border: 1px solid #b3b5b6;
        }
        display: flex;
        flex-direction: row;
    }
}

.colorIndicatorEdit {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 24px;
    color: #343b40;
    font-family: Inter, sans-serif;
    font-weight: 600;
    font-size: 14px;

    div:nth-child(1) {
        gap: 8px;
        div:nth-child(1) {
            width: 24px;
            height: 24px;
            background-color: #8cccff;
            border-radius: 4px;
            border: 1px solid #b3b5b6;
        }
        display: flex;
        flex-direction: row;
    }

    div:nth-child(2) {
        gap: 8px;
        div:nth-child(1) {
            width: 24px;
            height: 24px;
            background-color: #ecedee;
            border-radius: 4px;
            border: 1px solid #b3b5b6;
        }
        display: flex;
        flex-direction: row;
    }

    div:nth-child(3) {
        gap: 8px;
        div:nth-child(1) {
            width: 24px;
            height: 24px;
            background-color: #d8d9da;
            border-radius: 4px;
            border: 1px solid #b3b5b6;
        }
        display: flex;
        flex-direction: row;
    }
}

.buttonEdit {
    padding: 20.5px 42px;
    height: 56px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    align-items: center;
    text-align: center;
    background: linear-gradient(268.05deg, #0064b4 4.03%, #1486e1 101.81%);
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    border-radius: 50px;
    color: #ffffff;
    border: 0;
    display: flex;
}

.buttonCancel {
    display: flex;
    padding: 20.5px 42px;
    width: 181px;
    height: 60px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    align-items: center;
    text-align: center;
    background: #ffffff;
    border: 1px solid #0064b4;
    border-radius: 50px;
    color: #ffffff;
    span {
        text-align: center;
        width: 100%;
        color: #0064b4;
    }
}

.buttonSave {
    display: flex;
    padding: 20.5px 42px;
    width: 181px;
    height: 60px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    align-items: center;
    text-align: center;
    background: linear-gradient(268.05deg, #0064b4 4.03%, #1486e1 101.81%);
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    border-radius: 50px;
    color: #ffffff;
    border: 0;
    span {
        text-align: center;
        width: 100%;
    }
}

.containerInfo {
    display: flex;

    select {
        width: 400px;
        height: 56px;
        border-radius: 8px;
        color: #343b40;
        font-family: Inter, sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: 19.36px;

        @media (min-width: 1024px) and (max-width: 1280px) {
            width: 300px !important;
        }
    }

    select:focus {
        border: 1px solid #c7c9d9;
        outline: none;
        box-shadow: none;
    }

    align-items: center;
    span {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        font-family: Inter, sans-serif;
        color: #343b40;
        line-height: 16.94px;
    }
}

.formDropdown {
    background-image: url("../../../assets/images/icons/arrow-down.png") !important;
    background-repeat: no-repeat !important;
    background-position: right 0.75rem center !important;
    background-size: 14px 7px !important;
}

.containerCard {
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    margin-top: 32px;
}
