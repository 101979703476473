.table > thead > tr > th {
    vertical-align: bottom;
    background: #cfd9e1;
    border: 1px solid #cfd9e1 !important;
    font-family: Inter, sans-serif;
    font-weight: 600 !important;
    font-size: 14px;
    line-height: 16.94px;
    text-align: center;
    color: #252a31;
}

.tbody {
    background-color: #ffffff;

    tr {
        border: 1px solid #c7c9d9;
    }
}

.radiusTable {
    // border-top-left-radius: 5px;
    // border-top-right-radius: 5px;
}

.infoText {
    font-family: Inter, sans-serif;
    font-size: 14px;
    line-height: 16.94px;
    font-weight: 400;
    color: #252a31;
}

.infoTextDesc {
    font-family: Inter, sans-serif;
    font-size: 14px;
    line-height: 16.94px;
    font-weight: 400;
    color: #252a31;
    text-align: left !important;
}

// *********************************pagination*********************************

.reactPaginate {
    display: flex;
    justify-content: center;
}

.reactPaginateModal {
    display: flex;
    justify-content: center;
}

.pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
}

.pageItem {
    font-size: 0.9rem;
    color: #0064b4 !important;
    background-color: transparent;
    border: 0;
    outline: 0;
    transition: all 0.3s linear;
    font-weight: 400;
}

.pageLink {
    padding: 0.375rem 0.75rem;
    position: relative;
    display: block;
    color: #0064b4 !important;
}

.disabled {
    display: none !important;
}

// **************************************************************************
