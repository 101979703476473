.containerGeral {
    width: 879px;
    background-color: #f7f7f7;
    border-radius: 8px;
}

.subContainerGeral {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 32px 32px 45px;
}

.imageTrash {
    img {
        width: 40px !important;
        height: 40px !important;
        position: absolute;
        margin-left: -50px;
        margin-top: 10px;
        cursor: pointer;
    }
}

// ******************************************************************************

.labelTextTitle {
    font-family: Inter, sans-serif;
    font-size: 14px;
    line-height: 16.94px;
    font-weight: 600;
    color: #343b40;
    margin-bottom: 33px;
}

.text01 {
    font-family: Inter, sans-serif;
    font-size: 14px;
    line-height: 16.94px;
    font-weight: 400;
    color: #343b40;
}

.text02 {
    font-family: Inter, sans-serif;
    font-size: 14px;
    line-height: 16.94px;
    font-weight: 600;
    color: #343b40;
}

.textCampos {
    font-family: Inter, sans-serif;
    font-size: 14px;
    line-height: 16.94px;
    font-weight: 400;
    color: #343b40;
    margin-top: 24px;
}

.containerInfo {
    display: flex;
    flex-direction: column;

    span:nth-child(2) {
        margin-top: 10px;
    }
}

.divider {
    border: 1px solid #c7c9d9;
    margin-top: 24px;
}

.identificador {
    font-family: Inter, sans-serif;
    font-size: 14px;
    line-height: 16.94px;
    font-weight: 600;
    color: #343b40;
    text-decoration: underline;
}
