// *********************************pagination*********************************

.reactPaginate {
    display: flex;
    justify-content: center;
}

.reactPaginateModal {
    display: flex;
    justify-content: center;
}

.pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
}

.pageItem  {
    font-size: 0.9rem;
    color: #0064B4 !important;
    background-color: transparent;
    border: 0;
    outline: 0;
    transition: all 0.3s linear;
    font-weight: 400;
}

.pageLink {
    padding: .375rem .75rem;
    position: relative;
    display: block;
    color: #0064B4 !important;
}

.disabled {
    display: none !important;
}
// **************************************************************************

.colorTrOne {
    background-color: #eeeeee;
}

.colorTrTwo {
    background-color: #fbfbfb;
}

.colorTrRed {
    background-color: #E6D0D1;
}

.table > thead > tr > th {
    vertical-align: bottom;
    background: #cfd9e1;
    border: 1px solid #cfd9e1 !important;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16.94px;
    text-align: center;
    color: #252a31;
}

.table {
    td {
        font-family: Inter, sans-serif;
        font-weight: 400;
        font-size: 14px !important;
    }
}

.boxColor {
    min-width: 57px;
    width: 73px;
    height: 18px;
    border-radius: 99px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.boxTd {
    display: flex;
    justify-content: center;
    border: none !important;
}

.pillBadgesText {
    letter-spacing: 0.00938em;
    color: #ffffff;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    text-align: center;
    display: flex;
    justify-content: center;
}

.textNewOccurrence {
    display: flex;
    justify-content: flex-end;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-decoration-line: underline;
    color: #0064B4;
    margin: 10px 0 14px;
    span {
        cursor: pointer;
    }
}

.containerRel {
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 10px 0 10px;
    font-family: "Inter", sans-serif;
    font-style: "normal";
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #0064b4;
    a {
        text-decoration-line: underline;
    }
    div:nth-child(1) {
        cursor: pointer;
        font-family: "Inter", sans-serif;
        font-style: "normal";
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        text-decoration-line: underline;
        color: #0064b4;
        word-spacing: 2px;
        flex-direction: row;
        display: flex;
        gap: 8px;
        span {
            background-color: #ECEDEE;
            width: 40px;
            height: 40px;
            border-radius: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                width: 24px;
                height: 24px;
            }
        }
    }
}