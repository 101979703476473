.containerModal {
    height: 450px;
    width: 420px;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: #0064b4 #ecedee !important;

    @media (min-width: 1152px) and (max-width: 1370px) {
        height: 370px;
    }
}

.containerModal::-webkit-scrollbar {
    height: 500px;
    width: 5px;
}

.containerModal::-webkit-scrollbar-thumb {
    background: #0064b4;
}

.recipeContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
    text-align: left !important;
}

.pdfPage {
    position: relative;
    margin: 0 auto;
    padding: 20px;
    color: #333;
    background-color: #fff;
    box-sizing: border-box;
}

.recipeInnerContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
    text-align: left !important;
    width: 446px;
    left: 56px;
    top: 449px;
    background: #ffffff;
    border: 1px solid #dbdde0;
    border-radius: 18px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 10px;
    margin-top: 20px;
}

.titleRecipe {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #0064b4;
}

.subTitleRecipe {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #3a393b;
    padding-top: 10px;
    word-break: break-all;
}

.subInfoRecipe {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #3a393b;
    word-break: break-all;
}

@font-face {
    font-family: "DejaVu Sans";
    src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans.ttf")
        format("truetype");
}

@font-face {
    font-family: "DejaVu Sans";
    font-weight: bold;
    src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans-Bold.ttf")
        format("truetype");
}

@font-face {
    font-family: "DejaVu Sans";
    font-style: italic;
    src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans-Oblique.ttf")
        format("truetype");
}

@font-face {
    font-family: "DejaVu Sans";
    font-weight: bold;
    font-style: italic;
    src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans-Oblique.ttf")
        format("truetype");
}
