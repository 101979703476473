// *********************************pagination*********************************

.reactPaginate {
    display: flex;
    justify-content: center;
}

.reactPaginateModal {
    display: flex;
    justify-content: center;
}

.pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
}

.pageItem  {
    font-size: 0.9rem;
    color: #0064B4 !important;
    background-color: transparent;
    border: 0;
    outline: 0;
    transition: all 0.3s linear;
    font-weight: 400;
}

.pageLink {
    padding: .375rem .75rem;
    position: relative;
    display: block;
    color: #0064B4 !important;
}

.disabled {
    display: none !important;
}
// **************************************************************************

.colorTrOne {
    background-color: #eeeeee;
}

.colorTrTwo {
    background-color: #fbfbfb;
}

.colorTrRed {
    background-color: #E6D0D1;
}

.table > thead {
    vertical-align: bottom;
    background: #cfd9e1;
    border: 1px solid #cfd9e1 !important;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #252a31;
}

.boxColor {
    min-width: 57px;
    width: 73px;
    height: 18px;
    border-radius: 99px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
}

.boxTd {
    display: flex;
    justify-content: center;
    border: none !important;
}

.pillBadgesText {
    line-height: 1.5;
    letter-spacing: 0.00938em;
    color: #ffffff;
    margin: 0px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    text-align: center;
    justify-content: center;
}

.textNewOccurrence {
    display: flex;
    justify-content: flex-end;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-decoration-line: underline;
    color: #0064B4;
    margin: 10px 0 14px;
    span {
        cursor: pointer;
    }
}

.classProf {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    span {
        margin-right: 10px !important;
    }
}

.dataCircle {
    width: 37px;
    height: 37px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid #0064b4;
    border-radius: 50px;
    gap: 5px;
    padding: 20px 25px !important;

    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #0064b4;
}

.maginTop {
    margin-top: 80px;
}