// *********************************pagination*********************************

.reactPaginate {
    display: flex;
    justify-content: center;
}

.reactPaginateModal {
    display: flex;
    justify-content: center;
}

.pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
}

.pageItem {
    font-size: 0.9rem;
    color: #0064b4 !important;
    background-color: transparent;
    border: 0;
    outline: 0;
    transition: all 0.3s linear;
    font-weight: 400;
}

.pageLink {
    padding: 0.375rem 0.75rem;
    position: relative;
    display: block;
    color: #0064b4 !important;
}

.disabled {
    display: none !important;
}
// **************************************************************************

.colorTrOne {
    background-color: #eeeeee;
}

.colorTrTwo {
    background-color: #fbfbfb;
}

.colorTrRed {
    background-color: #e6d0d1;
}

.radiusTable {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.table > thead {
    vertical-align: bottom;
    background: #cfd9e1;
    border: 1px solid #cfd9e1 !important;
    font-family: Inter, sans-serif;
    font-style: normal;
    line-height: 17px;
    text-align: center;
    tr > th {
        font-family: Inter, sans-serif;
        font-weight: 600 !important;
        font-size: 14px !important;
        color: #252a31 !important;
    }
}

.table > tbody > tr > td {
    font-family: Inter, sans-serif;
    font-weight: 400 !important;
    font-size: 14px !important;
    color: #252a31 !important;
}

.textNewOccurrence {
    display: flex;
    justify-content: flex-end;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 17px;
    text-decoration-line: underline;
    color: #0064b4;
    margin: 10px 0 14px;
    span {
        cursor: pointer;
    }
}
