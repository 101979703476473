.containerFilds {
    margin-top: 16px;
    display: block;
    width: 760px;
    cursor: auto;
}

.subContainerFilds {
    display: flex;
    span {
        font-family: Inter, sans-serif !important;
        color: #0064b4;
        display: flex;
        width: 280px;
        font-style: normal;
        font-weight: 400 !important;
        font-size: 16px !important;
        line-height: 19px;
    }
    margin-top: 20px !important;
}

.containerSelect {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;

    div:first-child {
        margin-right: 8px;
    }

    div:nth-child(3) {
        margin: 12px 8px 0 0;
    }

    div:nth-child(4) {
        margin-top: 12px;
    }
}

.fieldSelect {
    width: 305px;
    height: 40px;
    display: flex;

    select {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #b3b5b6;
        background: #ffffff;
        border: 1px solid #c7c9d9;
        border-radius: 8px;

        &:focus {
            box-shadow: none !important;
            border-color: #ccc !important;
        }
        background-image: url("../../../assets/images/icons/arrow-down.png") !important;
        background-repeat: no-repeat !important;
        background-position: right 0.75rem center !important;
        background-size: 14px 7px !important;
    }
}

.fieldSelect2 {
    width: 305px;
    display: flex;
    flex-direction: row;
}

.inputData {
    display: flex;
    flex-direction: column;
    input {
        height: 40px !important;
        background: #ffffff;
        box-sizing: border-box;
        border-radius: 8px !important;
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #8d8f90 !important;

        ::placeholder {
            font-weight: normal;
            display: flex;
            align-items: center;
            color: #b6b3b3 !important;
        }

        &:focus {
            background-color: #ffffff !important;
            box-shadow: none !important;
        }
    }
    :hover {
        background-color: #ffffff !important;
        border-color: #b6b3b3 !important;
    }

    div {
        border-radius: 8px !important;
        background-color: #ffffff !important;
    }
    div:nth-child(1) {
        width: 145px !important;
    }
    label {
        margin-top: -4px;
        color: #b3b5b6 !important;
        font-style: normal !important;
        font-weight: 400 !important;
        font-size: 14px;
        line-height: 17px;
    }
}
