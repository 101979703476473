.listagem {
    background: #fbfbfb;
    border-radius: 8px;
    padding: 5px;
    padding-left: 25px !important;
    padding-right: 25px !important;
    padding: 15px;
}

.containerList {
    width: 1057px;
    margin-left: -60px;

    @media (min-width: 1366px) and (max-width: 1366px) {
        margin-left: -90px;
        width: 1057px;
    }

    @media (min-width: 1344px) and (max-width: 1344px) {
        width: 1000px;
    }

    @media (min-width: 1152px) and (max-width: 1280px) {
        width: 950px;
    }

    @media (min-width: 1024px) and (max-width: 1152px) {
        width: 850px;
        margin-left: -40px;
    }
}
