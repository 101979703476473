.novoServico {
    display: flex;
    justify-content: flex-end;
    margin-top: 35px;
    margin-bottom: 14px;
    font-family: Inter, sans-serif;
    font-size: 14px;
    line-height: 16.94px;
    font-weight: 600;

    a {
        color: #0064B4 !important;
    }

    :hover {
        color: #0064B4 !important;
    }
}

.formSelectServico {
    display: block;
    width: 8%;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    -moz-padding-start: calc(0.75rem - 3px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.6;
    color: #4f4f4f;
    background-color: #fff;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat !important;
    background-position: right 0.75rem center !important;
    background-size: 16px 12px !important;
    border: 1px solid #bdbdbd;
    border-radius: 0.25rem;
    transition: all 0.2s linear;
    appearance: none;
}

.containerList {
    width: 1057px;
    margin-left: -60px;

    @media (min-width: 1366px) and (max-width: 1366px) {
        margin-left: -90px;
        width: 1057px;
    }

    @media (min-width: 1344px) and (max-width: 1344px) {
        width: 1000px;
    }

    @media (min-width: 1152px) and (max-width: 1280px) {
        width: 950px;
    }

    @media (min-width: 1024px) and (max-width: 1152px) {
        width: 850px;
        margin-left: -40px;
    }
}