
#checkbox {
  position: relative;
  appearance: none;
  outline: none;
  width: 50px;
  height: 18px;
  border-radius: 20px;
  background: #D8D9DA;
  box-shadow: none;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  top: 3px;
}

#checkbox:checked {
  background: #D8D9DA;
}

#checkbox::before {
  content: '';
  position: absolute;
  border-radius: inherit;
  width: 26px;
  height: 26px;
  transition: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
}

#checkbox::after {
  content: "X";
  position: absolute;
  color: #fff;
  top: -4px;
  border-radius: inherit;
  width: 26px;
  height: 26px;
  background: #E60E19;
  transition: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  right: calc(100% - 25px);
}

#checkbox:checked::after {
    content: url('../../../assets/images/icons/check2.png');
    right: 0;
    background: #0064B4;
}

// ************************************************************************

.containerGeral {
  width: 880px;
  background-color: #F7F7F7;
  border-radius: 8px;
}

.subContainerGeral {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 32px 32px 45px;
}

.formularioItem {
  flex-direction: column;
  align-items: center;
}

.containerBtn {
  display: flex;
  flex-direction: row;
  align-items: center !important;
  justify-content: space-between;
  margin-top: 46px;
}

.buttonSave {
  min-width: 169px;
  height: 56px;
  background: linear-gradient(268.05deg, #0064b4 4.03%, #1486e1 101.81%);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  border-radius: 50px;
  border: 0;

  span {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
    padding: 20.5px 64px;
  }
}

.buttonSearchBlock {
  min-width: 169px;
  height: 56px;
  background: #b3b5b6;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  border-radius: 50px;
  border: 0;

  span {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #656a6e;
    padding: 20.5px 64px;
  }
}

.labelText {
  font-family: Inter, sans-serif;
  font-size: 14px;
  line-height: 16.94px;
  font-weight: 600;
  color: #343B40;
  display: flex;
  margin-bottom: 8px;
}

.containerInputFull {
  background-color: #ffffff !important;
  width: 816px !important;
  height: 56px !important;
  border-radius: 8px !important;
  outline: 0;

  &:focus {
    box-shadow: none !important;
    background-color: #ffffff !important;
    border: 1px solid #C7C9D9 !important;
    outline: none !important;
  }

  color: #343b40 !important;
  font-family: Inter,
  sans-serif !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 19.36px !important;
  border: 1px solid #C7C9D9 !important;
}

.textAreaComment {
  width: 816px !important;
  height: 133px !important;
  background: #ffffff;
  border: 1px solid #c7c9d9;
  border-radius: 8px;
  resize: none;
  outline: 0;

  &::placeholder {
    color: #B3B5B6 !important;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19.36px;
  }

  padding: 19px 17px !important;

  &:focus {
    box-shadow: none !important;
    background-color: #ffffff !important;
    border: 1px solid #C7C9D9 !important;
    outline: none !important;
  }
}

.fieldSelect {
  width: 816px;
  height: 56px;
  display: flex;

  select {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19.36px;
    color: #b3b5b6;
    background: #ffffff;
    border: 1px solid #c7c9d9;
    border-radius: 8px;

    &:focus {
      box-shadow: none !important;
      border-color: #ccc !important;
    }

    background-image: url("../../../assets/images/icons/arrow-down.png") !important;
    background-repeat: no-repeat !important;
    background-position: right 0.75rem center !important;
    background-size: 14px 7px !important;
  }
}

.maginTopLabel {
  margin-top: 24px;
}