// **************************************************************************

.formDropdown {
    background-image: url("../../assets/images/icons/arrow-down.png") !important;
    background-repeat: no-repeat !important;
    background-position: right 0.75rem center !important;
    background-size: 14px 7px !important;
}

.formDropdownDisabled {
    background-image: url("../../assets/images/icons/arrow-down-02.png") !important;
}

.selectInfo {
    width: 400px;
    height: 56px;
    @media (min-width: 1024px) and (max-width: 1280px) {
        width: 300px !important;
    }
}
