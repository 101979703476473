.containerTable {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    flex-direction: column;
    display: flex;
    span:nth-child(1) {
        text-decoration-line: underline;
        color: #0064b4;
        justify-content: flex-end;
        margin: 40px 0 40px 0;
        align-items: flex-end;
        align-self: flex-end;
        cursor: pointer;
    }
}

.containerDataTable {
    flex-direction: column;
    span:nth-child(1) {
        display: flex;
        justify-content: flex-start;
        background-color: #cfd9e1;
        height: 40px;
        padding: 10px 10px 10px 24px;
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #252a31;
        text-decoration: none;
        margin-bottom: 0;
    }
    .lisTable {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background-color: #ffffff;
        padding: 24px 24px;
        cursor: pointer;

        div {
            font-family: Inter, sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #252a31;
            img {
                width: 16px;
                height: 13px;
            }
        }
    }
}

.containerModalSelect {
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: #0064b4 #ecedee;
    div {
        height: 145px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #343b40;
        span {
            margin-bottom: 5px;
            padding: 15px;
            width: 100%;
            display: flex;
        }

        span:hover {
            display: flex;
            width: 100%;
            background-color: #2e7ebe;
            color: #ffffff;
            cursor: pointer;
        }
    }
}

.containerModalSelect::-webkit-scrollbar {
    height: 578px;
    width: 5px;
}

.containerModalSelect::-webkit-scrollbar-thumb {
    background: #0064b4;
}

.containerModalSelectTop {
    display: flex;
    justify-content: space-between;
    padding: 0 18px 20px 17px;

    border-bottom: 1px solid #c7c9d9;
    margin-bottom: 15px;

    img {
        width: 17px;
        height: 10px;
    }
}

.containerModalSelectNeighborhood {
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: #0064b4 #ecedee;
    height: 145px;
    div {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #343b40;

        div {
            flex-direction: row;
            align-items: center;
            padding: 15px;
            span {
                width: 100%;
                text-align: left;
            }
            div {
                justify-content: center;
                width: 24px;
                height: 24px;
                background: #0064b4;
                border-radius: 4px;
                img {
                    width: 17.6px;
                    height: 13.4px;
                }
                div {
                    display: flex;
                    padding: 12px;
                    background-color: #ffffff !important;
                }
            }
        }

        div:hover {
            background-color: #2e7ebe;
            color: #ffffff;
            cursor: pointer;
            & div {
                color: #ffffff;
            }
        }
    }
}

.containerModalSelectNeighborhood::-webkit-scrollbar {
    height: 578px;
    width: 5px;
}

.containerModalSelectNeighborhood::-webkit-scrollbar-thumb {
    background: #0064b4;
}

.boxModal {
    width: 100%;
    margin-bottom: 230px;
}

.containerSelect {
    margin-top: 40px;
}

.boxCommentText {
    label {
        display: flex;
        align-items: center;
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #343b40;
    }
}

.boxCommentTextError {
    label {
        display: flex;
        align-items: center;
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #e60e19;
    }
}

.containerPillBadges {
    display: flex;
    justify-content: flex-start;
    margin-top: 9px;
    flex-wrap: wrap;
    div {
        background: #ffffff;
        height: 32px;
        padding: 7.5px 0px 7.5px 18px;
        border: 1px solid #0064b4;
        border-radius: 50px;
        margin-right: 8px;
        margin-bottom: 9px;
        display: flex;
        cursor: pointer;

        span {
            font-family: Inter, sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            color: #2e7ebe;
            margin-right: 20px;
            img {
                width: 14px;
                height: 14px;
            }
        }
    }
}

.inputText {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    padding: 19px 31px 18px 17px;
    border: 1px solid #c7c9d9;
    border-radius: 8px;
    cursor: pointer;
    span {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #343b40;
    }
    img {
        width: 14px;
        height: 7px;
    }
}

.inputText:hover {
    cursor: pointer;
}

.removeUnit {
    display: flex;
    margin: 52px 0 0 10px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-decoration-line: underline;
    color: #0064b4;
    cursor: pointer;
}

.requiredField {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    font-family: Inter, sans-serif;
    display: flex;
    justify-content: flex-start;
    color: #e60e19;
}

.inputTextError {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    padding: 19px 31px 18px 17px;
    border: 1px solid red;
    border-radius: 8px;
    cursor: pointer;
    span {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #343b40;
    }
    img {
        width: 14px;
        height: 7px;
    }
}

.inputTextError:hover {
    cursor: pointer;
}

.containerModal {
    height: 400px;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: #0064b4 #ecedee !important;

    @media (min-width: 1366px) and (max-width: 1366px) {
        height: 300px !important;
    }

    @media (min-width: 1600px) and (max-width: 1600px) {
        height: 400px !important;
    }
}

.containerModal::-webkit-scrollbar {
    height: 500px;
    width: 5px;
}

.containerModal::-webkit-scrollbar-thumb {
    background: #0064b4;
}
