/* THIS IS FOR ALL SEARCH */
.box-pesquisa {
    width: 100%;
    max-width: 800px;
    height: 56px;
}

/* Input Search */
.containerSearch {
    width: 100%;
    max-width: 1056px;
}
.containerIconSearch {
    height: 56px;
    padding: 10px;
    position: relative;
    left: -39px;
    top: 1px;
}

.iconSearch {
    position: relative;
    color: rgb(97, 97, 97);
    cursor: pointer;
    margin-left: -15px;
}

.input-pesquisar {
    width: 800px;
    max-width: 800px;
    color: #8d8f90;
    background: #ffffff;
    border: 1px solid #e2e8f0;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 0px 1.2%;
}
.input-pesquisar::placeholder {
    position: absolute;
    left: 1.2%;
    right: 9.3%;
    top: 32.14%;
    bottom: 32.14%;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */
    display: flex;
    align-items: center;
    /* Cinza 2 */
    color: #b3b5b6 !important;
}
.input-pesquisar:focus {
    color: #212529;
    background-color: #ffffff;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13 110 253 / 25%);
}
.input-icon {
    position: relative;
    color: #8d8f90;
    background-color: #ffffff !important;
    border-color: 2px solid #8d8f90;
}
/* Filter */
.campos-filtro {
    display: flex;
}
.button-pesquisar {
    width: 169px;
    max-width: 169px;
    height: 56px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    align-items: center;
    text-align: center;
    background: linear-gradient(268.05deg, #0064b4 4.03%, #1486e1 101.81%);
    border-radius: 50px;
    color: #ffffff;
    border: 0;
    margin: 0px -10px;
}

.texto-filtro {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #0064b4;
    width: "auto";
    max-width: 320px;
    margin-left: 0.2em;
}

.select-pesquisa {
    max-width: 200px;
    outline: 0;
    height: 56px;
    background: #ffffff;
    border: 1px solid #c7c9d9;
    box-sizing: border-box;
    border-radius: 8px;
    margin-left: 23px;
    margin-top: 15px !important;
}
select.select-pesquisa {
    color: #b3b5b6;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
select.select-pesquisa:focus-visible {
    color: #252a31;
}
option {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
