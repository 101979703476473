// **************************************************************************

.buttonSearch {
    min-width: 169px;
    height: 56px;
    background: linear-gradient(268.05deg, #0064b4 4.03%, #1486e1 101.81%);
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    border-radius: 50px;
    border: 0;

    span {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
        padding: 20.5px 64px;
    }
}

// **************************************************************************

/* CONTATO E ACESSO */

.containerTextField {
    width: 100%;
    display: flex;
    div {
        display: flex;
        flex-direction: column;
        span {
            font-family: Inter, sans-serif !important;
            font-size: 14px;
            font-weight: 600;
            line-height: 16.94px;
            color: #343b40;
        }
        fieldset {
            background-color: #ffffff;
        }
    }
}

// **************************************************************************
/* DADOS PESSOAIS */
.containerDatePicker {
    width: 400px;
    span {
        display: flex;
        font-family: Inter, sans-serif !important;
        font-size: 14px;
        font-weight: 600;
        line-height: 16.94px;
        color: #343b40;
    }
    fieldset {
        background-color: #ffffff;
    }
}

.containerGeralDadosCli {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    flex-direction: column;
}

.containerGeralDadosSubCli {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    gap: 16px;
    padding-top: 32px;
}

.subContainerGeralDados01 {
    display: flex;
    flex-direction: column;
}

.dividerSpace {
    margin-top: 24px;
}

.colorFieldBlock {
    div {
        fieldset {
            background-color: #ecedee !important;
        }
    }
    select {
        background-color: #ecedee !important;
    }
}

.containerInfoButtonEditDadosCli {
    padding-right: 32px;
    div {
        margin: 32px 0;
    }
}

.containerButtonEditDadosCli {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    gap: 43px;
    div {
        font-weight: 600;
        font-size: 14px;
        font-family: Inter, sans-serif;
        color: #0064b4;
        line-height: 16.94px;
        text-decoration: underline;
        cursor: pointer;
    }
}

// *******************************************************************

.containerGeralSituacao {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    margin-top: 32px;
    margin-bottom: 32px;
}

.subContainerGeral {
    display: flex;
    flex-direction: column;
    padding: 32px 32px 0;
}

.fieldSelect {
    width: 400px;
    height: 56px;
    display: flex;

    select {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19.36px;
        color: #b3b5b6;
        background: #ffffff;
        border: 1px solid #c7c9d9;
        border-radius: 8px;

        &:focus {
            box-shadow: none !important;
            border-color: #ccc !important;
        }

        background-image: url("../../../assets/images/icons/arrow-down.png") !important;
        background-repeat: no-repeat !important;
        background-position: right 0.75rem center !important;
        background-size: 14px 7px !important;
    }
}

.formItem {
    display: flex;
    flex-direction: column;
}

.textAreaComment {
    width: 816px !important;
    height: 135px !important;
    background: #ffffff;
    border: 1px solid #c7c9d9;
    border-radius: 8px !important;
    resize: none;
    outline: 0;

    &::placeholder {
        color: #B3B5B6 !important;
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19.36px;
    }

    padding: 19px 17px !important;

    &:focus {
        box-shadow: none !important;
        background-color: #ffffff !important;
        border: 1px solid #C7C9D9 !important;
        outline: none !important;
    }
}

.textAreaCommentDisabled {
    background-color: #ECEDEE;
}

.labelText {
    font-family: Inter, sans-serif;
    font-size: 14px;
    line-height: 16.94px;
    font-weight: 600;
    color: #343B40;
    display: flex;
    margin-bottom: 8px;
}

.containerInfoButtonStatus {
    display: flex;
    width: 100%;
    div {
        margin: 32px 0;

    }
}