.containerModal {
    height: 450px;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: #0064b4 #ecedee !important;

    @media (min-width: 1280px) and (max-width: 1370px) {
        height: 350px;
    }

    @media (min-width: 1024px) and (max-width: 1280px) {
        height: 320px;
    }
}

.containerModal::-webkit-scrollbar {
    height: 500px;
    width: 5px;
}

.containerModal::-webkit-scrollbar-thumb {
    background: #0064b4;
}

.formulario {
    position: relative;
    max-width: 879px;
    // max-height: 931px;
    background: #f7f7f7;
    border-radius: 8px;
}

.formularioBottom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 879px;
    // max-height: 931px;
    background: #f7f7f7;
    border-radius: 8px;
    padding: 30px;
}

.formularioItem {
    position: relative;
    width: 100% !important;
    height: 100% !important;
    margin: 10px 2px;
    padding: 32px 32px;
    align-content: space-between;

    div > div > label {
        font-family: Inter, sans-serif !important;
        font-weight: 600 !important;
        font-size: 14px !important;
        color: #343b40 !important;
    }
}

.formulario {
    input,
    select {
        width: 100% !important;
        max-width: 816px;
        height: 56px;
        left: 0px;
        top: 22px;
        background: #ecedee !important;
        /* Linhas */
        border: 1px solid #c7c9d9;
        box-sizing: border-box;
        border-radius: 8px;

        font-family: Inter, sans-serif !important;
        font-weight: 400 !important;
        font-size: 16px !important;
        color: #b3b5b6 !important;
    }
}
.formulario select {
    &:focus {
        box-shadow: none !important;
        border-color: #ccc !important;
    }
    background-image: url("../../../assets/images/icons/arrow-down-02.png") !important;
    background-repeat: no-repeat !important;
    background-position: right 0.75rem center !important;
    background-size: 14px 7px !important;
}
.formulario select:focus {
    color: #343b40 !important;
}
.formulario textarea {
    width: 100% !important;
    max-width: 879px;
    // max-height: 931px;
    left: 0px;
    top: 22px;

    background: #ffffff;
    border: 1px solid #c7c9d9;
    box-sizing: border-box;
    border-radius: 8px;
}

.marginBottom {
    margin-bottom: 24px;
}

.spaceHR {
    margin: 32px 0 32px;
}

.complimentsBox {
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 40px;
    display: flex;
    justify-content: center;
    gap: 16px;
    width: 400px;
}

.complimentButton {
    width: 48%;
}

.complimentButtonPressed {
    width: 48%;
}

.complimentButtonPressed > button {
    width: 100%;
    background-color: #0064b4;
    border-radius: 50px;
    padding: 10px;
    border: 1px solid #0064b4;
    height: 48px;
}

.complimentButtonPressed {
    button:hover {
        width: 100%;
        border-radius: 50px;
        background-color: #0064b4;
    }
}

.complimentButton > button {
    width: 100%;
    background-color: #fff;
    border-radius: 50px;
    padding: 10px;
    border: 1px solid #0064b4;
    height: 48px;
}

.complimentText {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #fff;
    text-transform: capitalize;
}

.complimentTextBlue {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #0064b4;
    text-transform: capitalize;
}

.ratingStars {
    font-size: 40px !important;
    color: #f9b000 !important;
    cursor: pointer;
    text-align: left;
}

.boxCommentProfessionalText {
    text-align: left;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: #343b40;
}

.cardComment {
    margin-top: 60px;
}

.boxCommentProfessional {
    width: 100%;
}

.textAreaCommentProfessional {
    background: #ffffff;
    border: 1px solid #c7c9d9;
    border-radius: 8px;
    resize: none;
    margin-bottom: 30px;
}

.profileImage {
    width: 132px;
    height: 132px;
    border-radius: 66px;
    justify-content: center;
    align-items: center;
    object-fit: cover;
}

.notPhoto {
    width: 132px;
    height: 132px;
    border-radius: 66px;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: 1px solid #c7c9d9;
}

.cardProfImage {
    margin-bottom: 6px;
}

.boxProfessionalTable {
    display: flex;
    justify-content: center;
    // width: 817px;
    justify-self: center !important;
    justify-items: center !important;
    align-items: center !important;
    align-self: center !important;
    align-content: center;
    padding: 0 20px;
}

.recipeContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
    text-align: left !important;
}

.pdfPage {
    position: relative;
    margin: 0 auto;
    padding: 20px;
    color: #333;
    background-color: #fff;
    box-sizing: border-box;
    // background-color: red;
}

@font-face {
    font-family: "DejaVu Sans";
    src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans.ttf")
        format("truetype");
}

.recipeInnerContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
    text-align: left !important;
    width: 446px;
    left: 56px;
    top: 449px;
    background: #ffffff;
    border: 1px solid #dbdde0;
    border-radius: 18px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 10px;
    margin-top: 20px;
}

.titleProfissionaosSolicitados {
    height: 17px;
    left: 32px;
    top: 40px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    color: #343b40;
    justify-content: center !important;
}

.subtitleName {
    font-family: Inter, sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 29.05px;
    color: #343b40;
    word-break: break-all !important;
}

.subtitleExperience {
    font-family: Inter, sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 19.36px;
    color: #656a6e;
    margin: 40px 0 26px;
}

.recipeContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
    text-align: left !important;
}

.titleRecipe {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #0064b4;
}

.subTitleRecipe {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #3a393b;
    padding-top: 10px;
}

.subInfoRecipe {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #3a393b;
    word-break: break-all;
}

.colorBTN_WHITE {
    width: 181px;
    height: 60px;
    margin-left: 15px;
    border: 1px solid #0064b4;
    border-radius: 50px;
    background: #ffffff;
    color: #0064b4;
    font-family: Inter, sans-serif !important;
    font-weight: 700;
    font-size: 16px;
    line-height: 19.36px;
}

.colorBTN_BLUE {
    width: 181px;
    height: 60px;
    margin-left: 15px;
    border: none;
    border-radius: 50px;
    background: #0064b4;
    color: #ffffff;
    font-family: Inter, sans-serif !important;
    font-weight: 700;
    font-size: 16px;
    line-height: 19.36px;
}

.subInfoRecipe {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #3a393b;
}

.recipeValueText {
    width: 400px;
    height: 56px;
    left: 0px;
    top: 22px;
    background: #ffffff;
    border: 1px solid #c7c9d9;
    border-radius: 8px;
}
