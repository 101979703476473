.containeBtnUpload {
    display: flex;
    justify-content: center;
}

.btnUpload {
    border: 1px solid #0064b4 !important;
    border-radius: 50px !important;
    padding: 10px 20px !important;
}

.textBtnUpload {
    font-family: Inter, sans-serif;
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 19.36px !important;
    color: #0064b4 !important;
}

.containerGeralUpload {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.descUpload {
    padding: 0 70px;
    text-align: center;
    font-family: Inter, sans-serif;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 14.52px !important;
    color: #343b40 !important;
    margin: 25px 0 78px;
}

.descNotSupport {
    text-align: center;
    font-family: Inter, sans-serif;
    font-weight: 600 !important;
    font-size: 24px !important;
    line-height: 29.05px !important;
    color: #e60e19 !important;
}

.containerNotSupport {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

.btnNotSupport {
    border-radius: 50px !important;
    background-color: #e60e19;
    width: 181px;
    height: 60px;
    margin-top: 68px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.textNotSupport {
    font-family: Inter, sans-serif;
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 19.36px !important;
    color: #ffffff !important;
}
