.newRegister {
    display: flex;
    justify-content: flex-end;
    margin-top: 41px;
    margin-bottom: 14px;
    font-family: Inter, sans-serif;
    font-size: 14px;
    line-height: 16.94px;
    font-weight: 600;
    padding-right: 40px;
    text-decoration: underline;
    text-decoration-color: #0064b4 !important;

    a {
        color: #0064b4 !important;
    }

    :hover {
        color: #0064b4 !important;
    }
}

.containerList {
    width: 1057px;
    margin-left: -60px;

    @media (min-width: 1366px) and (max-width: 1366px) {
        margin-left: -90px;
        width: 1057px;
    }

    @media (min-width: 1344px) and (max-width: 1344px) {
        width: 1000px;
    }

    @media (min-width: 1152px) and (max-width: 1280px) {
        width: 950px;
    }

    @media (min-width: 1024px) and (max-width: 1152px) {
        width: 850px;
        margin-left: -40px;
    }
}

.listagem {
    background: #f7f7f7;
    border-radius: 8px;
    padding: 5px;
    padding-left: 25px !important;
    padding-right: 25px !important;
    padding: 15px;
    margin-bottom: 30px;
}
