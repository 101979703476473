// **************************************************************************

.tableCalendarHeader {
    table {
        background-color: #ffffff;
    }
    td {
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        color: #0064b4;
    }
    thead,
    tbody,
    tfoot,
    tr,
    td,
    th {
        border-bottom: none !important;
    }
}

.tableCalendarBody {
    table {
        border-bottom: solid 1px #c7c9d9;
    }
    tr {
        border: none;
    }
    td {
        border-top: solid 1px #c7c9d9;
        border-right: solid 1px #c7c9d9;
        border-left: solid 1px #c7c9d9;

        div {
            display: flex;
            justify-content: flex-end !important;
            width: 100% !important;
            height: 60px;
            padding: 10px 8px 0 0;
        }
    }
}

.colorTextNumber01 {
    font-family: Inter, sans-serif;
    font-weight: 500;
    font-size: 14px;
    color: #0064b4;
}

.colorTextNumber02 {
    font-family: Inter, sans-serif;
    font-weight: 500;
    font-size: 14px;
    color: #b3b5b6;
}

.containerMonth {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 32px;
}

.titleMonth {
    font-family: Inter, sans-serif;
    font-weight: 600;
    font-size: 32px;
    color: #0064b4;
}

.containerNextMonth {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.currentMonth {
    background-color: #ffffff;
    padding: 3px 10px;
    border-radius: 4px;
    border: 1px solid #d8d9da;
    font-family: Inter, sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #0064b4;
    cursor: pointer;
    text-transform: none;
}
