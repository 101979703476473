.table {
    margin-top: 24px;
    // width: 100%;
    thead {
        vertical-align: bottom;
        background: #cfd9e1;
        border: 1px solid #cfd9e1 !important;
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        color: #252a31;

        tr {
            th {
                font-family: Inter, sans-serif !important;
                font-style: normal;
                font-weight: 600 !important;
                font-size: 14px !important;
                line-height: 16.94px !important;
                color: #252a31;

                div:first-child {
                    width: 22.18px;
                    height: 22.18px;
                    background: #0064b4;
                    border-radius: 3.69677px;
                    display: flex;
                    span:first-child {
                        width: 13px;
                        height: 1.84839px;
                        margin-top: 10px;
                        background-color: #ffffff;
                        display: flex;
                        margin-left: 3.6px;
                    }
                }
            }
        }
    }
}

.inputSearch {
    width: 658px !important;
    height: 56px !important;
    color: #8d8f90;
    background: #ffffff;
    border: 1px solid #e2e8f0;
    box-sizing: border-box;
    border-radius: 8px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #8d8f90 !important;
    border-color: #e2e8f0 !important;

    ::placeholder {
        font-weight: normal;
        display: flex;
        align-items: center;
        color: #b6b3b3 !important;
    }

    &:focus {
        color: #212529 !important;
        background-color: #ffffff !important;
        box-shadow: none !important;
        outline: 0;
        color: #8d8f90 !important;
    }
}

.inputData {
    display: flex;
    width: 658px !important;
    background: #ffffff !important;
    flex-direction: column;
    input {
        width: 658px !important;
        height: 56px !important;
        background: #ffffff;
        box-sizing: border-box;
        border-radius: 8px !important;
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #8d8f90 !important;

        ::placeholder {
            font-weight: normal;
            display: flex;
            align-items: center;
            color: #b6b3b3 !important;
        }

        &:focus {
            background-color: #ffffff !important;
        }
    }

    div {
        border-radius: 8px !important;
    }
}

.containerInput {
    display: flex;
    justify-content: space-between;
    width: 853px;
}

.buttonSearch {
    width: 169px;
    max-width: 169px;
    height: 56px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    align-items: center;
    text-align: center;
    background: linear-gradient(268.05deg, #0064b4 4.03%, #1486e1 101.81%);
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    border-radius: 50px;
    color: #ffffff;
    border: 0;
}

.buttonSearchBlock {
    width: 169px;
    max-width: 169px;
    height: 56px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    align-items: center;
    text-align: center;
    background: #b3b5b6;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    border-radius: 50px;
    color: #656a6e;
    border: 0;
}

.radioCircle {
    width: 23px !important;
    height: 23px !important;
    border-radius: 12px;
    border: 2px solid #0064b4;
    display: flex;
    justify-content: center;
    align-items: center;
}

.nomeProf {
    word-break: break-all;
}

.radCirNoSelected {
    width: 23px !important;
    height: 23px !important;
    border-radius: 12px;
    border: 2px solid #b3b5b6;
    background-color: #d8d9da;
}

.tbodyTable {
    background-color: #ecedee;
    font-family: Inter, sans-serif;
    td {
        border: 0.5px solid #c7c9d9;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
    }

    tr {
        cursor: pointer;
        td:first-child {
            width: 10px;
        }
    }
}

.circleBall {
    background-color: #0064b4;
    width: 11px;
    height: 11px;
    border-radius: 6px;
    display: flex;
}

.textNoSelected {
    font-family: Inter, sans-serif;
    td {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16.94px;
        color: #8d8f90;
        word-break: break-all !important;
    }
}

.textSelected {
    font-family: Inter, sans-serif;
    td {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16.94px;
        color: #0064b4;
        word-break: break-all !important;
    }
}

// *********************************pagination*********************************

.reactPaginate {
    display: flex;
    justify-content: center;
}

.reactPaginateModal {
    display: flex;
    justify-content: center;
}

.pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
}

.pageItem {
    font-size: 0.9rem;
    color: #0064b4 !important;
    background-color: transparent;
    border: 0;
    outline: 0;
    transition: all 0.3s linear;
    font-weight: 400;
}

.pageLink {
    padding: 0.375rem 0.75rem;
    position: relative;
    display: block;
    color: #0064b4 !important;
}

.disabled {
    display: none !important;
}
// **************************************************************************

.containerSelect {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.containerObservation {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    margin-top: 24px;
    font-family: Inter, sans-serif;

    div:nth-child(1) {
        label {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            display: flex;
            color: #343b40;
        }
        textarea {
            background: #ffffff;
            border: 1px solid #c7c9d9;
            border-radius: 8px;
            resize: none;
            height: 133px;
            &:focus {
                outline: 0;
                border-color: #c7c9d9;
                box-shadow: none;
            }
            color: #8d8f90 !important;
        }

        ::placeholder {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: #b3b5b6;
        }
    }

    div:nth-child(3) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 14px;
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #343b40;
        div:nth-child(2) {
            margin-top: 0 !important;
            color: #8d8f90 !important;
            span:first-child {
                font-weight: 700;
            }
        }
    }
}

.fieldSelect {
    display: flex;
    flex-direction: column;
    font-family: Inter, sans-serif;

    label {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        color: #343b40;
    }

    select {
        font-style: normal;
        font-weight: 400;
        width: 400px;
        height: 56px;
        font-size: 14px;
        line-height: 17px;
        color: #b3b5b6;
        background: #ffffff;
        border: 1px solid #c7c9d9;
        border-radius: 8px;
        &:focus {
            box-shadow: none !important;
            border-color: #ccc !important;
        }
        background-image: url("../../../assets/images/icons/arrow-down-02.png") !important;
        background-repeat: no-repeat !important;
        background-position: right 0.75rem center !important;
        background-size: 14px 7px !important;
    }
}

.resultSelect {
    display: flex;
    flex-direction: column;
    font-family: Inter, sans-serif;

    label {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        color: #343b40;
    }

    select {
        font-style: normal;
        font-weight: 400;
        width: 213px;
        height: 40px;
        font-size: 14px;
        line-height: 17px;
        color: #b3b5b6;
        background: #ffffff;
        border: 1px solid #c7c9d9;
        border-radius: 8px;
        &:focus {
            box-shadow: none !important;
            border-color: #ccc !important;
        }
        background-image: url("../../../assets/images/icons/arrow-down.png") !important;
        background-repeat: no-repeat !important;
        background-position: right 0.75rem center !important;
        background-size: 14px 7px !important;
    }
}

.containerButton {
    display: flex;
    justify-content: flex-end;
    margin-top: 61px;
}

.containerFilter {
    display: flex;
    width: 869px;
    margin-top: 28px;
}

.subContainerFilter {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding-top: 5px;

    div:nth-child(1) {
        width: 200px;
        display: flex;
    }

    .containerResult {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0 40px 0 10px;
        div:nth-child(3) {
            margin-top: 13px;
        }
    }
}

.fieldFilter {
    display: flex;
    flex-direction: column;
    font-family: Inter, sans-serif;

    label {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        color: #343b40;
    }

    select {
        font-style: normal;
        font-weight: 400;
        width: 213px;
        height: 40px;
        font-size: 14px;
        line-height: 17px;
        color: #b3b5b6;
        background: #ffffff;
        border: 1px solid #c7c9d9;
        border-radius: 8px;
        &:focus {
            box-shadow: none !important;
            border-color: #ccc !important;
        }
        background-image: url("../../../assets/images/icons/arrow-down-02.png") !important;
        background-repeat: no-repeat !important;
        background-position: right 0.75rem center !important;
        background-size: 14px 7px !important;
    }

    input {
        width: 213px;
        height: 40px;
        background: #ffffff;
        border: 1px solid #c7c9d9;
        border-radius: 8px;
    }
}

.filterDate {
    width: 213px !important;
    input {
        width: 213px !important;
        height: 40px !important;
        background: #ffffff;
        box-sizing: border-box;
        border-radius: 8px !important;
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #8d8f90 !important;

        &::placeholder {
            font-weight: normal;
            display: flex;
            align-items: center;
            color: #b6b3b3 !important;
        }

        &:focus {
            align-self: center;
            background-color: #ffffff !important;
        }
    }

    div {
        border-radius: 8px !important;
        background-color: #ffffff !important;
    }
    div:nth-child(1) {
        width: 213px !important;
    }
}

.textFilter {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #0064b4;
    width: 133px;
    margin-top: 10px;
}

.requiredField {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    font-family: Inter, sans-serif;
    display: flex;
    justify-content: flex-start;
    color: #e60e19;
}

.formGroupError {
    textarea,
    input[type="text"],
    select {
        box-sizing: border-box;
        border: 2px solid red !important;
        border-radius: 4px;
    }
    div > div > div > div {
        border: 2px solid red !important;
    }
}

.labelDateOn {
    label {
        margin-top: -7px;
        color: #e60e19 !important;
    }
}

.labelDateOff {
    label {
        margin-top: -7px;
        color: #b3b5b6 !important;
    }
}

.labelDateOk {
    label {
        margin-top: -7px;
        color: #403434 !important;
    }
}

.labelDateEspOn {
    label {
        color: #e60e19 !important;
    }
}

.labelDateEspOff {
    label {
        color: #b3b5b6 !important;
    }
}

.labelDateEspOk {
    label {
        color: #403434 !important;
    }
}

.textLabel {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px !important;
    line-height: 17px !important;
    display: flex;
    color: #403434 !important;
}

.textLabelRed {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px !important;
    line-height: 17px !important;
    display: flex;
    color: #e60e19 !important;
}
