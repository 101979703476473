.containerFilds {
    margin-top: 16px;
    display: block;
    width: 760px;
    cursor: auto;
}

.subContainerFilds {
    display: flex;
    align-items: center;
    span {
        font-family: Inter, sans-serif;
        color: #0064b4;
        display: flex;
        margin-right: 16px;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
    }
}

.fieldSelect {
    width: 320px;
    height: 40px;
    display: flex;

    select {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #b3b5b6;
        background: #ffffff;
        border: 1px solid #c7c9d9;
        border-radius: 8px;

        &:focus {
            box-shadow: none !important;
            border-color: #ccc !important;
        }
        background-image: url("../../../assets/images/icons/arrow-down.png") !important;
        background-repeat: no-repeat !important;
        background-position: right 0.75rem center !important;
        background-size: 14px 7px !important;
    }
}
