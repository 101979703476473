#sair {
    width: 526px;
    height: 207px;
    padding: 32px 32px;
    background: #f7f7f7;
    border-radius: 8px;
}
#sair h3 {
    width: 473px;
    height: 19px;
    margin-bottom: 64px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #343b40;
}

#sair button {
    width: 181px;
    height: 60px;
    margin: 10px 0px;
    border-radius: 50px !important;
}

.confirm {
    color: #fff;
    background: #e60e19 !important;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
}
.cancel {
    color: #0064b4 !important;
    background: #f7f7f7 !important;
}
