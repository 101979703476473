.colorTrOne {
  background-color: #EEEEEE;
}

.colorTrTwo {
  background-color: #FBFBFB;
}


.table > thead {
  vertical-align: bottom;
  background: #cfd9e1;
  border: 1px solid #cfd9e1 !important;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #252a31;
}

.tbody {
  background-color: #f7f7f7;

  tr {
    border: 1px solid #c7c9d9;
  }
}

.estadoCat {
  justify-content: center;
  align-items: center;
  align-self: center;
  border: none !important;

  span {
    font-family: Inter, sans-serif;
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
    color: #ffffff;
    background-color: #8d8f90 !important;
    border-radius: 10px !important;
    display: flex;
    height: 18px;
    width: 95px;
    justify-content: center;
    align-items: center;
  }
}

.Ativo {
  justify-content: center;
  align-items: center;
  align-self: center;
  border: none !important;

  span {
    background-color: #95c11f !important;
    width: 62px;
  }
}

.Inativo {
  justify-content: center;
  align-items: center;
  align-self: center;
  border: none !important;

  span {
    background-color: #e60e19 !important;
    width: 72px;
  }
}

.radiusTable {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.infoText {
  font-family: Inter, sans-serif;
  font-size: 14px;
  line-height: 16.94px;
  font-weight: 400;
  color: #252a31;
}

.infoTextDesc {
  font-family: Inter, sans-serif;
  font-size: 14px;
  line-height: 16.94px;
  font-weight: 400;
  color: #252a31;
  text-align: left !important;
}

// *********************************pagination*********************************

.reactPaginate {
  display: flex;
  justify-content: center;
}

.reactPaginateModal {
  display: flex;
  justify-content: center;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
}

.pageItem {
  font-size: 0.9rem;
  color: #0064B4 !important;
  background-color: transparent;
  border: 0;
  outline: 0;
  transition: all 0.3s linear;
  font-weight: 400;
}

.pageLink {
  padding: .375rem .75rem;
  position: relative;
  display: block;
  color: #0064B4 !important;
}

.disabled {
  display: none !important;
}

// **************************************************************************