.containerGeral {
    display: flex;
    flex-direction: row;
}

.containerImage {
    @media (min-width: 2048px) and (max-width: 3840px) {
        div {
            background-image: url("../../../public/images/login/login3.png");
            width: 776px !important;
            height: 1000px !important;
            background-position: center;
            /* Center the image */
            background-repeat: no-repeat;
            /* Do not repeat the image */
            background-size: cover;
        }
    }

    @media (min-width: 1920px) and (max-width: 1920px) {
        div {
            background-image: url("../../../public/images/login/login3.png");
            width: 776px !important;
            height: 1000px !important;
            background-position: center;
            /* Center the image */
            background-repeat: no-repeat;
            /* Do not repeat the image */
            background-size: cover;
        }
    }

    @media (min-width: 1600px) and (max-width: 1680px) {
        div {
            background-image: url("../../../public/images/login/login5.png");
            width: 756px !important;
            height: 820px !important;
            /* You must set a specified height */
            background-position: center;
            /* Center the image */
            background-repeat: no-repeat;
            /* Do not repeat the image */
            background-size: cover;
            /* Resize the background image to cover the entire container */
        }
    }

    @media (min-width: 1366px) and (max-width: 1440px) {
        div {
            background-image: url("../../../public/images/login/login4.png");
            width: 588px !important;
            height: 688px !important;
            /* You must set a specified height */
            background-position: center;
            /* Center the image */
            background-repeat: no-repeat;
            /* Do not repeat the image */
            background-size: cover;
            /* Resize the background image to cover the entire container */
        }
    }

    @media (min-width: 1280px) and (max-width: 1360px) {
        div {
            background-image: url("../../../public/images/login/login4.png");
            width: 588px !important;
            height: 688px !important;
            /* You must set a specified height */
            background-position: center;
            /* Center the image */
            background-repeat: no-repeat;
            /* Do not repeat the image */
            background-size: cover;
            /* Resize the background image to cover the entire container */
        }
    }

    @media (min-width: 1024px) and (max-width: 1152px) {
        div {
            background-image: url("../../../public/images/login/login4.png");
            width: 588px !important;
            height: 688px !important;
            /* You must set a specified height */
            background-position: center;
            /* Center the image */
            background-repeat: no-repeat;
            /* Do not repeat the image */
            background-size: cover;
            /* Resize the background image to cover the entire container */
        }
    }
    
}

.containerInputControl {
    @media (min-width: 2048px) and (max-width: 3840px) {
        width: 714px !important;
    }

    @media (min-width: 1920px) and (max-width: 1920px) {
        width: 714px !important;
    }

    @media (min-width: 1600px) and (max-width: 1680px) {
        width:  540px !important;
    }

    @media (min-width: 1366px) and (max-width: 1440px) {
        width:  480px !important;
    }

    @media (min-width: 1280px) and (max-width: 1360px) {
        width: 540px !important;
    }

    @media (min-width: 1024px) and (max-width: 1152px) {
        width: 280px !important;       
    }
    
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #C7C9D9;
    padding: 1px;

}

.containerInputFull {
    background-color: #ffffff !important;
    @media (min-width: 2048px) and (max-width: 3840px) {
        width: 714px !important;
        height: 56px !important;
    }

    @media (min-width: 1920px) and (max-width: 1920px) {
        width: 714px !important;
        height: 56px !important;
    }

    @media (min-width: 1600px) and (max-width: 1680px) {
        width: 540px !important;
        height: 56px !important;
    }

    @media (min-width: 1366px) and (max-width: 1440px) {
        width: 480px !important;
        height: 55px !important;
    }

    @media (min-width: 1280px) and (max-width: 1360px) {
        width: 540px !important;
        height: 56px !important;
    }

    @media (min-width: 1024px) and (max-width: 1152px) {
        width: 280px !important;
        height: 55px !important;
    }

    border-radius: 8px !important;
    outline: 0;

    &:focus {
        box-shadow: none !important;
        background-color: #ffffff !important;
        outline: none !important;
    }

    color: #343b40 !important;
    font-family: Inter, sans-serif !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 19.36px !important;
    border: none !important;
}

.containerForm {
    display: flex;
    flex-direction: column;
}

.containerLogin {
    @media (min-width: 2048px) and (max-width: 3840px) {
        width: 714px !important;
        padding-top: 190px;
        margin-left: 200px;
        padding-right: 228px;
    }

    @media (min-width: 1920px) and (max-width: 1920px) {
        width: 714px !important;
        padding-top: 190px;
        margin-left: 200px;
        padding-right: 228px;
    }

    @media (min-width: 1600px) and (max-width: 1680px) {
        width: 540px !important;
        padding-top: 197px;
        margin-left: 124px;
        padding-right: 178px;
    }

    @media (min-width: 1366px) and (max-width: 1440px) {
        width: 480px !important;
        padding-top: 96px;
        margin-left: 120px;
        padding-right: 178px;
    }

    @media (min-width: 1280px) and (max-width: 1360px) {
        width: 540px !important;
        padding-top: 96px;
        margin-left: 80px;
        padding-right: 178px;
    }

    @media (min-width: 1024px) and (max-width: 1152px) {
        width: 300px !important;
        padding-top: 96px;
        margin-left: 80px;    
    }

    display: flex;
    flex-direction: column;    
}

.containerFormInput {
    width: 100%;
    display: flex;
    @media (min-width: 1920px) and (max-width: 1920px) {
        width:100% !important;
    }
}

.imageEye {
    padding: 16px;
    cursor: pointer;
    @media (min-width: 1024px) and (max-width: 1152px) {
        padding: 0;
    }
}

.containerForgot {
    @media (min-width: 2048px) and (max-width: 3840px) {
        width: 714px !important;
    }

    @media (min-width: 1920px) and (max-width: 1920px) {
        width: 714px !important;
    }

    @media (min-width: 1600px) and (max-width: 1680px) {
        width: 540px !important;
    }

    @media (min-width: 1366px) and (max-width: 1440px) {
        width: 480px !important;
    }

    @media (min-width: 1280px) and (max-width: 1360px) {
        width: 540px !important;
    }

    @media (min-width: 1024px) and (max-width: 1152px) {
        width: 280px !important;
    }


    display: flex;
    justify-content: flex-end;
    margin-top: 42px;
    span {
        color: #343b40 !important;
        font-family: Inter, sans-serif !important;
        font-weight: 600 !important;
        font-size: 14px !important;
        line-height: 16.94px !important;
        cursor: pointer;
        text-decoration: underline;        
    }
}

.buttonEnterCreate {
    @media (min-width: 2048px) and (max-width: 3840px) {
        width: 320px;
    }

    @media (min-width: 1920px) and (max-width: 1920px) {
        width: 320px;
    }

    @media (min-width: 1600px) and (max-width: 1680px) {
        width: 256px;
    }

    @media (min-width: 1366px) and (max-width: 1440px) {
        width: 212px;
    }

    @media (min-width: 1280px) and (max-width: 1360px) {
        width: 256px;
    }

    @media (min-width: 1024px) and (max-width: 1152px) {
        width: 130px;
    }

    height: 60px;
    background: #fff;
    border-radius: 50px;
    border: 1px solid #0064B4;

    span {
        font-family: Inter, sans-serif;
        font-weight: 700;
        font-size: 16px;
        line-height: 19.36px;
        color: #0064B4;
    }
}

.buttonEnter {
    @media (min-width: 2048px) and (max-width: 3840px) {
        width: 320px;
    }

    @media (min-width: 1920px) and (max-width: 1920px) {
        width: 320px;
    }

    @media (min-width: 1600px) and (max-width: 1680px) {
        width: 256px;
    }

    @media (min-width: 1366px) and (max-width: 1440px) {
        width: 212px;
    }

    @media (min-width: 1280px) and (max-width: 1360px) {
        width: 256px;
    }

    @media (min-width: 1024px) and (max-width: 1152px) {
        width: 130px;
    }

    height: 60px;
    background: linear-gradient(268.05deg, #0064b4 4.03%, #1486e1 101.81%);
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    border-radius: 50px;
    border: 0;

    span {
        font-family: Inter, sans-serif;
        font-weight: 700;
        font-size: 16px;
        line-height: 19.36px;
        color: #ffffff;
    }
}

.buttonEnterDisab {
    @media (min-width: 2048px) and (max-width: 3840px) {
        width: 320px;
    }

    @media (min-width: 1920px) and (max-width: 1920px) {
        width: 320px;
    }

    @media (min-width: 1600px) and (max-width: 1680px) {
        width: 256px;
    }

    @media (min-width: 1366px) and (max-width: 1440px) {
        width: 212px;
    }

    @media (min-width: 1280px) and (max-width: 1360px) {
        width: 256px;
    }

    @media (min-width: 1024px) and (max-width: 1152px) {
        width: 130px;
    }

    height: 60px;
    background: #B3B5B6 !important;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    border-radius: 50px;
    border: 0;

    span {
        font-family: Inter, sans-serif;
        font-weight: 700;
        font-size: 16px;
        line-height: 19.36px;
        color: #656A6E;
    }
}

.containerBtn {
    @media (min-width: 2048px) and (max-width: 3840px) {
        width: 714px !important;
    }

    @media (min-width: 1920px) and (max-width: 1920px) {
        width: 714px !important;
    }

    @media (min-width: 1600px) and (max-width: 1680px) {
        width: 540px !important;
    }

    @media (min-width: 1366px) and (max-width: 1440px) {
        width: 480px !important;
    }

    @media (min-width: 1280px) and (max-width: 1360px) {
        width: 540px !important;
    }

    @media (min-width: 1024px) and (max-width: 1152px) {
        width: 280px !important;
    }

    display: flex;
    justify-content: space-between;
    margin-top: 56px;    
}

.labelModal {
    font-family: Inter, sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 16.94px;
    color: #343B40;
    margin-bottom: 8px;
    display: flex;
}

.labelMarginTopModal {
    margin-top: 32px;
}

.textLogin {
    font-family: Inter, sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 29.05px;
    color: #0064B4;
    margin-bottom: 56px;
    display: flex;
}