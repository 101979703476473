/* formulário de login */

.login {
    max-width: 402px;
    max-height: 413px;
    margin: 160px 214px;
    margin-right: 100px;
}
.login-title {
    /* H1 */
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 64px;
    /* identical to box height */
    /* Azul */
    color: #0064b4;
}
.login-img {
    width: 100%;
}

.redefinirSenhaImag {
    width: 595px;
    height: 940px;
    background-image: url("../../../public/images/login/login2.png");
    background-repeat: no-repeat !important;
    background-position: -230px -100px !important;
    background-size: 1700px !important;
}

.form {
    align-items: center;
}
.form-control {
    color: #bcbcbd !important;
}
.form-control:focus {
    color: #343b40 !important;
}
.buttons {
    max-width: 400px;
    margin: 0 0;
    padding: 0 0;
}
.button-login {
    width: 181px;
    height: 60px;
    background: linear-gradient(268.05deg, #0064b4 4.03%, #1486e1 101.81%);
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    border-radius: 50px !important;
}
