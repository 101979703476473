.menuButton {
    font-family: Inter, sans-serif;
    display: inline-flex;
    width: 238px;
    height: 54px;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 19px !important;
}

.menuButtonActive {
    font-family: Inter, sans-serif;
    display: block;
    width: 238px;
    height: 54px;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 16px !important;
    line-height: 19px !important;
    background: linear-gradient(268.05deg, #0064b4 4.03%, #1486e1 101.81%);
    border-radius: 5px !important;
}

.menuItem {
    position: relative !important;
    width: 238px !important;
    height: 54px;
    left: 5px !important;
    top: 6px !important;
    padding: 1px 0px !important;
    margin: 10px -5px !important;
    background: #ffffff;
    border-radius: 5px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px !important;
    color: #0064b4;
}

.subMenuItem {
    font-family: Inter, sans-serif;
    font-style: normal;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-left: 6px;
    width: 238px;
}

.subItemOn {
    background: #cfd9e1;
    border-radius: 5px;
    width: 100%;
    text-align: left;
    margin-top: 9px;
    font-weight: 600;
    color: #0064b4 !important;
}

.subItemOff {
    border-radius: 5px;
    text-align: left;
    margin-top: 16px;
    font-weight: 500;
    color: #0064b4 !important;
    width: 100%;
}

.subItemOnUser {
    background: #cfd9e1;
    border-radius: 5px;
    width: 100%;
    text-align: left;
    margin-top: 9px;
    font-weight: 600;
    color: #0064b4;
}

.subItemOffUser {
    border-radius: 5px;
    text-align: left;
    margin-top: 16px;
    font-weight: 500;
    color: #0064b4;
    width: 100%;
}

.menuIcon {
    margin-left: -10px !important;
}

.menuItemRel {
    position: relative !important;
    width: 238px !important;
    height: 54px;
    top: 6px !important;
    padding: 1px 0px !important;
    margin: 10px -5px !important;
    background: #ffffff;
    border-radius: 5px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px !important;
    color: #0064b4;
}

.menuIconRelOff {
    display: flex;
    justify-content: center !important;
    padding-top: 0px !important;
    div:nth-child(1) {
        margin-right: -10px;
    }
    div:nth-child(2) {
        margin-right: 10px;
        display: flex;
    }
}

.menuIconRelOn {
    display: flex;
    justify-content: center !important;
    padding-top: 0px !important;

    div:nth-child(1) {
        margin-right: -10px;
    }
    div:nth-child(2) {
        margin-right: 20px;
        display: flex;
    }
}

.menuButtonReltActive {
    margin-left: 11px !important;
    font-family: Inter, sans-serif;
    display: block;
    width: 238px;
    height: 54px;
    font-style: normal !important;
    font-weight: bold !important;
    font-size: 16px !important;
    line-height: 19px !important;
    background: linear-gradient(268.05deg, #0064b4 4.03%, #1486e1 101.81%);
    border-radius: 5px !important;
}

.containerHabilit {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: -15px;
    span:first-child {
        margin: 0 10px 0 20px;
    }
}

.containerRelat {
    display: flex;
    flex-direction: row;
    align-items: center;
    span:first-child {
        margin: 0 10px 0 20px;
    }
}

.divContainerConf {
    display: flex;
    flex-direction: row;
    span:first-child {
        margin-left: 5px;
    }
}

.textConfig {
    font-family: Inter, sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 19.36px;
    color: #0064b4 !important;
}
