// ************************************************************************

.containerList {
    width: 1057px;
    margin-left: -60px;

    @media (min-width: 1366px) and (max-width: 1366px) {
        margin-left: -90px;
        width: 1057px;
    }

    @media (min-width: 1344px) and (max-width: 1344px) {
        width: 1000px;
    }

    @media (min-width: 1152px) and (max-width: 1280px) {
        width: 950px;
    }

    @media (min-width: 1024px) and (max-width: 1152px) {
        width: 850px;
        margin-left: -40px;
    }
}

.listagem {
    background: #f7f7f7;
    border-radius: 8px;
    padding: 32px;
    margin-bottom: 30px;
}

// ************************************************************************

#checkbox {
    position: relative;
    appearance: none;
    outline: none;
    width: 50px;
    height: 18px;
    border-radius: 20px;
    background: #d8d9da;
    box-shadow: none;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    top: 3px;
}

#checkbox:checked {
    background: #d8d9da;
}

#checkbox::before {
    content: "";
    position: absolute;
    border-radius: inherit;
    width: 26px;
    height: 26px;
    transition: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
}

#checkbox::after {
    content: "X";
    position: absolute;
    color: #fff;
    top: -4px;
    border-radius: inherit;
    width: 26px;
    height: 26px;
    background: #e60e19;
    transition: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    right: calc(100% - 25px);
}

#checkbox:checked::after {
    content: url("../../../assets/images/icons/check2.png");
    right: 0;
    background: #0064b4;
}

.containerSwitch {
    flex-direction: row;
    padding: 13.5px 0;
    display: flex;
    justify-content: space-between;
}

.textHabilitar {
    font-family: Inter, sans-serif;
    color: #343b40;
    font-weight: 600;
    font-size: 14px;
    line-height: 16.94px;
}

// ************************************************************************
