.titleModalUpload {
    font-family: Inter, sans-serif;
    font-weight: 600;
    font-size: 20px;
    line-height: 24.2px;
    color: #0064b4;
}

.containerTopUpload {
    display: flex;
    align-items: center;
    flex-direction: column;

    div {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        padding: 20px 20px 0 0;
    }
}

.fotoProf {
    img {
        width: 500px;
        height: 500px;
        object-fit: cover;
        border-radius: 10px;
    }
}

.containerEditFotoPerfil {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 25px;
    margin-top: 53px;
}

.hoverAvalPend:hover {
    background-color: #0064b4 !important;
}
