.container01 {
    position: relative;
    max-width: 879px;
    max-height: 931px;
    background: #f7f7f7;
    border-radius: 16px 16px 0 0;
    padding: 0 24px;
}

.container01Interno {
    display: flex;
    flex-direction: row;
    gap: 16px;
    padding: 11px 0;
    margin-bottom: 24px;

    div:nth-child(2) {
        width: 100%;
        div:nth-child(1) {
            display: flex;
            flex-direction: row !important;
            justify-content: flex-start;
            gap: 16px !important;
            margin-bottom: 10px;

            div:nth-child(1) {
                max-width: 55%;
                word-break: break-all;
                align-self: flex-start;
                align-items: center;
                display: flex !important;
                span {
                    word-wrap: break-word;
                }
            }
            div:nth-child(2) {
                word-break: break-all;
                align-self: flex-start;
                align-items: center;
                display: flex !important;
                width: auto !important;
                span {
                    word-wrap: break-word;
                }
            }
            div:nth-child(3) {
                gap: 16px !important;
                align-self: flex-start;
                align-items: center;
                word-break: break-all;
                display: flex !important;
                width: auto !important;
                span {
                    word-wrap: break-word;
                }
            }
        }

        div:nth-child(2) {
            width: 100%;
            display: flex;
            flex-direction: row !important;
            justify-content: flex-start;
            gap: 16px !important;

            div:nth-child(1) {
                max-width: 55%;
                word-break: break-all;
                align-self: flex-start;
                align-items: center;
                display: flex !important;
                span {
                    word-wrap: break-word;
                }
            }
            div:nth-child(2) {
                display: flex;
                align-self: flex-start;
                align-items: center;
                word-break: break-all;
                display: flex !important;
                width: auto !important;
                span {
                    word-wrap: break-word;
                }
            }
            div:nth-child(3) {
                align-self: flex-start;
                align-items: center;
                gap: 16px !important;
                word-break: break-all;
                display: flex !important;
                width: auto !important;
                span {
                    word-wrap: break-word;
                }
            }
        }
    }
}

.formulario-item {
    position: relative;
    width: 100% !important;
    height: 100% !important;
    margin: 10px 2px;
    padding: 32px 32px;
    align-content: space-between;
}

.colorSatusAtivo {
    span {
        display: flex;
        background-color: #95c11f;
        padding: 4px 30px;
        border-radius: 20px;

        font-weight: 600;
        font-size: 12px;
        line-height: 14.52px;
        font-family: Inter, sans-serif;
        display: flex;
        justify-content: flex-start;
        color: #ffffff;
    }
}

.colorSatusInativo {
    span {
        background-color: #e60e19 !important;
    }
}

.spanDados {
    font-weight: 400;
    font-size: 16px;
    line-height: 19.36px;
    font-family: Inter, sans-serif;
    color: #8d8f90;
}

.formularioContainer {
    position: relative;
    max-width: 879px;
    max-height: 931px;
    background: #f7f7f7;
    border-radius: 16px;
}

.containerRow {
    justify-content: center;
}

.inputLine {
    justify-content: center;
    display: flex;
    div:nth-child(1) {
        width: 400px;
        flex-direction: column;
    }

    select {
        width: 400px;
        height: 56px;
        background-color: #ffffff !important;
        box-sizing: border-box;
        border: 1px solid #c7c9d9;
        border-radius: 8px;

        &:focus {
            box-shadow: none !important;
            border-color: #ccc !important;
        }

        background-image: url("../../../assets/images/icons/arrow-down.png") !important;
        background-repeat: no-repeat !important;
        background-position: right 0.75rem center !important;
        background-size: 20px !important;
    }

    label {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16.94px;
        color: #343b40;
    }
}

.containerInput {
    padding: 32px 15px 0;
}

// ************************************************************************

// Operador administrador

#checkboxDesabil {
    position: relative;
    appearance: none;
    outline: none;
    width: 50px;
    height: 18px;
    border-radius: 20px;
    background: #d8d9da;
    box-shadow: none;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    top: 3px;
}

#checkboxDesabil:checked {
    background: #d8d9da;
}

#checkboxDesabil::before {
    content: "";
    position: absolute;
    border-radius: inherit;
    width: 26px;
    height: 26px;
    transition: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
}

#checkboxDesabil::after {
    content: "X";
    position: absolute;
    color: #fff;
    top: -4px;
    border-radius: inherit;
    width: 26px;
    height: 26px;
    background: #e60e19;
    transition: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    right: calc(100% - 25px);
}

#checkboxDesabil:checked::after {
    content: url("../../../assets/images/icons/check2.png");
    right: 0;
    background: #656a6e;
}

// Operador normal

#checkbox {
    position: relative;
    appearance: none;
    outline: none;
    width: 50px;
    height: 18px;
    border-radius: 20px;
    background: #d8d9da;
    box-shadow: none;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    top: 3px;
}

#checkbox:checked {
    background: #d8d9da;
}

#checkbox::before {
    content: "";
    position: absolute;
    border-radius: inherit;
    width: 26px;
    height: 26px;
    transition: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
}

#checkbox::after {
    content: "X";
    position: absolute;
    color: #fff;
    top: -4px;
    border-radius: inherit;
    width: 26px;
    height: 26px;
    background: #e60e19;
    transition: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    right: calc(100% - 25px);
}

#checkbox:checked::after {
    content: url("../../../assets/images/icons/check2.png");
    right: 0;
    background: #0064b4;
}

.containerSwitch {
    flex-direction: row;
    display: flex;
    align-items: center;
    gap: 25px;
    margin-top: 16px;
}

.containerSwitchAtivarTodas {
    flex-direction: row;
    display: flex;
    align-items: center;
    gap: 200px;
    margin-top: 16px;
}

.containerSwitchAtivar {
    flex-direction: row;
    display: flex;
    align-items: center;
    gap: 220px;
    margin-top: 16px;
}

.geralContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
}

.switchContainer {
    display: flex;
    padding-left: 20px;
    margin-top: 32px;

    .statusOper {
        font-family: Inter, sans-serif;
        color: #0064b4;
        font-weight: 600;
        font-size: 16px;
        line-height: 19.36px;
    }

    .textSwitch {
        font-family: Inter, sans-serif;
        color: #343b40;
        font-weight: 600;
        font-size: 14px;
        line-height: 16.94px;
    }

    .textSwitchPerm {
        font-family: Inter, sans-serif;
        color: #343b40;
        font-weight: 600;
        font-size: 14px;
        line-height: 16.94px;
        width: 199px;
    }

    .textSwitchPermissoes {
        font-family: Inter, sans-serif;
        color: #343b40;
        font-weight: 600;
        font-size: 14px;
        line-height: 16.94px;
        width: 100%;
    }

    .textSwitchEdit {
        font-family: Inter, sans-serif;
        color: #343b40;
        font-weight: 400;
        font-size: 14px;
        line-height: 16.94px;
        width: 100%;
    }
}

// ************************************************************************

.bntSalvar {
    display: flex !important;
    justify-content: flex-end !important;
    margin-bottom: 32px;
}

.buttonSalvarBlock {
    width: 169px;
    max-width: 169px;
    height: 56px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    align-items: center;
    text-align: center;
    background: #b3b5b6;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    border-radius: 50px;
    color: #656a6e;
    border: 0;
}

.buttonSalvar {
    min-width: 169px;
    height: 56px;
    background: #0064b4;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
    border-radius: 50px;
    border: 0;

    span {
        font-family: Inter, sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19.36px;
        color: #ffffff;
        padding: 20.5px 32px;
    }
}

.containerButton {
    padding: 0 8px 1px;
    margin-bottom: 32px;
}
